import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReCaptcha from 'react-google-recaptcha';

import CircularLoader from '../../../components/CircularLoader';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import { registerSignaler } from '../../../redux/Registration/registration.actions';
import { clearErrors } from '../../../redux/ClearErrors/clear-errors.actions';

import './AuthForm.scss';

const RegistrationForm = ({
  registerSignaler,
  clearErrors,
  isPending,
  errors,
  didSucceed,
  authorizationCode,
}) => {
  const initialState = {
    email: '',
    username: '',
    password: '',
    reCaptchaToken: '',
  };

  let reCaptchaRef = useRef();

  const [fieldInputs, setFieldInputs] = useState(initialState);

  const handleFormSubmit = e => {
    e.preventDefault();
    registerSignaler({ ...fieldInputs, authorizationCode });
    clearInputs();
  };

  const clearInputs = () => {
    setFieldInputs(initialState);
  };

  const handleInputChange = e => {
    e.persist();
    setFieldInputs(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    reCaptchaRef.current.reset();
    reCaptchaRef.current.execute();
  }, []);

  const handleCaptchaChange = reCaptchaToken => {
    setFieldInputs(prevState => ({ ...prevState, reCaptchaToken }));
  };
  const loadingClass = isPending ? 'loading' : '';

  useEffect(() => {
    if (reCaptchaRef) {
      reCaptchaRef.current.reset();
      reCaptchaRef.current.execute();
    }
  }, []);

  return (
    <>
      <ReCaptcha
        ref={reCaptchaRef}
        size="invisible"
        sitekey="6LdPFbAUAAAAABnEHRjaqKw5t_aygajNyGkDWQfN"
        theme="dark"
        badge="bottom-right"
        onChange={handleCaptchaChange}
      />
      <div className={`auth-form ${loadingClass}`}>
        {isPending ? (
          <CircularLoader classNames={loadingClass} size={100} />
        ) : null}
        {didSucceed ? (
          <div className="auth-form__success">
            <h2 className="sub-section-headline sub-section-headline--white">
              Verify your account
            </h2>
            <p className="auth-form__text auth-form__text--success">
              Login to your email and click on the verification link.
            </p>
          </div>
        ) : (
          <>
            <h2 className="sub-section-headline sub-section-headline--white">
              Create an account
            </h2>
            <form onSubmit={handleFormSubmit} className="auth-form__form">
              <TextInput
                id="username"
                type="text"
                name="username"
                variant="outlined"
                label="username"
                fullWidth={true}
                borderColor="white"
                value={fieldInputs.username}
                onChange={handleInputChange}
                helperText={errors ? errors.username : ''}
                error={errors ? errors.username || false : false}
              />
              <TextInput
                borderColor="white"
                id="email"
                name="email"
                type="text"
                variant="outlined"
                label="email"
                fullWidth={true}
                value={fieldInputs.email}
                onChange={handleInputChange}
                helperText={errors ? errors.email : ''}
                error={errors ? errors.email || false : false}
              />
              <TextInput
                borderColor="white"
                id="password"
                name="password"
                type="password"
                variant="outlined"
                label="password"
                fullWidth={true}
                value={fieldInputs.password}
                onChange={handleInputChange}
                helperText={errors ? errors.password : ''}
                error={errors ? errors.password || false : false}
              />
              <Button
                type="solid"
                value="register"
                element="input"
                header={false}
              />
            </form>

            <div className="auth-form__links-container">
              <span>
                <p className="auth-form__text">
                  Already a member?{' '}
                  <Link
                    onClick={clearErrors}
                    className="auth-form__link"
                    to="/accounts/login"
                  >
                    Login
                  </Link>
                </p>
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

RegistrationForm.propTypes = {};

const mapDispatchToProps = dispatch => ({
  registerSignaler: formData => dispatch(registerSignaler(formData)),
  clearErrors: () => dispatch(clearErrors()),
});

const mapStateToProps = ({
  registration: { isPending, errors, didSucceed },
}) => ({
  isPending,
  errors,
  didSucceed,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationForm);
