import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import SelectInput from '../../SelectInput';

import './DashComponent.scss';

const DashComponent = ({
                         svg,
                         title,
                         children,
                         twoPerRow = false,
                         role = 'user',
                       }) => {
  const [textInputField, setTextInputField] = useState('');
  const [timeSelectInputField, setTimeSelectInputField] = useState('overall');

  const [dashComponentIsClosed, setdashComponentIsClosed] = useState(false);

  const handleUserSearchChange = e => setTextInputField(e.target.value);
  const handleSubmit = e => {
    e.preventDefault();
    // send signal to back end
  };

  const handleSelectChange = e => setTimeSelectInputField(e.target.value);

  const handleToggleDashComponentOpen = () =>
      setdashComponentIsClosed(prevState => !prevState);

  let widthFullClass =
      twoPerRow && title === 'trade frequency' && role !== 'user'
          ? 'dash-component--width-full'
          : '';
  if ((role === 'superAdmin' || role === "signaler") && title === 'coin allocation') {
    widthFullClass = '';
  }

  const widthHalfClass = twoPerRow ? 'dash-component--width-half' : '';

  const dashComponentInputHeaderClass =
      ((role === 'signaler' || role === 'superAdmin') &&
          title === 'signal history') ||
      title === 'registration' ||
      title === 'activity'
          ? 'dash-component__header--input-header'
          : '';

  const toggleDashComponentClass = dashComponentIsClosed
      ? ''
      : 'toggle-dash-component';

  const isNonUserAndSpecificComponent = (role, title, component) => {
    if ((role === 'superAdmin' || role === "signaler") && title === 'coin allocation') {
      return component;
    }
    else if (
        (role === 'signaler' || role === 'superAdmin') &&
        (title === 'coin allocation' ||
            title === 'notifications' ||
            title === 'binance' ||
            title === 'trade profile' ||
            title === 'coin blacklist')
    ) {
      return null;
    } else {
      return component;
    }
  };

  const dashComponent = (
      <div
          className={`dash-component ${widthHalfClass} ${widthFullClass} ${toggleDashComponentClass}`}
      >
        <div
            className={`dash-component__header ${dashComponentInputHeaderClass}`}
        >
          <svg
              aria-label="minimize/maximize"
              height="24"
              id="Layer_8"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              className={`dash-component__collapse-button ${toggleDashComponentClass}`}
              onClick={handleToggleDashComponentOpen}
          >
            <title />
            <rect height="16" fill="transparent" width="16" x="4" y="4" />
            <path
                d="M19,5V19H5V5H19m0-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2Z"
                fill="#efefef"
            />
            <rect height="10" fill="#efefef" width="2" x="11" y="7" />
            <rect
                height="10"
                fill="#efefef"
                transform="translate(0 24) rotate(-90)"
                width="2"
                x="11"
                y="7"
            />
          </svg>
          {/* <img src={src} alt={alt} className="dash-component__icon" /> */}
          {svg}
          <h3 className="dash-component__title">{title}</h3>
        </div>
        {children}
      </div>
  );

  return <>{isNonUserAndSpecificComponent(role, title, dashComponent)}</>;
};

DashComponent.propTypes = {
  svg: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default DashComponent;
