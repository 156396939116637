import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { connect } from 'react-redux';

import './CoinAllocationChart.scss';

const CoinAllocationChart = ({ coinAllocationChartData }) => (
  <div className="coin-allocation-chart">
    {coinAllocationChartData ? (
      <ResponsivePie
        data={coinAllocationChartData}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: 'paired' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        radialLabelsSkipAngle={5}
        radialLabelsTextXOffset={2}
        radialLabelsTextColor="#121212"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={10}
        radialLabelsLinkHorizontalLength={0}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#121212"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        enableSlicesLabels={false}
        sortByValue={true}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
        ]}
        fill={[
          {
            match: {
              id: 'BTC',
            },
            id: 'dots',
          },
        ]}
      />
    ) : (
      <h2>CONNECT BINANCE FOR DATA</h2>
    )}
  </div>
);

const mapStateToProps = ({
  dashHome: {
    statistics: { coinAllocationChartData },
  },
}) => ({
  coinAllocationChartData,
});

export default connect(mapStateToProps)(CoinAllocationChart);
