import {AllUsersTypes} from "./all-user-types.types";

const INITIAL_STATE = {
    isPending: false,
    allUsers: [],
};

const allUserTypesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AllUsersTypes.GET_ALL_USERS_PENDING:
            return {...state, isPending: true};

        case AllUsersTypes.GET_ALL_USERS_SUCCESS:
            return {...state, isPending: false, allUsers: action.payload};

        case AllUsersTypes.GET_ALL_USERS_FAILED:
            return {...state, isPending: false};

        default:
            return state;
    }
};

export default allUserTypesReducer;



