import React, { useState } from "react";
import { connect } from "react-redux";

import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import CircleLoader from "../../../components/CircleLoader";

import { changePassword } from "../../../redux/Settings/settings.actions";

import "./PasswordForm.scss";

const INITIAL_STATE = {
  newPassword: "",
  confirmNewPassword: "",
  currentPassword: ""
};

const PasswordForm = ({ changePassword, errors, changePasswordPending }) => {
  const [inputFields, setInputFields] = useState(INITIAL_STATE);

  const handleInputChange = e => {
    e.persist();
    setInputFields(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    changePassword(inputFields);
    setInputFields(INITIAL_STATE);
  };

  return (
    <div className="password-form custom-mui-form">
      <form onSubmit={handleFormSubmit} className="custom-mui-form__form">
        <TextInput
          id="dash-settings-current-password-input"
          type="password"
          variant="outlined"
          label="current password"
          fullWidth={true}
          name="currentPassword"
          onChange={handleInputChange}
          value={inputFields.currentPassword}
          helperText={errors ? errors.currentPassword : ""}
          error={errors ? errors.currentPassword || false : false}
          disabled={changePasswordPending}
        />
        <TextInput
          id="dash-settings-new-password-input"
          type="password"
          variant="outlined"
          label="new password"
          fullWidth={true}
          name="newPassword"
          onChange={handleInputChange}
          value={inputFields.newPassword}
          helperText={errors ? errors.newPassword : ""}
          error={errors ? errors.newPassword || false : false}
          disabled={changePasswordPending}
        />
        <TextInput
          id="dash-settings-confirm-new-password-input"
          type="password"
          variant="outlined"
          label="confirm password"
          fullWidth={true}
          name="confirmNewPassword"
          onChange={handleInputChange}
          value={inputFields.confirmNewPassword}
          helperText={errors ? errors.confirmNewPassword : ""}
          error={errors ? errors.confirmNewPassword || false : false}
          disabled={changePasswordPending}
        />

        <Button
          type="solid"
          header={false}
          element="button"
          actionType="submit"
          disabled={changePasswordPending}
        >
          {changePasswordPending ? <CircleLoader /> : "Change Password"}
        </Button>
      </form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  changePassword: formData => dispatch(changePassword(formData))
});

const mapStateToProps = ({ settings: { errors, changePasswordPending } }) => ({
  errors,
  changePasswordPending
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
