import React, {useEffect} from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import "./DashInvoicing.scss";
import DashComponent from "../../components/Dashboard/DashComponent";
import WithdrawalsAndDepositsFilters from "../../components/WithdrawalsAndDepositsFilters";
import WithdrawalsAndDepositsTableDisplay from "./WithdrawalsAndDepositsTableDisplay";
import InvoicesTableDisplay from "./InvoiceTableDisplay";
import {getInvoiceTableData} from "../../redux/Invoices/invoices.actions";
import MakeInvoice from "./MakeInvoice";
import UserFilterManager from "./WithdrawalsAndDepositsTableDisplay/UserFilterManager";

const DashInvoicing = ({role = 'user', getInvoiceTableData}) => {

    useEffect(() => {
        getInvoiceTableData({page: 1, pendingOnly: false});
    }, []);

    return (
        <section className="dash-invoicing">
            <DashComponent title="Withdrawals / Deposits">
                <UserFilterManager/>
            </DashComponent>

            <DashComponent title="Invoices">
                <p className="body-text invoice-table-instruction">
                    Click an entry for the ability to cancel (if status is pending), as well as to view full details.
                </p>
                <InvoicesTableDisplay
                    headerTitleArray={['id', 'created', 'username', 'amount', 'withdraw ID', 'reason', 'status']}
                    removeHeaderAtSmallArray={['id', 'created', 'withdraw ID', 'reason']}
                    removeHeaderAtMediumArray={['reason']}
                />
            </DashComponent>

            <DashComponent title="Make Invoice">
                <MakeInvoice/>
            </DashComponent>

        </section>
    );
};

const mapDispatchToProps = dispatch => ({
    getInvoiceTableData: filterData => dispatch(getInvoiceTableData(filterData))
});

export default connect(null, mapDispatchToProps)(withRouter(DashInvoicing));
