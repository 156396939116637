import { SignalHistoryTypes } from './signal-history.types';
import { SnackbarTypes } from '../Snackbar/snackbar.types';
import { Urls, getRequest } from '../../api/index';

const handleSignalHistoryQueryError = (error, dispatch) => {
  if (error.response.data.snackbar) {
    dispatch({
      type: SignalHistoryTypes.SIGNAL_HISTORY_QUERY_FAILED,
      payload: null,
    });
    dispatch({
      type: SnackbarTypes.OPEN_SNACKBAR,
      payload: error.response.data.snackbar,
    });
  } else {
    dispatch({
      type: SignalHistoryTypes.SIGNAL_HISTORY_QUERY_FAILED,
    });
  }
};

export const getSignalsOnPageLoad = () => async dispatch => {
  dispatch({ type: SignalHistoryTypes.SIGNAL_HISTORY_QUERY_PENDING });

  getRequest(Urls.server, `/signals/all`)
    .then(data => {
      dispatch({
        type: SignalHistoryTypes.SIGNAL_HISTORY_QUERY_SUCCESS,
        payload: data,
      });
    })
    .catch(error => handleSignalHistoryQueryError(error, dispatch));
};

export const convertObjectToQueryString = object => {
  const keysArray = Object.keys(object);

  const convertKeysToQueryStringArray = (key, index) => {
    if (index === 0) return `?${key}=${object[key]}`;
    else return `&${key}=${object[key]}`;
  };

  const queryStringArray = keysArray.map(convertKeysToQueryStringArray);

  return queryStringArray.join('');
};

export const filterSignals = formData => dispatch => {
  const queryString = convertObjectToQueryString(formData);

  dispatch({ type: SignalHistoryTypes.SIGNAL_HISTORY_QUERY_PENDING });

  getRequest(Urls.server, `/signals/filter${queryString}`)
    .then(data =>
      dispatch({
        type: SignalHistoryTypes.SIGNAL_HISTORY_QUERY_SUCCESS,
        payload: data,
      }),
    )
    .catch(error => handleSignalHistoryQueryError(error, dispatch));
};
