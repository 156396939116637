import moment from "moment-timezone";
import numeral from "numeral";

export const removeNegativeFromZero = (price) => {
  if (price === null) return "N/A";
  else return price;
};

export const formatNegativeDollars = (price) => {
  const priceNumber = Number(price);
  if (isNaN(priceNumber)) return priceNumber;

  const formattedPrice = numeral(Math.abs(priceNumber)).format("$0,0.00");

  if (priceNumber < 0) return `-${formattedPrice}`;
  else return formattedPrice;
};

export const convertDateTimeToCurrentTimezone = ({ dateTime, format }) => {
  const utcOffset = moment().tz("America/Vancouver").utcOffset();

  return moment(dateTime).add(utcOffset, "minutes").format(format);
};
