import React, { useState } from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

// For reference to how this component was constructed visit this url: https://material-ui.com/components/autocomplete/

// import { tradePairArray } from '../../scenes/DashTradeInterface/seedData';

import './AutoCompleteUsers.scss';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
      }}
      {...other}
    />
  );
}

function getSuggestions({ value, suggestions }) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

function AutoCompleteUsers({
  handleChange,
  handleClick,
  value,
  helperText,
  error,
  suggestions,
}) {
  const [stateSuggestions, setSuggestions] = useState([]);

  const formattedSuggestions =
    suggestions.length &&
    suggestions.map(suggestion => ({
      label: suggestion.username,
    }));

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(
      getSuggestions({ value, suggestions: formattedSuggestions }),
    );
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
      <MenuItem
        onClick={() => handleClick(parts)}
        selected={isHighlighted}
        component="div"
      >
        <div>
          {parts.map(part => (
            <span
              key={part.text}
              className={part.highlight ? 'auto-complete-input__highlight' : ''}
            >
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          id: 'dash-home-filter-form-username-input',
          label: 'username',
          value: value,
          onChange: handleChange,
          name: 'username',
          variant: 'outlined',
          helperText,
          error,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}

export default AutoCompleteUsers;
