import React from "react";
import {connect} from "react-redux";

import "./StatBox.scss";

const StatBox = ({stat, role, data}) => {
    if (stat.key == "netProfitUSDT") {
        if (Number(data.netProfitUSDT) >= 0) {
            stat.color = "green";
        } else {
            stat.color = "red";
        }
    }

    if (stat.key == "netProfitBTC") {
        if (Number(data.netProfitBTC) >= 0) {
            stat.color = "green";
        } else {
            stat.color = "red";
        }
    }

    let statColorClass;
    switch (stat.color) {
        case "green":
            statColorClass = "stat-box__stat--green";
            break;
        case "blue":
            statColorClass = "stat-box__stat--blue";
            break;
        case "red":
            statColorClass = "stat-box__stat--red";
            break;
        case "yellow":
            statColorClass = "stat-box__stat--yellow";
            break;
        case "violet":
            statColorClass = "stat-box__stat--violet";
            break;
        default:
            statColorClass = "stat-box__stat--white";
    }

    data.portfolioValue = "$" + data.portfolioValue;
    data.netProfitUSDT =
        Number(data.netProfitUSDT) < 0
            ? (data.netProfitUSDT.substring(0, 1) + "$" + data.netProfitUSDT.substring(1))
            : ("$" + data.netProfitUSDT);
    data.startingBalanceUSDT =
        Number(data.startingBalanceUSDT) < 0
            ? (data.startingBalanceUSDT.substring(0, 1) + "$" + data.startingBalanceUSDT.substring(1))
            : ("$" + data.startingBalanceUSDT);
    data.withdrawTotalUSDT =
        Number(data.withdrawTotalUSDT) < 0
            ? (data.withdrawTotalUSDT.substring(0, 1) + "$" + data.withdrawTotalUSDT.substring(1))
            : ("$" + data.withdrawTotalUSDT);
    data.depositTotalUSDT =
        Number(data.depositTotalUSDT) < 0
            ? (data.depositTotalUSDT.substring(0, 1) + "$" + data.depositTotalUSDT.substring(1))
            : ("$" + data.depositTotalUSDT);

    const halfOfContainerWidthClass =
        role === "user" ? "" : "stat-box--half-of-container";
    return (
        <div className={`stat-box`}>
            <div className="stat-box__icon-container">
                <stat.svg/>
            </div>
            <div className="stat-box__content">
                <span className={`stat-box__stat ${statColorClass}`}>{data[stat.key]}</span>
                <span className="stat-box__stat-description">{stat.description}</span>
            </div>
        </div>
    );
};

const mapStateToProps = (
    {
        dashHome: {
            statistics: {
                netProfitBTC,
                netProfitUSDT,
                trades,
                portfolioValue,
                portfolioBTC,
                startingBalanceUSDT,
                startingBalanceBTC,
                withdrawTotalUSDT,
                withdrawTotalBTC,
                depositTotalUSDT,
                depositTotalBTC
            },
        },
        user: {
            data: {role},
        },
    }
) => ({
    role,
    data: {
        netProfitBTC,
        netProfitUSDT,
        trades,
        portfolioValue,
        portfolioBTC,
        startingBalanceUSDT,
        startingBalanceBTC,
        withdrawTotalUSDT,
        withdrawTotalBTC,
        depositTotalUSDT,
        depositTotalBTC
    }
});

export default connect(mapStateToProps)(StatBox);
