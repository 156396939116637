import { TradeInterfaceTypes } from "./trade-interface.types";
import { SnackbarTypes } from "../Snackbar/snackbar.types";
import { Urls, getRequest, postRequest } from "../../api";
import {
  handleData,
  handleError,
  handleDataWithSnackbar,
} from "../utils.redux";

export const getEligibleUsers = () => (dispatch) => {
  dispatch({ type: TradeInterfaceTypes.TRADE_INTERFACE_REQUEST_PENDING });

  getRequest(Urls.server, "/users/eligible")
    .then((userList) =>
      dispatch({
        type: TradeInterfaceTypes.GET_ELIGIBLE_USERS_SUCCESS,
        payload: userList,
      })
    )
    .catch((error) => {
      dispatch({ type: TradeInterfaceTypes.GET_ELIGIBLE_USERS_FAILED });
      dispatch({
        type: SnackbarTypes.OPEN_SNACKBAR,
        payload: error.response.data.snackbar,
      });
    });
};

export const clearUserBalances = () => (dispatch) => {
  dispatch({ type: TradeInterfaceTypes.CLEAR_USER_BALANCES });
};

export const sendSignal = (formData) => (dispatch) => {
  dispatch({ type: TradeInterfaceTypes.TRADE_INTERFACE_REQUEST_PENDING });

  postRequest(Urls.server, "/binance/trade", formData)
    .then((data) => {
      dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
      dispatch({ type: TradeInterfaceTypes.SEND_TRADE_SIGNAL_SUCCESS });
      if (data.userId) {
        dispatch(getOpenOrders(data.userId));
        dispatch(getUserBalances(data.userId));
      }
    })
    .catch((error) => {
      if (error.response.data.snackbar) {
        dispatch({
          type: TradeInterfaceTypes.SEND_TRADE_SIGNAL_FAILED,
          payload: null,
        });
        dispatch({
          type: SnackbarTypes.OPEN_SNACKBAR,
          payload: error.response.data.snackbar,
        });
      } else {
        dispatch({
          type: TradeInterfaceTypes.SEND_TRADE_SIGNAL_FAILED,
          payload: error.response.data,
        });
      }
    });
};

export const getUserBalances = (userId) => (dispatch) => {
  dispatch({ type: TradeInterfaceTypes.GET_USER_BALANCES_PENDING });

  getRequest(Urls.server, `/user/balances?userId=${userId}`)
    .then((data) =>
      handleData({
        data,
        dispatch,
        type: TradeInterfaceTypes.GET_USER_BALANCES_SUCCESS,
      })
    )
    .catch((error) =>
      handleError({
        error,
        dispatch,
        type: TradeInterfaceTypes.GET_USER_BALANCES_FAILED,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      })
    );
};

export const getOpenOrders = (userId) => (dispatch) => {
  dispatch({ type: TradeInterfaceTypes.GET_OPEN_ORDERS_PENDING });

  getRequest(Urls.server, `/user/orders/open?userId=${userId}`)
    .then((data) =>
      handleData({
        data,
        dispatch,
        type: TradeInterfaceTypes.GET_OPEN_ORDERS_SUCCESS,
      })
    )
    .catch((error) =>
      handleError({
        error,
        dispatch,
        type: TradeInterfaceTypes.GET_OPEN_ORDERS_FAILED,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      })
    );
};

export const cancelSelectedOrders = (payload) => (dispatch) => {
  dispatch({ type: TradeInterfaceTypes.CANCEL_SELECTED_ORDERS_PENDING });

  postRequest(Urls.server, `/binance/cancel/orders`, payload)
    .then((data) => {
      handleData({
        data,
        dispatch,
        type: TradeInterfaceTypes.CANCEL_SELECTED_ORDERS_SUCCESS,
      });
    })
    .catch((error) =>
      handleError({
        error,
        dispatch,
        type: TradeInterfaceTypes.CANCEL_SELECTED_ORDERS_FAILED,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      })
    );
};

export const sellSelectedOrders = (payload) => (dispatch) => {
  dispatch({ type: TradeInterfaceTypes.SELL_SELECTED_ORDERS_PENDING });

  postRequest(Urls.server, `/binance/sell/orders`, payload)
    .then((data) => {
      handleDataWithSnackbar({
        data,
        dispatch,
        type: TradeInterfaceTypes.SELL_SELECTED_ORDERS_SUCCESS,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      });
    })
    .catch((error) =>
      handleError({
        error,
        dispatch,
        type: TradeInterfaceTypes.SELL_SELECTED_ORDERS_FAILED,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      })
    );
};
