import React from "react";
import { connect } from "react-redux";

import { ReactComponent as ActivityLockIcon } from "../../../../static/svg/activity-lock-icon.svg";
import { ReactComponent as ChatIcon } from "../../../../static/svg/chat-icon.svg";
import { openMessageModal } from "../../../../redux/MessageModal/message-modal.actions";
import { toggleLockAccount } from "../../../../redux/DashActivity/dash-activity.actions";
import { convertDateTimeToCurrentTimezone } from "../../../../format";

import "./ActivityTableRow.scss";

const ActivityTableRow = ({
  user,
  index,
  openMessageModal,
  toggleLockAccount,
  currentFilter,
  page,
}) => {
  const darkBgClass = index % 2 !== 0 ? "" : "activity-table-row--dark";

  const loggedInClass = user.is_logged_in ? "logged-in" : "logged-out";

  const lockedClass = user.is_banned ? "locked" : "";

  return (
    <div className={`activity-table-row ${darkBgClass}`}>
      <div className="activity-table-row__item activity-table-row__item--username">
        <div className="activity-table-row__avatar-group">
          <div
            className={`activity-table-row__avatar-status activity-table-row__avatar-status--${loggedInClass}`}
          />
        </div>
        <span>{user.username}</span>
      </div>
      <span className="activity-table-row__item">
        <span className="activity-table-row__mobile-label">last login</span>
        {convertDateTimeToCurrentTimezone({
          dateTime: user.last_login,
          format: "lll",
        })}
      </span>
      <span className="activity-table-row__item">
        <span className="activity-table-row__mobile-label">registration</span>
        {convertDateTimeToCurrentTimezone({
          dateTime: user.created_on,
          format: "lll",
        })}
      </span>

      <div className="activity-table-row__button-container">
        {user.role === "user" ? (
          <button className="activity-table-row__item">
            <ChatIcon
              onClick={() =>
                openMessageModal({
                  notificationPreferences: user.notifications,
                  username: user.username,
                  accountStatus: { is_logged_in: user.is_logged_in },
                  role: user.role,
                })
              }
            />
          </button>
        ) : null}
        <button
          onClick={() =>
            toggleLockAccount({
              userId: user.id,
              currentLockStatus: user.is_banned,
              currentFilter: { ...currentFilter, page },
            })
          }
          className="activity-table-row__item"
        >
          <ActivityLockIcon className={`${lockedClass}`} />
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openMessageModal: (userData) => dispatch(openMessageModal(userData)),
  toggleLockAccount: (data) => dispatch(toggleLockAccount(data)),
});

const mapStateToProps = ({ dashActivity: { currentFilter, page } }) => ({
  currentFilter,
  page,
});
export default connect(mapStateToProps, mapDispatchToProps)(ActivityTableRow);
