import React from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  history,
  location,
  component: Component,
  user,
  securityLevel,
  ...rest
}) => {
  const getAuthorizedRoles = securityLevel => {
    if (securityLevel === 'low') return ['user', 'signaler', 'superAdmin'];
    if (securityLevel === 'medium') return ['signaler', 'superAdmin'];
    if (securityLevel === 'high') return ['superAdmin'];
  };
  const authorizedRoles = getAuthorizedRoles(securityLevel);
  return (
    <Route
      {...rest}
      render={props => {
        return authorizedRoles.includes(user && user.role) ? (
          <Component user={user} {...props} />
        ) : (
          <Redirect to="/accounts/login" />
        );
      }}
    />
  );
};

export default withRouter(PrivateRoute);
