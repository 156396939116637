import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  getRegistrationData,
  filterUserActivityData
} from "../../redux/DashActivity/dash-activity.actions";
import ActivityTableFilters from "./ActivityTableFilters";
import DashboardLoading from "../../components/DashboardLoading";
import RegistrationChart from "./RegistrationChart";
import ActivityTableDisplay from "./ActivityTableDisplay";
import RegisterSignalerForm from "./RegisterSignalerForm";
import RegistrationChartFilters from "./RegistrationChartFilters";
import { ReactComponent as RegistrationIcon } from "../../static/svg/registration-icon.svg";
import { ReactComponent as ActivityIcon } from "../../static/svg/psi-icon.svg";
import { ReactComponent as LinkIcon } from "../../static/svg/link-icon.svg";
import DashComponent from "../../components/Dashboard/DashComponent";
import "./DashActivity.scss";

const DashActivity = ({
  handleToggleMessageModal,
  registrationDataIsPending,
  userActivityDataIsPending,
  sendSignalerRegistrationLinkIsPending,
  getRegistrationData,
  filterUserActivityData
}) => {
  useEffect(() => {
    getRegistrationData({ endDate: "", startDate: "" });
    filterUserActivityData({ username: "", role: "", status: "", page: 1 });
  }, []);

  return (
    <section className="dash-activity">
      {registrationDataIsPending ||
      userActivityDataIsPending ||
      sendSignalerRegistrationLinkIsPending ? (
        <DashboardLoading />
      ) : null}
      <DashComponent svg={<RegistrationIcon />} title="registration">
        <RegistrationChartFilters />
        <RegistrationChart />
      </DashComponent>
      <DashComponent svg={<ActivityIcon />} title="activity">
        <div className="activity-table">
          <ActivityTableFilters />
          <ActivityTableDisplay
            resultsPerPage={5}
            headerTitleArray={["username", "login", "registered", ""]}
            removeHeaderAtSmallArray={["username", "login", "registered"]}
            handleToggleModal={handleToggleMessageModal}
          />
        </div>
      </DashComponent>
      <DashComponent svg={<LinkIcon />} title="send invite">
        <RegisterSignalerForm />
      </DashComponent>
    </section>
  );
};

const mapDispatchToProps = dispatch => ({
  getRegistrationData: formData => dispatch(getRegistrationData(formData)),
  filterUserActivityData: formData => dispatch(filterUserActivityData(formData))
});

const mapStateToProps = ({
  dashActivity: {
    registrationDataIsPending,
    userActivityDataIsPending,
    lockAccountIsPending,
    sendSignalerRegistrationLinkIsPending
  }
}) => ({
  registrationDataIsPending,
  userActivityDataIsPending,
  lockAccountIsPending,
  sendSignalerRegistrationLinkIsPending
});

export default connect(mapStateToProps, mapDispatchToProps)(DashActivity);
