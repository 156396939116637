import React, { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';

import '../../scss/typography.scss';
import './PageTitle.scss';

const PageTitle = ({ title, handleEnterBounds, handleLeaveBounds }) => {
  const [verticalScrollPos, setVerticalScrollPos] = useState(window.scrollY);

  const getPageTitleBackgroundClass = title => {
    let bgImgClass;
    const lowerCaseTitle = title.toLowerCase();
    if (lowerCaseTitle === 'about us') bgImgClass = 'page-title--about-us';
    if (lowerCaseTitle === 'guide') bgImgClass = 'page-title--guide';
    if (lowerCaseTitle === 'contact us') bgImgClass = 'page-title--contact-us';

    return bgImgClass;
  };

  const handleWindowScroll = e => {
    const scrollY = e.currentTarget.scrollY;
    if (scrollY < 300) setVerticalScrollPos(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  return (
    <Waypoint
      onEnter={handleEnterBounds}
      onLeave={handleLeaveBounds}
      topOffset="5%"
    >
      <div className={`page-title ${getPageTitleBackgroundClass(title)}`}>
        <h2 className="section-headline section-headline--white">{title}</h2>
      </div>
    </Waypoint>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  handleEnterBounds: PropTypes.func,
  handleLeaveBounds: PropTypes.func,
};

export default PageTitle;
