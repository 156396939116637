import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './RadioButton.scss';

const RadioButton = ({ label, value, type, checked }) => {
  return (
    <FormControlLabel
      className={`radio-button radio-button--${type}`}
      value={value}
      control={<Radio />}
      label={label}
      checked={checked}
    />
  );
};

export default RadioButton;
