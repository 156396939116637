import { AppErrorTypes } from './app-error.types';

const INITIAL_STATE = {
  hasError: false,
};

const appErrorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppErrorTypes.APP_ERROR: {
      return { ...state, hasError: true };
    }

    case AppErrorTypes.NO_APP_ERROR: {
      return { ...state, hasError: false };
    }

    default:
      return state;
  }
};

export default appErrorReducer;
