import React, { useState, useEffect } from 'react';

import GuideMenu from './GuideMenu';
import GuideContent from './GuideContent';

import PageTitle from '../../components/PageTitle';
import RegisterCTA from '../../components/RegisterCTA';

import './Guide.scss';

const Guide = ({ isAppMenuOpen }) => {
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const [isTopBound, setIsTopBound] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => setIsMenuOpen(prevState => !prevState);

  const handleEnterTopBounds = () => {
    setIsMenuFixed(false);
    setIsTopBound(true);
  };
  const handleLeaveTopBounds = () => {
    setIsMenuFixed(true);
    setIsTopBound(false);
  };

  const handleEnterBounds = () => setIsMenuFixed(false);
  const handleLeaveBounds = () => setIsMenuFixed(true);
  const handleBodyOverflowOnResize = e => {
    if (e.currentTarget.innerWidth > 1280) setIsMenuOpen(false);
  };

  const setMenuPositionOnLoad = (topBound, bottomBound) => {
    const scrollPosTop = window.scrollY;
    const scrollPosBot = scrollPosTop + window.innerHeight;
    if (scrollPosTop > topBound && scrollPosTop < bottomBound) {
      setIsMenuFixed(true);
      setIsTopBound(false);
    }
    if (scrollPosBot > bottomBound && isTopBound === true) setIsTopBound(false);
  };

  const lowerZIndexClass = isAppMenuOpen ? 'lower-z-index' : '';

  const guideMenuOpenClass = isMenuOpen ? 'menu-open' : '';

  useEffect(() => {
    window.addEventListener('resize', handleBodyOverflowOnResize);

    return () => {
      window.removeEventListener('resize', handleBodyOverflowOnResize);
    };
  }, []);

  document.documentElement.style.overflowY =
    isMenuOpen && window.innerWidth < 1280 ? 'hidden' : 'auto';

  document.body.style.overflow =
    isMenuOpen && window.innerWidth < 1280 ? 'hidden' : 'auto';

  return (
    <main className="guide">
      <PageTitle
        title="guide"
        handleEnterBounds={handleEnterTopBounds}
        handleLeaveBounds={handleLeaveTopBounds}
      />
      <section className="guide__layout">
        <svg
          width="58"
          height="58"
          viewBox="-1 -1 62 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`guide__menu-icon ${lowerZIndexClass} ${guideMenuOpenClass}`}
          onClick={handleToggleMenu}
        >
          <path
            d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
            fill="#121212"
            stroke="#a4a4a4"
            strokeWidth="0.2"
          />
          <path
            d="M29.9998 45.4288C30.3655 45.4288 30.7309 45.2891 31.0102 45.0105L39.5816 36.4391C40.1395 35.8812 40.1395 34.9766 39.5816 34.4187C39.0237 33.8609 38.1191 33.8609 37.5613 34.4187L29.9998 41.9798L22.4388 34.4184C21.8809 33.8605 20.9762 33.8605 20.4184 34.4184C19.8605 34.9763 19.8605 35.8809 20.4184 36.4387L28.9898 45.0102C29.2687 45.2891 29.6341 45.4288 29.9998 45.4288Z"
            fill="#EFEFEF"
          />
          <path
            d="M30.0002 14C29.6345 14 29.2691 14.1396 28.9898 14.4182L20.4184 22.9896C19.8605 23.5475 19.8605 24.4521 20.4184 25.01C20.9763 25.5678 21.8809 25.5678 22.4387 25.01L30.0002 17.4489L37.5612 25.0103C38.1191 25.5682 39.0238 25.5682 39.5816 25.0103C40.1395 24.4525 40.1395 23.5478 39.5816 22.99L31.0102 14.4185C30.7313 14.1396 30.3659 14 30.0002 14Z"
            fill="#EFEFEF"
          />
        </svg>

        <GuideMenu
          isMenuFixed={isMenuFixed}
          isTopBound={isTopBound}
          isMenuOpen={isMenuOpen}
          handleToggleMenu={handleToggleMenu}
        />
        <GuideContent setMenuPositionOnLoad={setMenuPositionOnLoad} />
      </section>

      <RegisterCTA
        handleEnterBounds={handleEnterBounds}
        handleLeaveBounds={handleLeaveBounds}
      />
    </main>
  );
};

export default Guide;
