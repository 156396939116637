import React from "react";
import "../../scss/typography.scss";
import "./TermsAndConditionsPage.scss";
import PageTitle from "../../components/PageTitle";

const TermsAndConditionsPage = () => (
  <main className="terms-and-conditions">
    <PageTitle title="terms and conditions" />

    <section className="terms-and-conditions__content">
      <h2 className="sub-section-headline">Terms and Conditions</h2>
      <h2 className="sub-section-headline">
        for Access and Use of the Website, Products and Services
      </h2>

      <p className="body-text">
        PLEASE READ CAREFULLY! THIS DOCUMENT REPRESENTS THE LEGAL BINDING
        BETWEEN US AND THE CUSTOMER. WE INVITE YOU TO READ THESE TERMS &
        CONDITIONS BEFORE USING THE PROVIDED SERVICES. PLEASE, ALSO CHECK OUR
        PRIVACY POLICY THAT, MUTUALLY WITH THIS LEGAL DOCUMENT, REPRESENT THE
        SET OF LEGAL CLAUSES APPLIED TO THE USE OF OUR WEBSITE, PRODUCTS AND
        SERVICES.
      </p>

      <p className="body-text">
        By using the Services, you, as Customer, agree irrevocably with the
        present Terms & Conditions of Use. In case you do not agree with the
        clauses of the present document, please stop using the Services.
      </p>

      <h3 className="sub-section-headline">Disclaimer</h3>
      <p className="body-text">
        <br />
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties and conditions relating to our website and
        the use of our website. Nothing in this disclaimer will:
        <br />
        <br />
        <ul>
          <li>
            - limit or exclude the Customer’s liability for misinterpretation of
            the information presented on the website;
          </li>
          <li>
            - limit any of the Customer’s liabilities in any way that is not
            permitted under applicable law;
          </li>
        </ul>
      </p>

      <p className="body-text">
        Our trading services carries a high level of risk and can result in
        losses that exceed the cash balance held on the Customer’s Account at
        any given time. The high level of risk means our trading service is not
        suitable for everyone. If you are in any doubt you should seek
        independent advice.
      </p>
      <p className="body-text">
        The set of risks set out in this Disclaimer include, but are not limited
        to, the following:
        <br />
        <br />
        <ul>
          <li>
            (a) any losses may exceed the cash you have deposited with the
            Customer’s Broker of choice;
          </li>
          <li>
            (b) historical performance is not to be considered indication as to
            future performance;
          </li>
          <li>(c) profits or losses can be incurred very quickly;</li>
          <li>
            (d) we are not market makers. The prices of instruments are
            determined by fluctuations in the market outside our control;
          </li>
          <li>
            (e) it is the Customer’s responsibility to monitor the Customer’s
            Account with the Broker of the Customer’s choice at all times;
          </li>
          <li>
            (f) in certain circumstances, it may be difficult to close the
            Customer’s open trades immediately, the value of the Customer’s
            trades could fall and you will be liable for the full amount of any
            losses;
          </li>
          <li>
            (g) currency exchange fluctuations may impact the Customer’s profits
            and losses;
          </li>
          <li>
            (h) you should read carefully the Terms and Conditions of the
            Customer’s broker of choice and do the Customer’s due diligence
            about its reliability;
          </li>
          <li>
            (j) corporate action type events may impact the Customer’s trade and
            it is the Customer’s responsibility to determine whether the
            Customer’s trade is likely to be subject to such action and what its
            effect may be;
          </li>
        </ul>
      </p>

      <p className="body-text">
        By submitting an application to open an Account with us, you confirm:
        <br />
        <br />
        <ul>
          <li>
            • that you have read the Disclaimer and all the documents supplied
            to you in connection with our services and that you fully agree to
            them;
          </li>
          <li>
            • that you understand and agree that our trading relationship may be
            amended from time to time, in which case you automatically agree to
            such modifications;
          </li>
        </ul>
      </p>

      <h3 className="sub-section-headline">General provisions</h3>
      <p className="body-text">
        <br />
        The website{" "}
        <a href="https://trading.altninjas.com/">
          https://trading.altninjas.com/
        </a>{" "}
        is owned and operated by “Altcoin Ninjas Organization Inc” (doing
        business as “AN”). Throughout the site, the terms “we”, “us”, “AN” and
        “our” refer to Company. AN offers this website, including all
        information, tools and services available from this website to you, the
        Customer, conditioned upon the Customer’s acceptance of all the
        provisions stated here.
      </p>
      <p className="body-text">
        By visiting our website and/ or purchasing a product or service from us,
        you engage in our “Service” and agree to be bound by the following Terms
        and Conditions (“Terms and Conditions”, “Terms”). These Terms and
        Conditions apply to all users of the website.
      </p>
      <p className="body-text">
        Any new features or tools which are added to the current line of
        products and services shall also be subject to the Terms and Conditions
        of Services. You can review the most current version of the document at
        any time on this page.
      </p>

      <h3 className="sub-section-headline">1. INTRODUCTION</h3>
      <p className="body-text">
        <br />
        1.1. Contracting Parties
        <br />
        <br />
        “Altcoin Ninjas Organization Inc”, Unit 301 15047 Marine Dr White Rock,
        BC V4B 1C5 - as a product and service provider,
        <br />
        <br />
        and
        <br />
        <br />
        <b>"Customer"</b> - any legal person who opens a User account at{" "}
        <a href="https://trading.altninjas.com/">
          https://trading.altninjas.com/
        </a>
        , and implicitly buys one or more of our services.
        <br />
        <br />
        1.2. Representations
        <br />
        <br />
        <b>Authority and Capacity.</b> The parties and their legal
        representatives guarantee that they have the authority and capacity to
        enter into this agreement.
        <br />
        <br />
        <b>Enforceability.</b> This agreement constitutes a legal, valid, and
        binding obligation, enforceable against the parties according to its
        terms.
        <br />
        <br />
        1.3. Purpose
        <br />
        <br />
        The access to the Services is made upon a commission-based payment. Upon
        ordering access to the Services, and confirming acceptance of the terms
        and conditions in this document, a legally binding agreement
        (“Agreement”) is entered into between AN and the Customer. All Services,
        as they are available at any time through the portal{" "}
        <a href="https://trading.altninjas.com/">
          https://trading.altninjas.com/
        </a>
        , are subject to the terms of this Agreement.
        <br />
        <br />
        For the purposes of these Terms, applicable laws and regulations shall
        include the FCA Rules, the ASIC rules, the rules of any other relevant
        regulatory authority or exchange and any applicable laws, rules,
        procedures, guidance, codes, standards and regulations (including,
        without limitation, accounting rules and anti-money laundering or
        sanctions legislation) in force from time to time.
      </p>

      <h3 className="sub-section-headline">2. THE RIGHT TO USE THE SERVICES</h3>
      <p className="body-text">
        <br />
        2.1. Sign-up Procedures and Fees
        <br />
        <br />
        The Customer requests the opening of the account through the
        "Registration" form, which can be found on the website. In order to
        create the account, the Customer is be required to provide a set of
        information, including, without limitation: complete contact
        information, payment and billing sources, and digital identification.
        The Customer also undertakes that during the validity of the contract it
        will keep the Account Information updated, accurate and true. The
        account opening will be validated after a preliminary verification of
        the contact data. The Customer is responsible for keeping the username,
        account number, password and/or security information safe and secure.
        The Customer must not disclose these details to any person who is not
        authorized to access the Account.
        <br />
        <br />
        AN reserves the right to refuse services to anyone for any reason, at
        any time.
        <br />
        <br />
        Upon requesting the opening of the account, the Customer is provided
        access to an User’s account, with username and password chosen by the
        Customer.
        <br />
        <br />
        The Customer receive a limited, terminable, non-exclusive and
        non-transferable right of access and use the Services, in accordance
        with the terms of this Agreement, exclusively for the purposes for which
        the services are construed.
        <br />
        <br />
        The Payment of the Commission Fee and the compliance with the Terms and
        Conditions is a condition for the right to use the Services. AN may
        adjust the agreed Commission Fee upon 14 days’ written notice. In case
        of changes in public taxes, charges or other duties or other changes in
        public administrative practice affects the Broker’s costs connected to
        the Service, the Commission Fee may be adjusted correspondingly without
        prior notice.
        <br />
        <br />
        The Commission Fee is being automatically retained by AN. It is agreed
        that the Commission Fee received from the Customer will not be refunded
        regardless of the situation.
        <br />
        <br />
        2.2. Brokers
        <br />
        <br />
        We make sure that any Broker used in association with our Services shall
        conform to the present Terms and Conditions. We are not responsible for
        testing or screening any of the Third-Party Brokers, and consequently,
        the Customer’s use of such content is at its sole risk. We may, at our
        sole discretion, prohibit the use of certain Third-Party Brokers
        whenever we consider it endangers the security of our systems.
        <br />
        <br />
        2.3. The use of our services
        <br />
        <br />
        The Customer accepts the full responsibility for all activities
        connected its compliance with the terms of this document. It is at all
        times the Customer who is a party to this agreement with AN, and who has
        all rights and obligations set out in this document.
        <br />
        <br />
        The Customer accepts that AN may contact the Customer, through the
        registered e-mail addresses and through telephone, and provide general
        marketing information or other information about the services.
        <br />
        <br />
        <b>Prohibited usage.</b> Under all circumstances, the Customer is
        strictly prohibited from permitting, assisting or enabling any
        individual or entity not authorized in the Customer’s Account from
        accessing or using the account. In the event that the Customer’s
        Personal Account Information is lost or stolen, or if there are reasons
        to believe that the account has been accessed by an unauthorized user,
        the Customer is required to immediately contact our Support Service.
        Notwithstanding any provision to the contrary, nothing in this document
        shall be construed, interpreted or applied to impose responsibility or
        liability on us or our affiliates for any and all activities arising
        from the authorized or unauthorized access of the account.
        <br />
        <br />
        The following activities are prohibited and will result in immediate
        account suspension or cancellation of the services without the
        possibility of a refund, but with the possibility for AN impose
        supplementary fees and/or pursue civil remedies, in case of legal
        damages:
        <br />
        <br />
        <ul>
          <li>
            - Fostering or promotion of illegal, abusive, or irresponsible
            behavior;
          </li>
          <li>
            - Misuse of system resources, such as programs that consume
            excessive network capacity, CPU cycles, or disk IO, disruption or
            interference of any data system or network, unauthorized collection
            or use of e-mail addresses, screen names or other identifiers, use
            of the service to distribute software that covertly gathers
            information about a user or covertly transmits information about the
            user, monitoring data or traffic on any network or system without
            the express authorization of the owner of the system or network;
          </li>
          <li>
            - Spam, Unsolicited Bulk Email, Forged Email, Phishing Email, etc;
          </li>
          <li>
            - Transmission, distribution, or storage of any information, data or
            material that is protected by copyright, trademark, trade secret, or
            any other intellectual property rights. Any usage that prompts the
            receipt of abuse complaints pertaining to violation of any
            international copyright law will be discontinued;
          </li>
          <li>
            - Access to other computers or networks without authorization;
          </li>
          <li>
            - Providing false data on any contract or application: including
            fraudulent use of credit card numbers;
          </li>
          <li>
            - Any conduct that is likely to result in retaliation against the AN
            network or website, including engaging in behavior that results in
            any server being the target of a DoS attack;
          </li>
          <li>
            - Any attempt to probe, scan, penetrate or test the vulnerability of
            AN systems or networks, or to breach the AN security or
            authentication measures, whether by passive or intrusive techniques;
          </li>
          <li>
            - Any other activities viewed as illegal or harmful by any
            jurisdiction;
          </li>
        </ul>
        <br />
        2.4. Requirements for use of the Services
        <br />
        <br />
        The Customer will use our services, provided that he/she is of legal age
        with respect to its domestic legislation and is not barred from
        receiving such services under any international law or jurisdiction.
        <br />
        <br />
        Access our services is provided based on the Customer’s current
        identification data, contact data, and any other information that is or
        might be required as part of the registration process. The Customer is
        solely responsible for the content, the confidentiality of the account
        information and for all activities that occur under its account. In case
        of any unauthorized use of the account or any other breach of security
        the Customer will immediately notify AN.
        <br />
        <br />
        The Customer and any of its Users shall not transfer viruses, malware or
        any other harmful code to the Services or use the Services in a manner
        that gives a risk for such transfer. The Services shall not be used for
        any illegal or otherwise unauthorized purpose. The Customer is
        responsible for the actions of the users of the account and for any data
        created, stored, displayed by, or transmitted by the account while using
        AN. The Customer will not engage in any activity that interferes with or
        disrupts AN's services or any networks that are connected to it.
        <br />
        <br />
        AN will not be liable for any loss or damage as a result of the
        Customer’s failure to provide us with accurate information or to keep
        the account secure.
      </p>

      <h3 className="sub-section-headline">3. OUR SERVICES</h3>
      <p className="body-text">
        <br />
        <ul>
          <li>
            • Reception and transmission of Pending Orders in relation to one or
            more Financial Instruments;
          </li>
          <li>• Execution of orders on behalf of the Customer;</li>
          <li>• Algorithmic trading services;</li>
          <li>
            • Technical services and platforms for assisted trading (such as
            copy-trading and trading signal interfaces)
          </li>
        </ul>
        <br />
        Subject to each Broker’s range of services, the above-listed Services
        may be provided and the activities may be exercised in regards but not
        limited to the following Financial Instruments:
        <br />
        <br />
        <ul>
          <li>• Transferable securities</li>
          <li>• Money-market instruments.</li>
          <li>• Units in collective investment undertakings.</li>
          <li>
            • Options, futures, swaps, forward rate agreements and any other
            derivative contracts relating to securities, currencies, interest
            rates or yields, or other derivatives instruments, financial indices
            or financial measures which may be settled physically or in cash.
          </li>
          <li>
            • Options, futures, swaps, forward rate agreements and any other
            derivative contracts relating to commodities that must be settled in
            cash or may be settled in cash at the option of one of the parties
            (otherwise than by reason of a default or other termination event).
          </li>
          <li>
            • Options, futures, swaps, and any other derivative contract
            relating to commodities that can be physically settled provided that
            they are traded on a regulated market or/and an MTF.
          </li>
          <li>
            • Options, futures, swaps, forwards and any other derivative
            contracts relating to commodities, that can be physically settled
            not being for commercial purposes, which have the characteristics of
            other derivative financial instruments, having regard to whether,
            inter alia, they are cleared and settled through recognized clearing
            houses or are subject to regular margin calls.
          </li>
          <li>• Derivative instruments for the transfer of credit risk.</li>
          <li>• Financial contracts for differences.</li>
          <li>
            • Options, futures, swaps, forward rate agreements and any other
            derivative contracts relating to climatic variables, freight rates,
            emission allowances or inflation rates or other official economic
            statistics that must be settled in cash or may be settled in cash at
            the option of one of the parties, as well as any other derivative
            contract relating to assets, rights, obligations, indices and
            measures which have the characteristics of other derivative
            financial instruments, having regard to whether, inter alia, they
            are traded on a regulated market or an MTF, are cleared and settled
            through recognized clearing houses or are subject to regular margin
            calls.
          </li>
        </ul>
      </p>

      <h3 className="sub-section-headline">4. TRADING</h3>
      <p className="body-text">
        <br />
        <b>Trading conditions.</b> The trading conditions are set out by the
        Customer’s Broker of choice.
        <br />
        <br />
        <b>Trade reporting.</b> Under applicable laws and regulations, we may be
        obliged to make certain information about certain trades public and to
        report the transaction details to competent authorities, such as the
        FCA. The Customer agrees and acknowledges that any and all proprietary
        rights in such trade and transaction information are owned by us and the
        Customer waive any duty of confidentiality attaching to the information
        which we reasonably disclose.
        <br />
        <br />
        <b>Commissions.</b> The Customer will pay us a commission only based on
        profits. Our commission rates applicable from time to time are
        accessible on our website. If the website does not contain a commission
        rate for any given Instrument, we shall charge you a fair and reasonable
        rate of commission based upon our commission rates for comparable
        Instruments.
        <br />
        <br />
        The commission rates that we charge are subject to change. We shall give
        you 14 days’ notice of any changes to the commission rates.
        <br />
        <br />
        We shall debit your Account with the commission payable by you upon your
        opening or closing a trade as the case may be.
      </p>

      <h3 className="sub-section-headline">5. INACTIVE/ ARCHIVED ACCOUNTS</h3>
      <p className="body-text">
        <br />
        The Customer acknowledges and confirms that any Trading Account held
        with the Company that are inactive for more than 3 (three) months are to
        be considered Inactive Account. Inactivity means that the Customer has
        not performed any trading Transactions on his Trading Account during the
        last 3 (three) months.
        <br />
        <br />
        The Customer acknowledges and confirms that the Customer will be subject
        to a monthly charge of fifty (50) USD or its equal in the currency of
        Trading Account relating to the maintenance, administration and
        compliance management of such Inactive Account.
        <br />
        <br />
        The Company reserves the right to charge aforementioned monthly charge
        for any month in which the Company had the right to charge it but did
        not do so for technical reasons.
        <br />
        <br />
        The Customer acknowledges and confirms that any Inactive Account will be
        subject to archiving, which means that no trading terminal
        authorization, trading or balance viewing operations will be available
        to the Customer.
        <br />
        <br />
        Once an Account is deemed inactive and is archived, any available
        trading benefits will be removed.
      </p>

      <h3 className="sub-section-headline">
        6. SUSPENDING OR TERMINATING THE ACCOUNT
      </h3>
      <p className="body-text">
        <br />
        <b>Right to suspend the Account.</b> We may, acting reasonably, suspend
        the Customer’s Account at any time and may, at any time and for any
        reason and without notice, suspend or change the username and/or
        password of any person authorized to trade on the Customer’s Account. If
        the Customer’s Account is suspended, they will be able to close any
        existing trades but will not be permitted to open any new trades. The
        circumstances where we may suspend the Customer’s Account include, but
        are not limited to:
        <br />
        <br />
        <ul>
          <li>
            • when we have not received information within 10 days of a request
            (or sooner if so reasonably required), when we believe that we
            require such information in connection with this document;
          </li>
          <li>
            • when we have reason to believe that there has been a breach in the
            Customer’s Account security or that there is a threat to the
            Customer’s Account security;
          </li>
          <li>
            • when the Customer’s trading activity or conduct is such that we
            believe acting reasonably at all times has or is likely to impair
            the integrity, functionality, speed or reliability of us or
            compromise, impair, restrict or prevent the ability of us to operate
            a fair and orderly market;
          </li>
        </ul>
        <br />
        <b>Right to close the Customer’s Account.</b> We may, acting reasonably,
        close the Customer’s Account at any time. We shall normally notify the
        Customer by email and such termination will take effect upon notice
        being sent to the email address. If we elect to close the Customer’s
        Account, where possible the Customer will have 14 working days to close
        any open trades. If the Customer has not closed all open trades within
        the 14 days provided, we shall be entitled to close all trades at the
        best available price from the next Business Day. The circumstances where
        we may close the Customer’s Account include but are not limited to
        where:
        <br />
        <br />
        <ul>
          <li>
            • the Customer repeatedly fails to provide information requested,
            when we believe that we require such information in connection with
            this document;
          </li>
          <li>
            • the Customer has persistently acted in an abusive manner toward
            our staff (for example by performing what we consider to be a
            serious discourtesy or the use of offensive or insulting language);
          </li>
          <li>
            • the information provided to us as part of the application process
            to open the Customer’s Account is demonstrably untrue;
          </li>
          <li>
            • in our opinion, the Customer’s trading is likely to have a
            negative impact on the quality of the our services and, therefore, a
            potential detrimental effect on the members of AN community.
          </li>
        </ul>
      </p>

      <h3 className="sub-section-headline">
        7. RESPONSIBILITY FOR QUALITY OF SERVICE, AVAILABILITY, AND SUPPORT
      </h3>
      <p className="body-text">
        <br />
        <b>Quality of Service.</b> AN warrants that the Services will perform
        substantially as described in applicable documentation of Services. AN
        guarantees an annual mean 95%-availability of the physical connection to
        the servers. Exempted hereof are periods of time in which the servers
        are not reachable over the internet due to technical or other problems
        which do not lie within our sphere of influence (force majeure, faults
        of third parties or of the Customer). The Services will be subject to
        permanent improvement.
        <br />
        <br />
        If the Services does not function as described in applicable
        documentation of Services, AN will correct verified errors in the
        services at AN’s own expense. AN may choose to replace the Services or
        functionality therein instead of performing a correction. If AN does not
        solve the verified errors according to the time-limits set out together
        with the description of the Services in question or does not replace the
        Service within a reasonable time, the Customer may cancel their
        subscription in accordance with the terms of this document. The Customer
        may not set forth any other claims due to defects or errors in the
        Services.
        <br />
        <br />
        <b>Service Level Agreement (SLA) targets, penalties.</b> AN’s Service
        availability targets, SLA penalties, and fixed maintenance periods are
        set out together with the description of the Services in question.
        <br />
        <br />
        AN may, from time to time, require maintenance periods, for example for
        major upgrades. Such maintenance periods will generally take place over
        weekends or overnight and will be notified to the Customer at least 48
        hours in advance.
        <br />
        <br />
        <b>Notification of errors.</b> If the Customer experiences that the
        Services in whole or in part are unavailable, or have reduced
        performance, the Customer shall report the error via email to
        admin@altninjas.com. In order to ensure that AN gets the necessary
        information to identify and correct the error, the Customer shall,
        together with the notification of error, provide accurate information
        about the error, including a description of how the error occurred.
        <br />
        <br />
        <b>Support.</b> AN will provide basic technical support to the Customer.
        This entails technical assistance, but AN does not warrant that any
        solution will be found for any problems or requests. The administration
        and configuration of the Services for the Customer are not included in
        the support and may be provided according to separate agreements.
        <br />
        <br />
        The Customer may contact AN support on business days (Monday to Friday
        except for Christmas Eve, New Year’s Eve and other public holidays)
        between 09:00 and 17:00 hours Vancouver time. Any contact with AN
        support shall take place via admin@altninjas.com.
      </p>

      <h3 className="sub-section-headline">8. SECURITY</h3>
      <p className="body-text">
        <br />
        <b>Confidentiality.</b> Customer’s privacy is important to AN and we
        will abide by our Privacy Policy. AN reserves the right to modify their
        Privacy Policy from time to time.
        <br />
        <br />
        <b>Processing of personal data.</b> The Services offered by AN entail
        collecting and processing of Customer’s personal data.
        <br />
        <br />
        AN is the Controller and Processor, in accordance with the international
        data protection regulations. The Customer can find all the relevant
        legal details relating to data Protection in the Privacy Policy section
        of the website.
      </p>

      <h3 className="sub-section-headline">9. SPECIAL DISCLAIMER</h3>
      <p className="body-text">
        <br />
        THE OUR SERVICES ARE PROVIDED "AS IS." WE, OUR AFFILIATES AND OUR
        EVENTUAL SUBPROCESSORS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY
        KIND, WHETHER EXPRESSED, IMPLIED, STATUTORY OR OTHERWISE REGARDING THE
        OUR SERVICES, INCLUDING ANY WARRANTY THAT THE OUR SERVICES WILL BE
        UNINTERRUPTED, ERROR FREE OR FREE OF RISK OR ADVERSE ELEMENTS, OR THAT
        ANY CONTENT, INCLUDING THE CUSTOMER DATA, WILL BE SECURE OR NOT
        OTHERWISE LOST OR DAMAGED. EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE
        AND OUR AFFILIATES AND SUBPROCESSORS DISCLAIM ALL WARRANTIES, INCLUDING
        ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS
        FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR QUIET ENJOYMENT, AND ANY
        WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE.
      </p>

      <h3 className="sub-section-headline">10. LIMITATION OF LIABILITY.</h3>
      <p className="body-text">
        <br />
        <b>Limitation of liability.</b> If AN is held responsible for paying
        damages to the Customer as a consequence of breaches of any of the
        obligations under this Agreement, such damages may in no event include
        compensation for indirect loss or damages of any kind which may arise as
        a result of, or in connection with, such breach. Indirect loss includes,
        but is not limited to, loss of profit of any kind, losses as a
        consequence of disrupted operations, loss of data, lost savings. AN’s
        liability under this Agreement is therefore limited to direct loss,
        unless otherwise set out in mandatory applicable law, for example,
        damages due to gross negligence or intent.
        <br />
        <br />
        If standardized sanctions are agreed, these standardized sanctions shall
        be the sole and exclusive remedy for the matter and no other claims may
        be made based on the same situation.
        <br />
        <br />
        Even though AN will use appropriate care to ensure secure transmission
        of information between the Customer and the Services, the Customer
        recognizes that the Internet is an open system and that AN cannot
        warrant that a third party cannot or will not intercept or alter data
        during the transmission. AN takes no responsibility for such
        unauthorized access to, use or alternation or publication or loss of
        data.
        <br />
        <br />
        Neither is AN responsible for lack of availability of the Services when
        this is directly or indirectly caused by the Customer or by
        circumstances for which the Customer is responsible or the
        reconstruction of data regardless of cause.
      </p>

      <h3 className="sub-section-headline">11. FORCE MAJEURE</h3>
      <p className="body-text">
        <br />
        If the use and execution of the Services are wholly or partly prevented
        or materially impeded by circumstances beyond the parties’ control, both
        parties’ obligations are suspended for as long as the circumstances are
        relevant and as long as these circumstances last. Each party may,
        however, in accordance with section 10 of this Agreement, terminate the
        Agreement if the force majeure makes it particularly burdensome for that
        party to continue the Agreement.
        <br />
        <br />
        In the event that law, rules or regulations applicable to the use or
        delivery of the Services is changed or new rules or regulations are
        adopted after the Services have been made available on the market and
        this prevents AN from fulfilling the Customer’s instructions regarding
        processing of personal data or other obligations in this Agreement,
        and/or this requires full or partial termination of access to the
        Services for a limited or indefinite period of time, this shall be
        considered as a force majeure circumstance. AN is not in any way
        responsible for any such or other force majeure circumstance.
      </p>

      <h3 className="sub-section-headline">
        12. TRANSFER OF ACCOUNT OR SERVICES
      </h3>
      <p className="body-text">
        <br />
        The Customer is not entitled to transfer all or part of the right to use
        the Services to another entity. AN may fully or partially transfer its
        rights and obligations under the Agreement to subsidiaries or other
        companies within the same group, hereunder use these as sub-contractors,
        provided that this is done in such a manner that it is ensuring
        compliance with the obligations under all relevant data protection laws
        from the Customer’s perspective.
      </p>

      <h3 className="sub-section-headline">13. CUSTOMER COMPLAINTS</h3>
      <p className="body-text">
        <br />
        The Company shall maintain effective and transparent procedures for the
        reasonable and prompt handling of complaints received from the Customer
        and shall keep a record of each complaint and the measures taken for its
        resolution or handling.
        <br />
        <br />
        The Parties agree to make every reasonable effort for resolving any
        disputes between them before commencing any litigation proceedings.
      </p>

      <h3 className="sub-section-headline">14. GOVERNING LAW</h3>
      <p className="body-text">
        <br />
        This Agreement is governed by and construed in accordance with all the
        applicable international laws. Exclusive jurisdiction and venue for any
        action arising under this Agreement are in the Courts of Justice from
        the headquarters of AN, and both parties hereby consent to such
        jurisdiction and venue for this purpose. In any action or proceeding to
        enforce or interpret this Agreement, the prevailing party will be
        entitled to recover from the other party its costs and expenses
        (including reasonable attorneys' fees) incurred in connection with such
        action or proceeding and enforcing any judgment or order obtained.
      </p>

      <h3 className="sub-section-headline">15. ENTIRE AGREEMENT</h3>
      <p className="body-text">
        <br />
        This Agreement together with the Terms and Conditions and the Privacy
        Policy constitute the entire agreement between the parties concerning
        the contractual relationship.
        <br />
        <br />
        No change, consent or waiver to this Agreement will be effective unless
        in writing and signed by the party against which enforcement is sought.
        The failure of AN to enforce its rights under this Agreement at any time
        for any period shall not be construed as a waiver of such rights. Unless
        expressly provided otherwise, each right and remedy in this Agreement is
        in addition to any other right or remedy, at law or in equity, and the
        exercise of one right or remedy will not be deemed a waiver of any other
        right or remedy.
      </p>

      <h3 className="sub-section-headline">16. NOTIFICATIONS</h3>
      <p className="body-text">
        <br />
        Any notice hereunder will be effective upon receipt and shall be given
        in writing, in English and delivered to the other party at its address
        given herein for AN and in the account information for the Customer.
      </p>

      <h3 className="sub-section-headline">17. ACKNOWLEDGMENT OF TERMS</h3>
      <p className="body-text">
        <br />
        The Customer acknowledges that they:
        <br />
        <br />
        <ul>
          <li>
            (a) have read this agreement prior to signing up for the corporate
            account;
          </li>
          <li>(b) understand the terms of this agreement;</li>
          <li>
            (c) have signed this agreement voluntarily, by signing up for the
            account;
          </li>
        </ul>
      </p>

      <p className="body-text">
        IN WITNESS WHEREOF, the Parties have executed this Agreement as of the
        date of the signup.
      </p>
    </section>
  </main>
);

export default TermsAndConditionsPage;
