import { SettingsTypes } from "./settings.types";
import { SnackbarTypes } from "../Snackbar/snackbar.types";
import {
  Urls,
  getRequest,
  postRequest,
  deleteRequest,
  patchRequest
} from "../../api";
import { handleValidationError } from "../utils.redux";

const handleSettingsUpdateResponse = ({ data, dispatch, type }) => {
  dispatch({
    type,
    payload: data
  });
  dispatch({
    type: SnackbarTypes.OPEN_SNACKBAR,
    payload: data.snackbar
  });
};

const handleError = ({ error, dispatch, type }) => {
  dispatch({
    type
  });

  dispatch({
    type: SnackbarTypes.OPEN_SNACKBAR,
    payload: error.response.data.snackbar
  });
};

const handleFormValidationError = ({ error, dispatch, type }) => {
  const { data } = error.response;

  if (data.snackbar) {
    dispatch({
      type,
      payload: null
    });

    dispatch({
      type: SnackbarTypes.OPEN_SNACKBAR,
      payload: data.snackbar
    });
  } else dispatch({ type, payload: data });
};

export const getProfileSettings = () => dispatch => {
  dispatch({ type: SettingsTypes.GET_SETTINGS_PENDING });

  getRequest(Urls.server, "/user/settings")
    .then(data =>
      dispatch({ type: SettingsTypes.GET_SETTINGS_SUCCESS, payload: data })
    )
    .catch(error =>
      handleError({ error, dispatch, type: SettingsTypes.GET_SETTINGS_FAILED })
    );
};

export const submitTradeProfile = formData => dispatch => {
  dispatch({ type: SettingsTypes.SUBMIT_TRADE_PROFILE_PENDING });

  patchRequest(Urls.server, "/user/settings/trade-profile", formData)
    .then(data => {
      dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
      dispatch({
        type: SettingsTypes.SUBMIT_TRADE_PROFILE_SUCCESS,
        payload: data
      });
      dispatch(getProfileSettings());
    })
    .catch(error =>
      handleError({
        error,
        dispatch,
        type: SettingsTypes.SUBMIT_TRADE_PROFILE_FAILED
      })
    );
};

export const changePassword = formData => dispatch => {
  dispatch({ type: SettingsTypes.CHANGE_PASSWORD_PENDING });

  postRequest(Urls.server, "/user/settings/change-password", formData)
    .then(data => {
      dispatch({ type: SettingsTypes.CHANGE_PASSWORD_SUCCESS });
      dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
    })
    .catch(error =>
      handleFormValidationError({
        error,
        dispatch,
        type: SettingsTypes.CHANGE_PASSWORD_FAILED
      })
    );
};

export const submitBlacklistCoin = symbol => dispatch => {
  dispatch({ type: SettingsTypes.UPDATE_COIN_BLACKLIST_PENDING });

  postRequest(Urls.server, "/user/settings/coin-blacklist", symbol)
    .then(data =>
      handleSettingsUpdateResponse({
        data,
        dispatch,
        type: SettingsTypes.UPDATE_COIN_BLACKLIST_SUCCESS
      })
    )
    .catch(error =>
      handleFormValidationError({
        error,
        dispatch,
        type: SettingsTypes.UPDATE_COIN_BLACKLIST_FAILED
      })
    );
};

export const deleteBlacklistCoin = symbol => dispatch => {
  dispatch({ type: SettingsTypes.UPDATE_COIN_BLACKLIST_PENDING });

  deleteRequest(Urls.server, `/user/settings/coin-blacklist?symbol=${symbol}`)
    .then(data =>
      handleSettingsUpdateResponse({
        data,
        dispatch,
        type: SettingsTypes.UPDATE_COIN_BLACKLIST_SUCCESS
      })
    )
    .catch(error =>
      handleFormValidationError({
        error,
        dispatch,
        type: SettingsTypes.UPDATE_COIN_BLACKLIST_FAILED
      })
    );
};

export const updateNotifications = formData => dispatch => {
  dispatch({ type: SettingsTypes.UPDATE_NOTIFICATIONS_PENDING });

  postRequest(Urls.server, "/user/settings/notifications", formData)
    .then(data =>
      handleSettingsUpdateResponse({
        data,
        dispatch,
        type: SettingsTypes.UPDATE_NOTIFICATIONS_SUCCESS
      })
    )
    .catch(error =>
      handleError({
        error,
        dispatch,
        type: SettingsTypes.UPDATE_NOTIFICATIONS_FAILED
      })
    );
};

export const toggleAccountActivated = isActivated => dispatch => {
  dispatch({ type: SettingsTypes.UPDATE_ACCOUNT_STATUS_PENDING });

  postRequest(Urls.server, "/user/account-status", isActivated)
    .then(data =>
      handleSettingsUpdateResponse({
        data,
        dispatch,
        type: SettingsTypes.UPDATE_ACCOUNT_STATUS_SUCCESS
      })
    )
    .catch(error =>
      handleError({
        error,
        dispatch,
        type: SettingsTypes.UPDATE_ACCOUNT_STATUS_FAILED
      })
    );
};

const createBinanceCredentialsActionCreator = (
  axiosRequestFunction,
  url,
  endpoint
) => formData => dispatch => {
  dispatch({ type: SettingsTypes.EDIT_BINANCE_CREDENTIALS_PENDING });

  axiosRequestFunction(url, endpoint, formData)
    .then(data => {
      handleSettingsUpdateResponse({
        data,
        dispatch,
        type: SettingsTypes.EDIT_BINANCE_CREDENTIALS_SUCCESS
      });
      dispatch(getProfileSettings());
    })
    .catch(error =>
      handleValidationError({
        error,
        dispatch,
        type: SettingsTypes.EDIT_BINANCE_CREDENTIALS_FAILED,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR
      })
    );
};

export const saveBinanceCredentials = createBinanceCredentialsActionCreator(
  postRequest,
  Urls.server,
  "/binance/save-credentials"
);

export const clearBinanceCredentials = createBinanceCredentialsActionCreator(
  patchRequest,
  Urls.server,
  "/binance/clear-credentials"
);
