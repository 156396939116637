import { SignalHistoryTypes } from "./signal-history.types";

const INITIAL_STATE = {
  isPending: false,
  signals: [],
  page: null,
  nextPage: null,
  prevPage: null,
  totalPages: null,
  lowerDisplay: null,
  upperDisplay: null,
  totalSignals: null,
  currentFilter: {
    username: "",
    symbol: "",
    startDate: null,
    endDate: null
  }
};

const signalHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SignalHistoryTypes.SIGNAL_HISTORY_QUERY_PENDING:
      return { ...state, isPending: true };

    case SignalHistoryTypes.SIGNAL_HISTORY_QUERY_SUCCESS:
      const { signals, currentFilter, pagination } = action.payload;
      return {
        ...state,
        isPending: false,
        signals,
        page: Number(pagination.page),
        totalPages: Number(pagination.totalPages),
        currentFilter: {
          username: currentFilter.username,
          symbol: currentFilter.symbol,
          startDate: currentFilter.startDate,
          endDate: currentFilter.endDate
        },
        lowerDisplay: pagination.lowerDisplay,
        upperDisplay: pagination.upperDisplay,
        totalSignals: pagination.totalSignals,
      };
    case SignalHistoryTypes.SIGNAL_HISTORY_QUERY_FAILED:
      return { ...state, isPending: false };

    default:
      return state;
  }
};

export default signalHistoryReducer;
