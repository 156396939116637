import "./WithdrawalsAndDepositsTableRow.scss";
import React, {useEffect} from "react";
import {openAssignModal} from "../../../../redux/AssignTransactionToInvoiceModal/assign-modal.actions";
import { connect } from "react-redux";
import moment from "moment-timezone";

const WithdrawalsAndDepositsTableTableRow = ({data, index, openAssignModal, selectedUser}) => {
    const darkBgClass = index % 2 !== 0 ? "" : "withdrawals-and-deposits-table-row--dark";

    function openModal() {
        openAssignModal({
            transaction: data,
            user: selectedUser,
        });
    }

    return (
        <div
            onClick={openModal}
            tabIndex={1}
            className={`withdrawals-and-deposits-table-row ${darkBgClass}`}
        >
            <span className="withdrawals-and-deposits-table-row__data">
              {data.isDeposit ? "Deposit" : "Withdrawal"}
            </span>
            <span className="withdrawals-and-deposits-table-row__data remove-at-sm">
                {data.date ? (
                    <>
                      {moment(data.date).tz("America/Vancouver").format("h:mm a - M/D/YYYY")}
                    </>
                ) : (
                    <>N/A</>
                )}
            </span>
            {/*<span className="withdrawals-and-deposits-table-row__data remove-at-sm">*/}
            {/*    <span style={{display: "flex"}}>*/}
            {/*        <span className="text-concat-cell">*/}
            {/*            {data.binanceID ? data.binanceID : "N/A"}*/}
            {/*        </span>*/}
            {/*    </span>*/}
            {/*</span>*/}
            <span className="withdrawals-and-deposits-table-row__data">
              {data.amountInBTC + " BTC"}
            </span>
            <span className="withdrawals-and-deposits-table-row__data">
              {"$" + data.amountInUSDT}
            </span>
            <span className="withdrawals-and-deposits-table-row__data remove-at-sm">
              {data.isDeposit ? "N/A" : (data.isPaired ? "YES" : "NO")}
            </span>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    openAssignModal: transactionAndUserData => dispatch(openAssignModal(transactionAndUserData))
});

export default connect(null, mapDispatchToProps)(WithdrawalsAndDepositsTableTableRow);
