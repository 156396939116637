import axios from 'axios';

import { Urls, postRequest } from '../../api';
import { RegistrationTypes } from './registration.types';
import { SnackbarTypes } from '../Snackbar/snackbar.types';

const createRegistrationActionCreator = endPoint => formData => dispatch => {
  dispatch({
    type: RegistrationTypes.REGISTER_USER_PENDING,
  });

  postRequest(Urls.server, endPoint, formData)
    .then(data => {
      dispatch({ type: RegistrationTypes.REGISTER_USER_SUCCESS });
      dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
    })
    .catch(error => {
      if (error.response.data.snackbar) {
        dispatch({
          type: RegistrationTypes.REGISTER_USER_FAILED,
          payload: null,
        });

        dispatch({
          type: SnackbarTypes.OPEN_SNACKBAR,
          payload: error.response.data.snackbar,
        });
      } else
        dispatch({
          type: RegistrationTypes.REGISTER_USER_FAILED,
          payload: error.response.data,
        });
    });
};

export const registerUser = createRegistrationActionCreator('/register');

export const registerSignaler = createRegistrationActionCreator(
  '/register/signaler',
);
