import React from "react";
import { Link } from "react-router-dom";

import BinanceIcon from "../../../static/svg/binance-logo-small.svg";
import ProfileIcon from "../../../static/svg/profile-icon-sm.svg";
import "./AccountStatus.scss";

const AccountStatus = ({
  binanceCredentialsComplete,
  tradeProfileComplete,
  isActivated,
  role
}) => {
  const accountIsComplete = binanceCredentialsComplete && tradeProfileComplete;

  const statusCompleteClass = accountIsComplete
    ? "account-status--complete"
    : "";

  const statusActiveClass = isActivated ? "account-status--active" : "";

  let statusMsg;

  if (isActivated) statusMsg = "account activated";
  else if (!accountIsComplete) statusMsg = "account incomplete";

  const statusSubText = accountIsComplete ? (
    <span className="account-status__sub-text">
      Watch for signals on the{" "}
      <Link to="/dashboard/signal-history">signal history</Link> page.
    </span>
  ) : (
    <span className="account-status__sub-text">
      Here is what you need to do to finish setting up your account:
    </span>
  );
  return (
    <>
      {role === "user" ? (
        <div
          className={`account-status ${statusCompleteClass} ${statusActiveClass}`}
        >
          <div className="account-status__msg-container ">
            <span className="account-status__msg">{statusMsg}</span>
            {statusSubText}
          </div>
          {accountIsComplete ? null : (
            <div className="account-status__todos">
              {binanceCredentialsComplete ? null : (
                <div className="account-status__todo-item account-status__todo-item--binance">
                  <img
                    src={BinanceIcon}
                    alt="binance logo"
                    className="account-status__todo-icon "
                  />
                  Add your binance credentials
                </div>
              )}
              {tradeProfileComplete ? null : (
                <div className="account-status__todo-item">
                  <img
                    src={ProfileIcon}
                    alt="binance logo"
                    className="account-status__todo-icon account-status__todo-icon--profile"
                  />
                  Complete your trade profile
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default AccountStatus;
