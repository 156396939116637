import {AssignModalTypes} from "./assign-modal.types";

const INITIAL_STATE = {
    isOpen: false,
    transactionAndUserData: {
        transaction: null,
        user: null,
    },
    assignInvoicePending: false,
};

const assignInvoiceToTransactionModalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AssignModalTypes.ASSIGN_MODAL_OPEN:
            return { ...state, isOpen: true, transactionAndUserData: action.payload };

        case AssignModalTypes.ASSIGN_MODAL_CLOSE:
            return { ...state, isOpen: false, transactionAndUserData: null };

        case AssignModalTypes.ASSIGN_MODAL_SUBMIT_PENDING:
            return { ...state, assignInvoicePending: true };

        case AssignModalTypes.ASSIGN_MODAL_SUBMIT_SUCCESS:
            return { ...state, assignInvoicePending: false };

        case AssignModalTypes.ASSIGN_MODAL_SUBMIT_FAILED:
            return { ...state, assignInvoicePending: false };

        default:
            return state;
    }
};

export default assignInvoiceToTransactionModalReducer;
