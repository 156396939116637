import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ReactComponent as BTCIcon } from '../../../../../static/svg/btc-icon.svg';
import { ReactComponent as LTCIcon } from '../../../../../static/svg/ltc-icon.svg';
import { ReactComponent as ETHIcon } from '../../../../../static/svg/eth-icon.svg';
import { ReactComponent as XMRIcon } from '../../../../../static/svg/xmr-icon.svg';
import { ReactComponent as XRPIcon } from '../../../../../static/svg/xrp-icon.svg';
import { ReactComponent as EOSIcon } from '../../../../../static/svg/eos-icon.svg';

import './DisplayBox.scss';

const DisplayBox = ({ coin }) => {
  const formatPrice = price => {
    const decimalIndex = price.indexOf('.');
    return price.substring(0, decimalIndex + 3);
  };

  const formatDate = timestamp => {
    const formatted = timestamp.format('M/D/YY');
    return formatted;
  };

  const formatTime = timestamp => {
    const formatted = timestamp.format('hh:mm:ss');
    return formatted;
  };

  switch (coin.symbol) {
    case 'BTC':
      coin.img = {
        svg: BTCIcon,
      };
      break;
    case 'LTC':
      coin.img = {
        svg: LTCIcon,
      };
      break;
    case 'ETH':
      coin.img = {
        svg: ETHIcon,
      };
      break;

    case 'XMR':
      coin.img = {
        svg: XMRIcon,
      };
      break;

    case 'XRP':
      coin.img = {
        svg: XRPIcon,
      };
      break;

    case 'EOS':
      coin.img = {
        svg: EOSIcon,
      };
      break;

    default:
      break;
  }

  return (
    <div className="display-box">
      <h3 className="display-box__symbol">{coin.symbol}</h3>
      <coin.img.svg className="display-box__icon" />
      <span className="display-box__price">
        {`${formatPrice(coin.price)} USD`}
      </span>
      <div className="display-box__date-container">
        <span className="display-box__date">{formatDate(coin.timestamp)}</span>
        <span className="display-box__time">{formatTime(coin.timestamp)}</span>
      </div>
    </div>
  );
};

DisplayBox.propTypes = {
  coin: PropTypes.object.isRequired,
};

export default DisplayBox;
