import { SnackbarTypes } from '../Snackbar/snackbar.types';
import {Urls, postRequest, deleteRequest} from '../../api';
import {InvoiceModalTypes} from "./invoice-modal.types";
import {getInvoiceTableData} from "../Invoices/invoices.actions";

export const openUserInvoiceModal = invoiceData => dispatch => {
    dispatch({ type: InvoiceModalTypes.USER_INVOICE_MODAL_OPEN, payload: invoiceData });
};

export const closeUserInvoiceModal = () => dispatch => {
    dispatch({ type: InvoiceModalTypes.USER_INVOICE_MODAL_CLOSE });
};

export const openInvoiceModal = invoiceData => dispatch => {
    dispatch({ type: InvoiceModalTypes.INVOICE_MODAL_OPEN, payload: invoiceData });
};

export const closeInvoiceModal = () => dispatch => {
    dispatch({ type: InvoiceModalTypes.INVOICE_MODAL_CLOSE });
};

export const cancelInvoice = invoiceData => dispatch => {
    dispatch({type: InvoiceModalTypes.INVOICE_MODAL_CANCEL_INVOICE_PENDING});

    deleteRequest(Urls.server, `/invoices/delete?invoice_id=${invoiceData.id}`)
        .then(data => {
            // dispatch({ type: InvoiceModalTypes.INVOICE_MODAL_CANCEL_INVOICE_FAILED });
            dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
            dispatch({ type: InvoiceModalTypes.INVOICE_MODAL_CLOSE });
            dispatch(getInvoiceTableData({page: 1, pendingOnly: false}));
        })
        .catch(error => {
            dispatch({ type: InvoiceModalTypes.INVOICE_MODAL_CANCEL_INVOICE_FAILED });
            dispatch({
                type: SnackbarTypes.OPEN_SNACKBAR,
                payload: error.response.data.snackbar,
            });
            dispatch({ type: InvoiceModalTypes.INVOICE_MODAL_CLOSE});
        });
};
