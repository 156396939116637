import React from 'react';
import { Switch } from '@material-ui/core';

import './SwitchInput.scss';

const SwitchInput = ({
  classnames,
  checked,
  onChange,
  value,
  name,
  id,
  disabled,
}) => {
  return (
    <Switch
      className={`switch-input ${classnames}`}
      checked={checked}
      onChange={onChange}
      value={value}
      name={name}
      id={id}
      disabled={disabled}
    />
  );
};

export default SwitchInput;
