import io from "socket.io-client";
const isDevEnv = window.location.href.includes("localhost");

const url = isDevEnv
  ? "http://localhost:3000"
  : "https://trading.altninjas.com";

const socket = io(url);

export default socket;
