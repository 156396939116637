import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { convertDateTimeToCurrentTimezone } from "../../../format";
import { ReactComponent as CloseIcon } from "../../../static/svg/close-icon.svg";
import { ReactComponent as CancelSignalIcon } from "../../../static/svg/cancel-signal-icon.svg";
import {
  closeSignalModal,
  cancelSignal,
} from "../../../redux/SignalModal/signal-modal.actions";

import "./SignalModal.scss";

const DataGroup = ({ label, data, labelColorClass }) => (
  <div className="data-group">
    <span className={`data-group__label ${labelColorClass}`}>{label}</span>
    <span className="data-group__data">{data}</span>
  </div>
);

const SignalModal = ({
  role,
  isOpen,
  signalData,
  closeSignalModal,
  cancelSignal,
}) => {
  useEffect(() => {
    const handleClickOnBackground = (e) => {
      const { classList } = e.target;

      if (classList.contains("dashboard") && isOpen) closeSignalModal();
    };

    window.addEventListener("click", handleClickOnBackground);

    return () => {
      window.removeEventListener("click", handleClickOnBackground);
    };
  }, [isOpen, closeSignalModal]);

  const assetSymbol = signalData.symbol_with_slash.split("/")[0];
  const priceSymbol = signalData.symbol_with_slash.split("/")[1];
  const formattedSignalData = {
    order_id: signalData.order_id,
    symbol: signalData.symbol,
    type: signalData.type,
    side: signalData.side,
    quantity: signalData.quantity + ` ${assetSymbol}`,
    price: signalData.price + ` ${priceSymbol}`,
    stop_price: signalData.stop_price  + ` ${priceSymbol}`,
    timestamp: signalData.timestamp,
    status: signalData.status,
    time_in_force: signalData.time_in_force,
    user: signalData.user,
    signaler: signalData.signaler,
    currentPrice: signalData.currentPrice + ` ${priceSymbol}`,
    priceDifference: signalData.priceDifference + "%",
  }

  return (
    <div className="signal-modal">
      <CloseIcon
        aria-label="close"
        onClick={closeSignalModal}
        className="signal-modal__close-btn"
      />
      <div className="signal-modal__content">
        <div className="signal-modal__row">
          <DataGroup
            label="oid"
            data={formattedSignalData.order_id}
            labelColorClass="data-group__label--blue"
          />
          <DataGroup
            label="symbol"
            data={formattedSignalData.symbol}
            labelColorClass="data-group__label--blue"
          />
          <DataGroup
            label="current price"
            data={formattedSignalData.currentPrice || "N/A"}
            labelColorClass="data-group__label--violet"
          />
        </div>
        <div className="signal-modal__row">
          <DataGroup
            label="type"
            data={formattedSignalData.type}
            labelColorClass="data-group__label--yellow"
          />
          <DataGroup
            label="side"
            data={formattedSignalData.side}
            labelColorClass="data-group__label--yellow"
          />

          <DataGroup
            label="price difference"
            data={formattedSignalData.priceDifference || "N/A"}
            labelColorClass="data-group__label--violet"
          />
        </div>
        <div className="signal-modal__row">
          <DataGroup
            label="quantity"
            data={formattedSignalData.quantity}
            labelColorClass="data-group__label--violet"
          />
          <DataGroup
            label="price"
            data={formattedSignalData.price}
            labelColorClass="data-group__label--violet"
          />

          <DataGroup
            label="stop price"
            data={formattedSignalData.stop_price}
            labelColorClass="data-group__label--violet"
          />
        </div>
        <div className="signal-modal__row">
          <DataGroup
            label="date"
            data={convertDateTimeToCurrentTimezone({
              dateTime: formattedSignalData.timestamp,
              format: "YYYY-MM-DD",
            })}
            labelColorClass="data-group__label--green"
          />
          <DataGroup
            label="status"
            data={formattedSignalData.status}
            labelColorClass="data-group__label--green"
          />
          <DataGroup
            label="time in force"
            data={formattedSignalData.time_in_force}
            labelColorClass="data-group__label--green"
          />
        </div>
        {role === "signaler" || role === "superAdmin" ? (
          <div className="signal-modal__row">
            <DataGroup
              label="user"
              data={formattedSignalData.user}
              labelColorClass="data-group__label--red"
            />
            {role === "signaler" || role === "superAdmin" ? (
              <DataGroup
                label="signaler"
                data={formattedSignalData.signaler}
                labelColorClass="data-group__label--red"
              />
            ) : null}
          </div>
        ) : null}
        {(role === "signaler" || role === "superAdmin") &&
        signalData.cancel_signal_capability === true &&
        signalData.status !== "FILLED" &&
        signalData.status !== "CANCELED" &&
        signalData.status.split(" -")[0] !== "MISSED" ? (
          <div className="signal-modal__cancel-signal">
            <span>Cancel Signal</span>
            <CancelSignalIcon
              onClick={() =>
                cancelSignal({
                  oid: signalData.order_id,
                  symbol: signalData.symbol,
                  timestamp: signalData.transact_time,
                  userId: signalData.user_id,
                })
              }
              aria-label="cancel signal"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeSignalModal: () => dispatch(closeSignalModal()),
  cancelSignal: (signalData) => dispatch(cancelSignal(signalData)),
});

const mapStateToProps = ({
  signalModal: { isOpen, signalData },
  user: {
    data: { role },
  },
}) => ({
  isOpen,
  signalData,
  role,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignalModal);
