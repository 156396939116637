import {SymbolsTypes} from "./symbols.types";

const INITIAL_STATE = {
    isPending: false,
    symbolList: [],
};

const symbolsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SymbolsTypes.GET_SYMBOLS_PENDING:
            return {...state, isPending: true};

        case SymbolsTypes.GET_SYMBOLS_SUCCESS:
            return {
                ...state,
                isPending: false,
                symbolList: action.payload,
            };

        case SymbolsTypes.GET_SYMBOLS_FAILED:
            return {...state, isPending: false};

        default:
            return state;
    }
};

export default symbolsReducer;
