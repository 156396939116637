import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import HomeIcon from "./HomeIcon";
import TradeInterfaceIcon from "./TradeInterfaceIcon";
import SignalHistoryIcon from "./SignalHistoryIcon";
import ActivityNotificationsIcon from "./ActivityNotificationsIcon";
import SettingsIcon from "./SettingsIcon";
import LogOutIcon from "./LogOutIcon";
import { ReactComponent as ChevronIcon } from "../../../static/svg/chevron-right.svg";
import { toggleDashMenuOpen } from "../../../redux/DashMenu/dash-menu.actions";
import { logoutUser } from "../../../redux/User/user.actions";

import "./DashboardMenu.scss";
import InvoicingIcon from "./InvoicingIcon";

const DashboardMenu = ({ route, user, isOpen, toggleDashMenu, logoutUser }) => {
  const { role, username, id } = user;

  const handleClickMenuLink = () => {
    if (window.innerWidth < 900) toggleDashMenu();
  };

  const returnLinkActiveClass = link => (link === route ? "active" : "");

  const dashMenuOpenClass = isOpen ? "menu-open" : "";

  useEffect(() => {
    const handleWindowResize = e => {
      if (isOpen && e.innerWidth > 900) toggleDashMenu();
    };

    const handleClickOnBackground = e => {
      const { classList } = e.target;

      if (classList.contains("dashboard") && isOpen) toggleDashMenu();
    };

    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("click", handleClickOnBackground);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("click", handleClickOnBackground);
    };
  });
  return (
    <div className={`dashboard-menu ${dashMenuOpenClass}`}>
      <ChevronIcon
        onClick={toggleDashMenu}
        className={`dashboard-menu__back-button ${dashMenuOpenClass}`}
      />
      <div className="dashboard-menu__avatar-group">
        <span className="dashboard-menu__username">{username}</span>
        {role === "signaler" ? (
          <span className="dashboard-menu__role">signaler</span>
        ) : null}
        {role === "superAdmin" ? (
          <div>
            <span className="dashboard-menu__role">super</span>
            <span className="dashboard-menu__role dashboard-menu__role--emphasized">
              Admin
            </span>
          </div>
        ) : null}
      </div>
      <nav>
        <ul className="dashboard-menu__nav-list">
          <li>
            <Link onClick={handleClickMenuLink} to="/dashboard/home">
              <span
                className={`dashboard-menu__link ${returnLinkActiveClass(
                  "home"
                )}`}
              >
                <HomeIcon />
                Home
              </span>{" "}
            </Link>
          </li>
          {role === "signaler" || role === "superAdmin" ? (
            <li>
              <Link
                onClick={handleClickMenuLink}
                to="/dashboard/trade-interface"
              >
                <span
                  className={`dashboard-menu__link ${returnLinkActiveClass(
                    "trade-interface"
                  )}`}
                >
                  <TradeInterfaceIcon />
                  Trade Interface
                </span>{" "}
              </Link>
            </li>
          ) : null}

          <li>
            <Link onClick={handleClickMenuLink} to="/dashboard/signal-history">
              <span
                className={`dashboard-menu__link ${returnLinkActiveClass(
                  "signal-history"
                )}`}
              >
                <SignalHistoryIcon /> Signal History
              </span>
            </Link>
          </li>

          {role === "user" ? (
              <li>
                <Link
                    onClick={handleClickMenuLink}
                    to="/dashboard/user-invoices"
                >
                <span
                    className={`dashboard-menu__link ${returnLinkActiveClass(
                        "user-invoices"
                    )}`}
                >
                  <InvoicingIcon classes="dashboard-menu__link-icon dashboard-menu__link-icon--height" />
                  Your Invoices
                </span>
                </Link>
              </li>
          ) : null}

          {role === "superAdmin" ? (
              <li>
                <Link
                    onClick={handleClickMenuLink}
                    to="/dashboard/invoicing"
                >
                <span
                    className={`dashboard-menu__link ${returnLinkActiveClass(
                        "invoicing"
                    )}`}
                >
                  <InvoicingIcon classes="dashboard-menu__link-icon dashboard-menu__link-icon--height" />
                  Invoicing
                </span>
                </Link>
              </li>
          ) : null}
          {role === "superAdmin" ? (
            <li>
              <Link
                onClick={handleClickMenuLink}
                to="/dashboard/activity-and-notifications"
              >
                <span
                  className={`dashboard-menu__link ${returnLinkActiveClass(
                    "activity-and-notifications"
                  )}`}
                >
                  <ActivityNotificationsIcon classes="dashboard-menu__link-icon dashboard-menu__link-icon--height" />
                  Activity and Notifications
                </span>
              </Link>
            </li>
          ) : null}
          <li>
            <Link onClick={handleClickMenuLink} to="/dashboard/settings">
              <span
                className={`dashboard-menu__link ${returnLinkActiveClass(
                  "settings"
                )}`}
              >
                <SettingsIcon />
                Settings
              </span>
            </Link>
          </li>
          <li tabIndex={0} onClick={() => logoutUser(id)}>
            <Link>
              <span className="dashboard-menu__link dashboard-menu__link--logout">
                <LogOutIcon />
                Log Out
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

DashboardMenu.propTypes = {
  route: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
};

const mapStateToProps = ({ dashMenu: { isOpen } }) => ({
  isOpen
});

const mapDispatchToProps = dispatch => ({
  toggleDashMenu: () => dispatch(toggleDashMenuOpen()),
  logoutUser: userId => dispatch(logoutUser(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMenu);
