import { RegistrationTypes } from './registration.types';
import { ClearErrorsTypes } from '../ClearErrors/clear-errors.types';

const INITIAL_STATE = {
  isPending: false,
  errors: '',
  didSucceed: false,
};

const registrationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RegistrationTypes.REGISTER_USER_PENDING:
      return { ...state, isPending: true, errors: '' };
    case RegistrationTypes.REGISTER_USER_SUCCESS:
      return { ...state, isPending: false, errors: '', didSucceed: true };
    case RegistrationTypes.REGISTER_USER_FAILED:
      return { ...state, isPending: false, errors: action.payload };
    case ClearErrorsTypes.CLEAR_ERRORS:
      return { ...state, errors: '' };
    default:
      return state;
  }
};

export default registrationReducer;
