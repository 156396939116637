import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { connect } from 'react-redux';
import './TradeFreqChart.scss';

const TradeFreqChart = ({ tradeFrequencyChartData, data }) => {
  return (
    <div className="trade-freq-chart">
      {tradeFrequencyChartData ? (
        <ResponsiveBar
          data={tradeFrequencyChartData}
          keys={['Trades']}
          indexBy="Time"
          margin={{ top: 50, right: 10, bottom: 50, left: 30 }}
          padding={0.3}
          colors={{ scheme: 'paired' }}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          enableLabel={false}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 90,
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            tickValues: 7,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-left',
              direction: 'column',
              justify: false,
              translateX: 0,
              translateY: -40,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 10,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      ) : (
        <h2>NO TRADES FOR THIS DATE RANGE</h2>
      )}
    </div>
  );
};

const mapStateToProps = ({
  dashHome: {
    statistics: { tradeFrequencyChartData },
  },
}) => ({ tradeFrequencyChartData });

export default connect(mapStateToProps)(TradeFreqChart);
