import WithdrawalsAndDepositsFilters from "../../../components/WithdrawalsAndDepositsFilters";
import WithdrawalsAndDepositsTableDisplay from "./index";
import React, {useEffect, useState} from "react";

const UserFilterManager = () => {

    const [selectedUser, setSelectedUser] = useState();

    return (
        <React.Fragment>
            <WithdrawalsAndDepositsFilters updateSelectedUserForManager={setSelectedUser}/>
            <WithdrawalsAndDepositsTableDisplay
                headerTitleArray={['type', 'date', 'amount (btc)', 'amount (usdt)', 'assigned']}
                removeHeaderAtSmallArray={['date', 'assigned']}
                selectedUser={selectedUser}
            />
        </React.Fragment>
    );
};

export default UserFilterManager;
