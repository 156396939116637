import React, { useState } from "react";
import { connect } from "react-redux";

import RiskForm from "./RiskForm";
import TradeProfileComplete from "./TradeProfileComplete";
import "./TradeProfile.scss";

const TradeProfile = ({ tradeProfileComplete }) => {
  const [editRisk, setEditRisk] = useState(false);

  return (
    <section className="trade-profile">
      {tradeProfileComplete && !editRisk ? (
        <TradeProfileComplete setEditRisk={setEditRisk} />
      ) : (
        <RiskForm setEditRisk={setEditRisk} />
      )}
    </section>
  );
};

const mapStateToProps = ({ settings: { settings } }) => ({
  settings
});

export default connect(mapStateToProps)(TradeProfile);
