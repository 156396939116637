import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {closeAssignModal} from "../../../redux/AssignTransactionToInvoiceModal/assign-modal.actions";
import "./AssignModal.scss";
import {ReactComponent as CloseIcon} from "../../../static/svg/close-icon.svg";
import {convertDateTimeToCurrentTimezone} from "../../../format";
import Button from "../../Button";
import {getInvoiceTableData, handleInvoicesQueryError} from "../../../redux/Invoices/invoices.actions";
import {getRequest, putRequest, Urls} from "../../../api";
import SelectInput from "../../SelectInput";
import {handleErrorStrict} from "../../../redux/utils.redux";
import {
    filterWithdrawalsAndDeposits,
    forceNewWithdrawalsDepositsGet
} from "../../../redux/WithdrawalsAndDeposits/withdrawals-and-deposits.actions";

const DataGroup = ({label, data, labelColorClass, className}) => (
    <div className={"assign-invoice-data-group" + (className ? " " + className : "")}>
        <span className={`assign-invoice-data-group__label ${labelColorClass}`}>{label}</span>
        <span className="assign-invoice-data-group__data">{data}</span>
    </div>
);

const AssignInvoiceModal = ({role, isOpen, transactionAndUserData, closeAssignModal, assignTransactionToInvoice, handleInvoicesQueryError, handleErrorStrict, getInvoiceTableData, forceNewWithdrawalsDepositsGet, invoicesPage}) => {
    const {transaction, user} = transactionAndUserData;
    const [userInvoices, setUserInvoices] = useState();
    const [selectedInvoice, setSelectedInvoice] = useState();

    useEffect(() => {
        getRequest(Urls.server, `/invoices/get-list?pendingOnly=true&user_id=${user.id}`)
            .then(data => {
                setUserInvoices(data.invoices);
            })
            .catch(error => {
                handleInvoicesQueryError(error)
            });
    }, []);

    useEffect(() => {
        const handleClickOnBackground = (e) => {
            const {classList} = e.target;

            if (classList.contains("dashboard") && isOpen) closeAssignModal();
        };

        window.addEventListener("click", handleClickOnBackground);

        return () => {
            window.removeEventListener("click", handleClickOnBackground);
        };
    }, [isOpen, closeAssignModal]);

    function getInvoicesDropDownList() {
        if (!userInvoices) {
            return [];
        }

        return userInvoices.map((invoice) => invoice.id);
    }

    function handleOnInvoiceChange(e) {
        const _selectedInvoice = userInvoices.map((invoice) => invoice.id).indexOf(e.target.value);
        setSelectedInvoice(userInvoices[_selectedInvoice]);
    }

    function getInvoiceDisplayText(selectedInvoice) {
        if (!selectedInvoice || selectedInvoice.id < 0) {
            return "";
        }
        const selectedInvoiceIndex = userInvoices.map((user) => user.id).indexOf(selectedInvoice.id);
        return userInvoices[selectedInvoiceIndex].id;
    }

    function onSubmit(e) {
        if (e) {
            e.preventDefault()
        }

        putRequest(Urls.server, "/invoices/pair", {invoice_id: selectedInvoice.id, withdraw_id: transaction.binanceID})
            .then(data => {
                // close modal
                closeAssignModal();
                // redux dispatch that makes the withdrawals/deposits filter fetch new data
                forceNewWithdrawalsDepositsGet();
                // make the invoice table get new data
                getInvoiceTableData(invoicesPage);
            })
            .catch(error => {
                handleErrorStrict("ASSIGN_TRANSACTION_TO_INVOICE", error);
            });
    }

    return (
        <div className="assign-modal">
            <CloseIcon
                aria-label="close"
                onClick={closeAssignModal}
                className="assign-modal__close-btn"
            />
            <div className="assign-modal__content">
                <h1 className="assign-modal__header-info">Transaction Information</h1>
                <div className="assign-modal__row">
                    <DataGroup
                        label="transaction type"
                        data={transaction.isDeposit ? "deposit" : "withdrawal"}
                        labelColorClass="data-group__label--blue"
                    />

                    <DataGroup
                        label="date"
                        data={transaction.date ? convertDateTimeToCurrentTimezone({
                            dateTime: transaction.date,
                            format: "h:mm a - M/D/YYYY"
                        }) : "N/A"}
                        labelColorClass="data-group__label--blue"
                    />
                    <DataGroup
                        label="binance id"
                        data={transaction.binanceID ? transaction.binanceID : "N/A"}
                        labelColorClass="data-group__label--blue"
                        className="word-break-all-helper"
                    />
                </div>
                <div className="assign-modal__row">
                    <DataGroup
                        label="amount (BTC)"
                        data={transaction.amountInBTC}
                        labelColorClass="data-group__label--blue"
                    />
                    <DataGroup
                        label="amount (USDT)"
                        data={transaction.amountInUSDT}
                        labelColorClass="data-group__label--blue"
                    />
                    <DataGroup
                        label="assigned"
                        data={transaction.isDeposit ? "N/A" : (transaction.isPaired ? "YES" : "NO")}
                        labelColorClass="data-group__label--blue"
                    />
                </div>

                <h1 className="assign-modal__header-info">User Information</h1>
                <div className="assign-modal__row">
                    <DataGroup
                        label="username"
                        data={user.username}
                        labelColorClass="data-group__label--green"
                    />
                    <DataGroup
                        label="id"
                        data={user.id}
                        labelColorClass="data-group__label--green"
                    />

                    <DataGroup
                        label="trade risk"
                        data={user.trade_risk}
                        labelColorClass="data-group__label--green"
                    />
                </div>

                {(!transaction.isDeposit && !transaction.isPaired) && (
                    <>
                        <h1 className="assign-modal__header-info">Assign to Invoice</h1>
                        <p className="assign-modal__sub-header">(Dropdown only shows 'pending' invoices assigned to the selected user)</p>
                        {userInvoices ? (
                            <>
                                <div className="assign-modal__row">
                                    <div className="assign-invoices-form-style-helper">
                                        <SelectInput
                                            id="invoicing-invoice-select"
                                            label="invoice"
                                            fullWidth={true}
                                            name="invoice"
                                            variant="outlined"
                                            onChange={handleOnInvoiceChange}
                                            menuItemArray={getInvoicesDropDownList()}
                                            value={getInvoiceDisplayText(selectedInvoice)}
                                            condensed={true}
                                            classNames="text-input user-drop-down"
                                            style={{minWidth: 300}}
                                            outlinedInputClassName="test"
                                        />
                                    </div>
                                </div>

                                {selectedInvoice && (
                                    <>
                                        <div className="assign-modal__row">
                                            <DataGroup
                                                label="created"
                                                data={selectedInvoice.created_at ? convertDateTimeToCurrentTimezone({
                                                    dateTime: selectedInvoice.created_at,
                                                    format: "h:mm a - M/D/YYYY"
                                                }) : "N/A"}
                                                labelColorClass="data-group__label--yellow"
                                            />
                                            <DataGroup
                                                label="amount"
                                                data={selectedInvoice.amount}
                                                labelColorClass="data-group__label--yellow"
                                            />
                                            <DataGroup
                                                label="withdraw id"
                                                data={selectedInvoice.withdraw_id ? selectedInvoice.withdraw_id : "N/A"}
                                                labelColorClass="data-group__label--yellow"
                                            />
                                        </div>
                                        <div className="assign-modal__row">
                                            <DataGroup
                                                label="reason"
                                                data={selectedInvoice.reason ? selectedInvoice.reason : "N/A"}
                                                labelColorClass="data-group__label--yellow"
                                            />
                                        </div>

                                        {((role === "superAdmin") && (!transaction.isDeposit && !transaction.isPaired)) ? (
                                            <div className="assign-modal__cancel-signal">
                                                <form onSubmit={onSubmit}>
                                                    <Button type="solid" header={false} element="input" value={`Assign to invoice #${selectedInvoice.id}`}/>
                                                </form>
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </>
                        ) : (
                            <p className="assign-invoices-form-style-helper" style={{textAlign: "center", color: "white", fontSize: "1.5rem", padding: "3vh 3vw"}}>
                                Loading Invoices...
                            </p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    closeAssignModal: () => dispatch(closeAssignModal()),
    handleInvoicesQueryError: error => dispatch(handleInvoicesQueryError(error, dispatch)),
    handleErrorStrict: (type, error) => dispatch(handleErrorStrict(type, error, dispatch)),
    forceNewWithdrawalsDepositsGet: () => dispatch(forceNewWithdrawalsDepositsGet()),
    filterTransactions: formData => dispatch(filterWithdrawalsAndDeposits(formData)),
    getInvoiceTableData: (page) => dispatch(getInvoiceTableData({page, pendingOnly: false}))
});

const mapStateToProps = ({assignInvoiceModal: {isOpen, transactionAndUserData}, user: {data: {role}}, invoices: {page}}) => ({
    isOpen,
    transactionAndUserData,
    role,
    invoicesPage: page
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignInvoiceModal);
