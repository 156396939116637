import { MessageModalTypes } from './message-modal.types';
import { SnackbarTypes } from '../Snackbar/snackbar.types';
import { Urls, postRequest } from '../../api';
import { handleDataWithSnackbar, handleValidationError } from '../utils.redux';

export const openMessageModal = userData => dispatch =>
  dispatch({
    type: MessageModalTypes.OPEN_MESSAGE_MODAL,
    payload: userData,
  });

export const closeMessageModal = () => dispatch =>
  dispatch({ type: MessageModalTypes.CLOSE_MESSAGE_MODAL });

export const sendMessage = formData => dispatch => {
  dispatch({ type: MessageModalTypes.SEND_MESSAGE_PENDING });

  postRequest(Urls.server, '/user/send-message', formData)
    .then(data =>
      handleDataWithSnackbar({
        data,
        dispatch,
        type: MessageModalTypes.SEND_MESSAGE_SUCCESS,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      }),
    )
    .catch(error =>
      handleValidationError({
        error,
        dispatch,
        type: MessageModalTypes.SEND_MESSAGE_FAILED,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      }),
    );
};
