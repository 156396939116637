import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import SignalerRegistrationForm from "./Forms/signalerRegistrationForm";
import RegistrationForm from "./Forms/registrationForm";
import LoginForm from "./Forms/loginForm";
import RecoverPasswordForm from "./Forms/recoverPasswordForm";
import RecoverUsernameForm from "./Forms/recoverUsernameForm";
import NewPasswordForm from "./Forms/newPasswordForm";
import { resetRecoverForm } from "../../redux/Recovery/recovery.actions";

import "./Accounts.scss";

const Accounts = ({ match, resetRecoverForm }) => {
  const { form, recoveryCode, registrationCode } = match.params;

  const renderForm = form => {
    resetRecoverForm();
    if (form === "register" || registrationCode)
      return <RegistrationForm registrationCode={registrationCode} />;
    if (form === "login") return <LoginForm />;
    if (form === "recover-password") return <RecoverPasswordForm />;
    if (form === "recover-username") return <RecoverUsernameForm />;
    if (recoveryCode) return <NewPasswordForm recoveryCode={recoveryCode} />;
  };
  return <main className="accounts">{renderForm(form)}</main>;
};

const mapDispatchToProps = dispatch => ({
  resetRecoverForm: () => dispatch(resetRecoverForm())
});

export default connect(null, mapDispatchToProps)(withRouter(Accounts));
