export const DashActivityTypes = {
  GET_REGISTRATION_DATA_PENDING: 'GET_REGISTRATION_DATA_PENDING',
  GET_REGISTRATION_DATA_SUCCESS: 'GET_REGISTRATION_DATA_SUCCESS',
  GET_REGISTRATION_DATA_FAILED: 'GET_REGISTRATION_DATA_FAILED',
  GET_USER_ACTIVITY_DATA_PENDING: 'GET_USER_ACTIVITY_DATA_PENDING',
  GET_USER_ACTIVITY_DATA_SUCCESS: 'GET_USER_ACTIVITY_DATA_SUCCESS',
  GET_USER_ACTIVITY_DATA_FAILED: 'GET_USER_ACTIVITY_DATA_FAILED',
  TOGGLE_LOCK_ACCOUNT_PENDING: 'TOGGLE_LOCK_ACCOUNT_PENDING',
  TOGGLE_LOCK_ACCOUNT_SUCCESS: 'TOGGLE_LOCK_ACCOUNT_SUCCESS',
  TOGGLE_LOCK_ACCOUNT_FAILED: 'TOGGLE_LOCK_ACCOUNT_FAILED',
  SEND_SIGNALER_REGISTRATION_LINK_PENDING:
    'SEND_SIGNALER_REGISTRATION_LINK_PENDING',
  SEND_SIGNALER_REGISTRATION_LINK_SUCCESS:
    'SEND_SIGNALER_REGISTRATION_LINK_SUCCESS',
  SEND_SIGNALER_REGISTRATION_LINK_FAILED:
    'SEND_SIGNALER_REGISTRATION_LINK_FAILED',
};
