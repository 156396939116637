export const UserTypes = {
  LOAD_USER_PENDING: 'LOAD_USER_PENDING',
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
  LOAD_USER_FAILED: 'LOAD_USER_FAILED',
  LOGOUT_USER_PENDING: 'LOGOUT_USER_PENDING',
  LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS',
  LOGOUT_USER_FAILED: 'LOGOUT_USER_FAILED',
  UPDATE_SHOW_GREETING_PENDING: 'UPDATE_SHOW_GREETING_PENDING',
  UPDATE_SHOW_GREETING_SUCCESS: 'UPDATE_SHOW_GREETING_SUCCESS',
  UPDATE_SHOW_GREETING_FAILED: 'UPDATE_SHOW_GREETING_FAILED',
};
