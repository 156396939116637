import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Avatar, FormControlLabel, Checkbox } from "@material-ui/core";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from "@material-ui/icons";
import Button from "../../Button";
import TextInput from "../../TextInput";
import {
  closeMessageModal,
  sendMessage
} from "../../../redux/MessageModal/message-modal.actions";

import "./MessageModal.scss";

const initialState = {
  email: false,
  discord: false,
  telegram: false,
  message: ""
};

const MessageModal = ({
  closeMessageModal,
  isOpen,
  notificationPreferences,
  username,
  accountStatus,
  sendMessage,
  role
}) => {
  const [messageDetails, setMessageDetails] = useState(initialState);

  const handleFormSubmit = e => {
    e.preventDefault();
    const { message, email, discord, telegram } = messageDetails;
    if (!messageDetails.message) return;
    sendMessage({
      username,
      message,
      email,
      discord,
      telegram
    });
    setMessageDetails(initialState);
    closeMessageModal();
  };

  const handleTextInputChange = e => {
    const { value, name } = e.target;
    setMessageDetails(prevState => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxInputChange = e => {
    const { name } = e.target;
    setMessageDetails(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  const loggedInClass = accountStatus.is_logged_in ? "logged-in" : "";

  useEffect(() => {
    const handleClickOnBackground = e => {
      const { classList } = e.target;

      if (classList.contains("dashboard") && isOpen) {
        closeMessageModal();
      }
    };

    window.addEventListener("click", handleClickOnBackground);

    return () => {
      window.removeEventListener("click", handleClickOnBackground);
    };
  }, [isOpen, closeMessageModal]);

  return (
    <div className="message-modal">
      <div className="message-modal__avatar-group">
        <div className={`message-modal__avatar-status ${loggedInClass}`} />
        <span className="message-modal__username">{username}</span>
      </div>
      <form
        onSubmit={handleFormSubmit}
        className="message-modal__form custom-mui-form"
      >
        <TextInput
          id="notify-user-message-input"
          type="text"
          variant="outlined"
          label="message"
          fullWidth={true}
          name="message"
          multiline={true}
          onChange={handleTextInputChange}
          value={messageDetails.message}
        />
        <div className="message-modal__checkbox-group">
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                value={messageDetails.email}
                name="email"
                onChange={handleCheckboxInputChange}
                disabled={!notificationPreferences.email.enabled}
              />
            }
            label="Email"
          />
          {role === "user" ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    value={messageDetails.discord}
                    name="discord"
                    onChange={handleCheckboxInputChange}
                    disabled={!notificationPreferences.discord.enabled}
                  />
                }
                label="Discord"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    value={messageDetails.telegram}
                    name="telegram"
                    onChange={handleCheckboxInputChange}
                    disabled={!notificationPreferences.telegram.enabled}
                  />
                }
                label="Telegram"
              />{" "}
            </>
          ) : null}
        </div>
        <Button type="solid" header={false} element="input" value="send" />
      </form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  closeMessageModal: () => dispatch(closeMessageModal()),
  sendMessage: formData => dispatch(sendMessage(formData))
});

const mapStateToProps = ({
  messageModal: {
    notificationPreferences,
    username,
    accountStatus,
    role,
    messageIsPending,
    isOpen
  }
}) => ({
  notificationPreferences,
  username,
  accountStatus,
  role,
  messageIsPending,
  isOpen
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
