import React from 'react';

import { ReactComponent as TextLogo } from '../../static/svg/text-logo.svg';
import BoxSlideLoader from '../BoxSlideLoader';
import CircularLoader from '../CircularLoader';
import './DashboardLoading.scss';

const DashboardLoading = () => (
  <div className="dashboard-loading">
    {/* <CircularLoader size={100} /> */}
    <BoxSlideLoader />
    <TextLogo className="dashboard-loading__logo" />
  </div>
);

export default DashboardLoading;
