import { ReactComponent as EasyToUseIcon } from '../../../static/svg/easy-to-use-icon.svg';
import { ReactComponent as NoSubscriptionIcon } from '../../../static/svg/no-sub-icon.svg';
import { ReactComponent as NotificationsIcon } from '../../../static/svg/notifications-letter-icon.svg';
import { ReactComponent as SecureSignalsIcon } from '../../../static/svg/secure-signals-icon.svg';
import { ReactComponent as StatsIcon } from '../../../static/svg/stats-icon.svg';

const featureArray = [
  {
    title: 'Easy To Use',
    description:
      'In just 5 minutes, you can get registered, connect your Binance account, set up your trading profile, and start earning through trades.',
    svg: EasyToUseIcon,
  },
  {
    title: 'No Subscriptions',
    description:
      'Our platform is free to use until you make money. We don’t see returns until you make a profit. There are no subscriptions or monthly payments.',
    svg: NoSubscriptionIcon,
  },
  {
    title: 'Up To Date Statistics',
    description:
      'Log in to our web application to see how many trades we’ve made for you and your profit margin. Sort through the data and visualize how your account is earning.',
    svg: StatsIcon,
  },
  {
    title: 'Personalized Notifications',
    description:
      'We will send you notifications on your account via your preferred method (email, discord, telegram). We also have a discord server where you can chat with a ninja live.',
    svg: NotificationsIcon,
  },
  {
    title: 'Secure Signals',
    description:
      'Your account credentials are of utmost importance to us. We’ve made sure to implement safety measures in every step of the process.',
    svg: SecureSignalsIcon,
  },
];

export default featureArray;
