import React from "react";
import { connect } from "react-redux";

import {
  removeNegativeFromZero,
  convertDateTimeToCurrentTimezone
} from "../../../../format";
import { openSignalModal } from "../../../../redux/SignalModal/signal-modal.actions";
import "./SignalTableRow.scss";

const SignalTableRow = ({ data, index, openSignalModal, role }) => {
  const darkBgClass = index % 2 !== 0 ? "" : "signal-table-row--dark";
  return (
    <div
      onClick={() => openSignalModal(data)}
      tabIndex={1}
      className={`signal-table-row ${darkBgClass}`}
    >
      <span className="signal-table-row__data signal-table-row__data--symbol">
        <span className={`data-side--${data.side}`}>{data.side}</span>
        {data.symbol}
      </span>

      {(role === "signaler" || role === "superAdmin") && (
          <span className="signal-table-row__data">
            {data.user}
          </span>
      )}

      {(role === "signaler" || role === "superAdmin") && (
          <span className="signal-table-row__data">
            {data.signaler}
          </span>
      )}

      <span className="signal-table-row__data">
        {convertDateTimeToCurrentTimezone({
          dateTime: data.timestamp,
          format: "h:mm a - M/D/YYYY"
        })}
      </span>
      <span className="signal-table-row__data">{data.status}</span>
      {/*<span className="signal-table-row__data word-break">*/}
      {/*  {removeNegativeFromZero(data.profit)}*/}
      {/*</span>*/}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  openSignalModal: signalData => dispatch(openSignalModal(signalData))
});

export default connect(null, mapDispatchToProps)(SignalTableRow);
