import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { ResponsiveLine } from '@nivo/line';

import './RegistrationChart.scss';

const RegistrationChart = ({ registrationChartData }) => {
  const [cursorIsOnLeftSideOfScreen, setCursorIsOnLeftSideOfScreen] = useState(
    false,
  );
  const [toolTipTransformValue, setToolTipTransformValue] = useState(0);

  useEffect(() => {
    const handleWindowScroll = e => {
      const chartBounds = document
        .querySelector('.registration-chart')
        .getBoundingClientRect();

      const leftSideOfChart = chartBounds.left + chartBounds.width / 2;

      if (e.screenX > leftSideOfChart && cursorIsOnLeftSideOfScreen)
        setCursorIsOnLeftSideOfScreen(false);
      else if (e.screenX <= leftSideOfChart && !cursorIsOnLeftSideOfScreen)
        setCursorIsOnLeftSideOfScreen(true);
    };
    window.addEventListener('mousemove', handleWindowScroll);

    setToolTipTransformValue(cursorIsOnLeftSideOfScreen ? 0 : -110);

    return () => {
      window.removeEventListener('mousemove', handleWindowScroll);
    };
  }, [cursorIsOnLeftSideOfScreen, setCursorIsOnLeftSideOfScreen]);
  return (
    <div className="registration-chart">
      <ResponsiveLine
        data={registrationChartData}
        margin={{ top: 45, right: 5, bottom: 50, left: 20 }}
        xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
        yScale={{ type: 'linear', stacked: false, min: 0, max: 'auto' }}
        enableSlices="x"
        enableGridX={false}
        crosshairType="x"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 15,
          tickRotation: 90,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
          format: d => moment(d).tz("America/Vancouver").format('M/D'),
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
          format: d => (d % 1 === 0 ? d : ''),
        }}
        sliceTooltip={({ slice }) => {
          return (
            <div
              style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
                transform: `translateX(${toolTipTransformValue}%)`,
                boxShadow: '1px 1px 2px rgba(0,0,0,0.2)',
              }}
            >
              {slice.points.map(point => (
                <div
                  key={point.id}
                  style={{
                    padding: '3px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      maxWidth: '150px',
                      // lineHeight: '170%',
                    }}
                  >
                    <strong>Date:</strong>{' '}
                    {moment(point.data.xFormatted).tz("America/Vancouver").format('MMMM Do, YYYY')}
                  </div>
                  <div>
                    <strong>{point.serieId}</strong> {point.data.yFormatted}
                  </div>
                </div>
              ))}
            </div>
          );
        }}
        colors={{ scheme: 'set2' }}
        enablePoints={false}
        pointColor={{ from: 'color' }}
        pointBorderWidth={2}
        pointBorderColor={{ theme: 'background' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.6}
        curve="basis"
        useMesh={true}
        // legends={[
        //   {
        //     anchor: 'top-left',
        //     direction: 'row',
        //     justify: false,
        //     translateX: 0,
        //     translateY: -30,
        //     itemsSpacing: 0,
        //     itemDirection: 'left-to-right',
        //     itemWidth: 80,
        //     itemHeight: 20,
        //     itemOpacity: 0.75,
        //     symbolSize: 12,
        //     symbolShape: 'circle',
        //     symbolBorderColor: 'rgba(0, 0, 0, .5)',
        //     effects: [
        //       {
        //         on: 'hover',
        //         style: {
        //           itemBackground: 'rgba(0, 0, 0, .03)',
        //           itemOpacity: 1,
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
    </div>
  );
};

const mapStateToProps = ({ dashActivity: { registrationChartData } }) => ({
  registrationChartData,
});

export default connect(mapStateToProps)(RegistrationChart);
