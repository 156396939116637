import { Urls, getRequest, postRequest } from '../../api';
import socket from '../../socketIO';
import { UserTypes } from './user.types';
import { SnackbarTypes } from '../Snackbar/snackbar.types';

export const loadUser = () => dispatch => {
  dispatch({ type: UserTypes.LOAD_USER_PENDING });

  getRequest(Urls.server, `/user/current`)
    .then(userData => {
      dispatch({ type: UserTypes.LOAD_USER_SUCCESS, payload: userData });
    })
    .catch(error => {
      if (error.response.data.expiredTokenError) {
        dispatch(logoutUser());
      }
      if (error.response.data.snackbar) {
        dispatch({
          type: SnackbarTypes.OPEN_SNACKBAR,
          payload: error.response.data.snackbar,
        });
        dispatch({ type: UserTypes.LOAD_USER_FAILED });
      }
    });
};

export const logoutUser = userId => dispatch => {
  dispatch({ type: UserTypes.LOGOUT_USER_PENDING });
  postRequest(Urls.server, `/logout`, { userId })
    .then(data => {
      dispatch({
        type: SnackbarTypes.OPEN_SNACKBAR,
        payload: data.snackbar,
      });
      dispatch({ type: UserTypes.LOGOUT_USER_SUCCESS });
      socket.emit('LEAVE_PRIVATE_SESSION');
      window.localStorage.removeItem('pathname');
    })
    .catch(error => {
      dispatch({
        type: SnackbarTypes.OPEN_SNACKBAR,
        payload: error.response.data.snackbar,
      });
      dispatch({ type: UserTypes.LOGOUT_USER_FAILED });
    });
};

export const updateShowGreeting = showGreeting => dispatch => {
  dispatch({ type: UserTypes.UPDATE_SHOW_GREETING_PENDING });

  postRequest(Urls.server, '/user/greeting', { showGreeting })
    .then(data =>
      dispatch({
        type: UserTypes.UPDATE_SHOW_GREETING_SUCCESS,
        payload: data.showGreeting,
      }),
    )
    .catch(() => dispatch({ type: UserTypes.UPDATE_SHOW_GREETING_FAILED }));
};
