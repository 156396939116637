import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getProfileSettings } from "../../redux/Settings/settings.actions";
import DashComponent from "../../components/Dashboard/DashComponent";
import AccountStatus from "./AccountStatus";
import BinanceForm from "./BinanceForm";
import Notifications from "./Notifications";
import BlacklistForm from "./BlacklistForm";
import PasswordForm from "./PasswordForm";
import TradeProfile from "./TradeProfile";
import DashboardLoading from "../../components/DashboardLoading";
import { ReactComponent as KeyIcon } from "../../static/svg/key-icon.svg";
import { ReactComponent as NotificationIcon } from "../../static/svg/notification-icon.svg";
import { ReactComponent as TradeProfileIcon } from "../../static/svg/trade-profile-icon.svg";
import { ReactComponent as LockIcon } from "../../static/svg/lock-icon.svg";
import { ReactComponent as CancelIcon } from "../../static/svg/cancel-icon.svg";
import "./DashSettings.scss";

const DashSettings = ({ role, getProfileSettings, settings }) => {
  useEffect(() => {
    getProfileSettings();
  }, [getProfileSettings]);
  return (
    <section className="dash-settings">
      {!settings ? <DashboardLoading /> : null}
      <AccountStatus
        tradeProfileComplete={settings && settings.tradeProfile.trade_risk}
        binanceCredentialsComplete={
          settings && settings.binanceCredentialsComplete
        }
        isActivated={settings && settings.accountStatus.activated}
        role={role}
      />
      <div className="dash-settings__row">
        <DashComponent
          svg={<KeyIcon />}
          title="binance"
          twoPerRow={true}
          role={role}
        >
          <BinanceForm
            binanceCredentialsComplete={
              settings && settings.binanceCredentialsComplete
            }
          />
        </DashComponent>
        <DashComponent
          svg={<NotificationIcon />}
          title="notifications"
          twoPerRow={true}
          role={role}
        >
          <Notifications settings={settings} />
        </DashComponent>
      </div>
      <DashComponent
        svg={<TradeProfileIcon />}
        title="trade profile"
        role={role}
      >
        <TradeProfile
          tradeProfile={settings && settings.tradeProfile}
          tradeProfileComplete={
            settings &&
            settings.tradeProfile &&
            !!settings.tradeProfile.trade_risk
          }
        />
      </DashComponent>
      <div className="dash-settings__row">
        <DashComponent
          svg={<LockIcon />}
          title="password"
          twoPerRow={true}
          role={role}
        >
          <PasswordForm />
        </DashComponent>
        <DashComponent
          svg={<CancelIcon />}
          title="coin blacklist"
          twoPerRow={true}
          role={role}
        >
          <BlacklistForm blacklistFromDB={["bnb", "doge", "xmr"]} />
        </DashComponent>
      </div>
    </section>
  );
};

const mapDispatchToProps = dispatch => ({
  getProfileSettings: () => dispatch(getProfileSettings())
});

const mapStateToProps = ({ settings }) => ({
  settings: settings.settings,
  settingsIsPending: settings.isPending
});

export default connect(mapStateToProps, mapDispatchToProps)(DashSettings);
