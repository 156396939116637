import { MessageModalTypes } from "./message-modal.types";

const INITIAL_STATE = {
  isOpen: false,
  notificationPreferences: null,
  username: "",
  role: "",
  accountStatus: null,
  messageIsPending: false
};

const messageModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MessageModalTypes.OPEN_MESSAGE_MODAL:
      const {
        notificationPreferences,
        username,
        role,
        accountStatus
      } = action.payload;
      return {
        ...state,
        isOpen: !state.isOpen,
        notificationPreferences,
        username,
        role,
        accountStatus
      };

    case MessageModalTypes.CLOSE_MESSAGE_MODAL:
      return { ...state, ...INITIAL_STATE };

    case MessageModalTypes.SEND_MESSAGE_PENDING:
      return { ...state, messageIsPending: true };

    case MessageModalTypes.SEND_MESSAGE_SUCCESS:
      return { ...state, messageIsPending: false };

    case MessageModalTypes.SEND_MESSAGE_FAILED:
      return { ...state, messageIsPending: false };
    default:
      return state;
  }
};

export default messageModalReducer;
