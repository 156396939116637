import { LoginTypes } from './login.types';
import { ClearErrorsTypes } from '../ClearErrors/clear-errors.types';

const INITIAL_STATE = { isPending: false, errors: '' };

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoginTypes.LOGIN_USER_PENDING:
      return { ...state, isPending: true, errors: '' };

    case LoginTypes.LOGIN_USER_SUCCESS:
      return { ...state, isPending: false };

    case LoginTypes.LOGIN_USER_FAILED:
      return { ...state, isPending: false, errors: action.payload };

    case ClearErrorsTypes.CLEAR_ERRORS:
      return { ...state, errors: '' };

    default:
      return state;
  }
};

export default loginReducer;
