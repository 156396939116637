import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MenuIcon from './MenuIcon';
import Button from '../../components/Button';
import { ReactComponent as TextLogo } from '../../static/svg/text-logo.svg';
import { toggleDashMenuOpen } from '../../redux/DashMenu/dash-menu.actions';
import { logoutUser } from '../../redux/User/user.actions';

import './Header.scss';

const Header = ({
  location,
  isMenuOpen,
  handleMenuToggle,
  setIsMenuOpen,
  toggleDashMenuOpen,
  isDashMenuOpen,
  userData,
  logoutUser,
  appHasError,
}) => {
  // State
  const [isTopOfPage, setIsTopOfPage] = useState(true);

  // Methods
  const handleEnterTopOfPage = () => setIsTopOfPage(true);
  const handleLeaveTopOfPage = () => setIsTopOfPage(false);

  const handleNavLinkClick = () => {
    if (isMenuOpen) handleMenuToggle();
  };

  const handleLogoClick = () => {
    if (isMenuOpen) handleMenuToggle();
  };

  const getPathsObject = pathname => {
    let [, firstPath, secondPath = ''] = pathname.split('/');
    if (!secondPath) secondPath = null;

    return { firstPath, secondPath };
  };

  const pathsObject = getPathsObject(location.pathname);

  // Class variables
  const headerFixedClass = !isTopOfPage ? 'header-fixed' : '';
  const menuOpenClass = isMenuOpen ? 'menu-open' : '';
  const headerBgColor =
    (pathsObject.firstPath === '/' ||
      pathsObject.firstPath === '' ||
      (pathsObject.firstPath === 'accounts' &&
        pathsObject.secondPath !== null) ||
      pathsObject.firstPath === 'guide' ||
      pathsObject.firstPath === 'about-us' ||
      pathsObject.firstPath === 'contact-us') &&
    !appHasError
      ? ''
      : 'header--black';

  useEffect(() => {
    const handleBodyOverflowOnResize = e => {
      if (e.currentTarget.innerWidth >= 900) setIsMenuOpen(false);
    };

    const handleScroll = e => {
      const verticalScrollPos = e.currentTarget.scrollY;
      if (verticalScrollPos > 0) handleLeaveTopOfPage();
      else handleEnterTopOfPage();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleBodyOverflowOnResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleBodyOverflowOnResize);
    };
  }, [setIsMenuOpen]);

  const currentRoute = location.pathname;
  const getFirstRouteName = location.pathname.split('/')[1];

  const renderLogo = () => {
    if (userData)
      return (
        <HashLink onClick={handleLogoClick} to="/dashboard/home">
          <TextLogo className="header__logo" />
        </HashLink>
      );
    else if (currentRoute === '/')
      return (
        <HashLink onClick={handleLogoClick} smooth to="/#top">
          <TextLogo className="header__logo" />
        </HashLink>
      );
    else
      return (
        <HashLink onClick={handleLogoClick} to="/#top">
          <TextLogo className="header__logo" />
        </HashLink>
      );
  };
  document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
  document.documentElement.style.overflowY = isMenuOpen ? 'hidden' : 'auto';

  return (
    <header className={`header ${headerBgColor}  ${headerFixedClass}`}>
      {renderLogo()}

      <nav className="header__nav">
        <ul className={`${menuOpenClass}`}>
          <div className={`header__nav-links-left ${menuOpenClass}`}>
            <li onClick={handleNavLinkClick}>
              <Link rel="prerender" to="/about-us">
                About Us
              </Link>
            </li>
            <li onClick={handleNavLinkClick}>
              <Link rel="prerender" to="/guide">
                Guide
              </Link>
            </li>
            <li onClick={handleNavLinkClick}>
              <Link rel="prerender" to="/contact-us">
                Contact
              </Link>
            </li>
            <li className="header__menu-auth-link" onClick={handleNavLinkClick}>
              <Link rel="prerender" to="/accounts/login">
                login
              </Link>
            </li>
            <li className="header__menu-auth-link" onClick={handleNavLinkClick}>
              <Link rel="prerender" to="/accounts/register">
                register
              </Link>
            </li>
          </div>
          <div className={`header__nav-links-right ${menuOpenClass}`}>
            {userData ? (
              <>
                <li onClick={handleNavLinkClick}>
                  <Button
                    header={true}
                    type="outline"
                    element="link"
                    href="/dashboard/home"
                  >
                    Home
                  </Button>
                </li>
                <li onClick={() => logoutUser(userData.id)}>
                  <Button header={true} type="solid" element="link">
                    Logout
                  </Button>
                </li>
              </>
            ) : (
              <>
                <li onClick={handleNavLinkClick}>
                  <Button
                    header={true}
                    type="outline"
                    element="link"
                    href="/accounts/login"
                  >
                    Login
                  </Button>
                </li>
                <li onClick={handleNavLinkClick}>
                  <Button
                    header={true}
                    type="solid"
                    element="link"
                    href="/accounts/register"
                  >
                    Register
                  </Button>
                </li>
              </>
            )}
          </div>
        </ul>
      </nav>
      {location.pathname.split('/')[1].toLowerCase() === 'dashboard' ? (
        <MenuIcon
          type="dashboard"
          handleMenuToggle={toggleDashMenuOpen}
          isMenuOpen={isDashMenuOpen}
        />
      ) : (
        <MenuIcon
          type="public"
          handleMenuToggle={handleMenuToggle}
          isMenuOpen={isMenuOpen}
        />
      )}
    </header>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({ dashMenu: { isOpen }, user, appError }) => ({
  isDashMenuOpen: isOpen,
  userData: user.data,
  appHasError: appError.hasError,
});

const mapDispatchToProps = dispatch => ({
  toggleDashMenuOpen: () => dispatch(toggleDashMenuOpen()),
  logoutUser: userId => dispatch(logoutUser(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Header));
