import React from "react";
import {convertDateTimeToCurrentTimezone} from "../../../../format";
import "./UserInvoiceTableRow.scss";
import { connect } from "react-redux";
import {openUserInvoiceModal} from "../../../../redux/InvoiceModal/invoice-modal.actions";

const UserInvoicesTableTableRow = ({data, index, openUserInvoiceModal}) => {
    const darkBgClass = index % 2 !== 0 ? "" : "invoice-table-row--dark";

    function openModal() {
        openUserInvoiceModal(data);
    }

    return (
        <div
            onClick={openModal}
            tabIndex={1}
            className={`user-invoice-table-row ${darkBgClass}`}
        >
            <span className="invoice-table-row__data">
                {data.id}
            </span>
            <span className="invoice-table-row__data remove-at-sm">
              {convertDateTimeToCurrentTimezone({
                  dateTime: data.created_at,
                  // format: "h:mm a - M/D/YYYY"
                  format: "M/D/YYYY"
              })}
            </span>
            <span className="invoice-table-row__data">
              {data.amount}
            </span>
            <span className="invoice-table-row__data remove-at-sm text-concat-cell">
              {data.withdraw_id ? data.withdraw_id : "N/A"}
            </span>
            <span className="invoice-table-row__data">
              {data.paid_externally ? "Paid Externally" : (data.withdraw_id ? "Paid" : "Pending")}
            </span>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    openUserInvoiceModal: invoiceData => dispatch(openUserInvoiceModal(invoiceData)),
});

export default connect(null, mapDispatchToProps)(UserInvoicesTableTableRow);
