import {AllUsersTypes} from "./all-user-types.types";
import { Urls, getRequest } from '../../api';
import {handleError} from "../utils.redux";

export const getAllUserTypes = () => dispatch => {
    dispatch({type: AllUsersTypes.GET_ALL_USERS_PENDING});

    getRequest(Urls.server, '/users/get-all-user-type')
        .then(data =>
            dispatch({type: AllUsersTypes.GET_ALL_USERS_SUCCESS, payload: data})
        )
        .catch(error =>
            handleError({
                error,
                dispatch,
                type: AllUsersTypes.GET_ALL_USERS_FAILED
            })
        )
};
