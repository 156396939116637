import moment from "moment-timezone";

const defaultTimeZone = moment.tz.guess();

/**
 * This util will convert your date to a new time zone. The actual date value will change but the times are equivalent.
 * If the input is 3pm then the output will be the ms time for 3pm of the output timezone
 * @param d
 * @param timezone
 */
export function convertTimeZonePreserveDate(d, timezone = "America/Vancouver") {
    if (d === "") {
        return "";
    }

    if (!d) {
        d = new Date()
    }
    const value = d instanceof Date ? d.getTime() : d
    const destinationTimezone = (timezone && timezone.length > 0) ? moment().tz(timezone).utcOffset() : 0;
    const localTimezone = moment().tz(defaultTimeZone).utcOffset();
    const adjustValue = (destinationTimezone - localTimezone) * 60000;
    return value - adjustValue;
}