import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { submitTradeProfile } from "../../../../redux/Settings/settings.actions";
import SelectInput from "../../../../components/SelectInput";
import Button from "../../../../components/Button";
import CircleLoader from "../../../../components/CircleLoader";
import "./RiskForm.scss";

const RiskForm = ({
  setEditRisk,
  settings,
  submitTradeProfile,
  submitSuccess,
  submitTradeProfilePending
}) => {
  const [inputFields, setInputFields] = useState({
    riskPerTrade: ""
  });

  useEffect(() => {
    setInputFields({
      riskPerTrade:
        settings && settings.tradeProfile && settings.tradeProfile.trade_risk
    });
  }, [settings]);

  const handleChange = e => setInputFields({ [e.target.name]: e.target.value });

  const handleSubmit = e => {
    e.preventDefault();
    submitTradeProfile({ riskPerTrade: inputFields.riskPerTrade });
    if (submitSuccess) setEditRisk(false);
  };

  return (
    <form onSubmit={handleSubmit} className="risk-form custom-mui-form">
      <p className="body-text">
        By telling us how much risk you're willing to incur on any given trade,
        we can make orders on your behalf accordingly. If you want to learn more
        about how your trade profile will affect your portfolio, we address it
        in our <Link to="/guide#set-up-your-trading-profile">GUIDE</Link> or
        click on the chat button on the bottom right of your screen to ask us
        directly.
      </p>
      <div className="risk-form__inputs">
        <SelectInput
          onChange={handleChange}
          menuItemArray={["low", "medium", "high"]}
          id="trade-profile-risk-per-trade-input"
          value={inputFields.riskPerTrade}
          name="riskPerTrade"
          label="risk per trade"
          variant="outlined"
        />
        <Button
          type="solid"
          header={false}
          element="button"
          actionType="submit"
          disabled={submitTradeProfilePending}
        >
          {submitTradeProfilePending ? <CircleLoader /> : "submit"}
        </Button>
      </div>
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  submitTradeProfile: formData => dispatch(submitTradeProfile(formData))
});

const mapStateToProps = ({
  settings: { settings, tradeProfileSubmitSuccess, submitTradeProfilePending }
}) => ({
  settings,
  submitSuccess: tradeProfileSubmitSuccess,
  submitTradeProfilePending
});

export default connect(mapStateToProps, mapDispatchToProps)(RiskForm);
