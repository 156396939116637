import {WithdrawalsAndDepositsTypes} from "./withdrawals-and-deposits.types";

const INITIAL_STATE = {
    isPending: false,
    transactions: [],
    page: null,
    totalPages: null,
    lowerDisplay: null,
    upperDisplay: null,
    totalWithdrawalsAndDeposits: null,
    currentFilter: {
        user_id: "",
        startTime: null,
        endTime: null
    },
    forceKey: 1,
};

const withdrawalsAndDepositsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WithdrawalsAndDepositsTypes.WAD_FILTER_QUERY_PENDING:
            return {...state, isPending: true};

        case WithdrawalsAndDepositsTypes.WAD_FILTER_QUERY_SUCCESS:
            const {withdrawalsAndDeposits, pagination, currentFilter} = action.payload;
            return {
                ...state,
                isPending: false,
                transactions: withdrawalsAndDeposits,
                page: pagination ? Number(pagination.page) : 0,
                totalPages: pagination ? Number(pagination.totalPages) : 0,
                currentFilter: {
                    user_id: currentFilter.user_id,
                    startTime: currentFilter.startTime,
                    endTime: currentFilter.endTime
                },
                lowerDisplay: pagination ? pagination.lowerDisplay : 0,
                upperDisplay: pagination ? pagination.upperDisplay : 0,
                totalWithdrawalsAndDeposits: pagination ? pagination.totalWithdrawalsAndDeposits : 0,
            };

        case WithdrawalsAndDepositsTypes.WAD_FILTER_QUERY_FAILED:
            return { ...state, isPending: false };

        case WithdrawalsAndDepositsTypes.WAD_FILTER_QUERY_FORCE_NEW:
            return {...state, forceKey: state.forceKey + 1};

        case WithdrawalsAndDepositsTypes.WAD_CLEAR:
            return {...INITIAL_STATE};

        default:
            return state;
    }
};

export default withdrawalsAndDepositsReducer;
