import { RecoveryTypes } from './recovery.types';
import { ClearErrorsTypes } from '../ClearErrors/clear-errors.types';

const INITIAL_STATE = {
  isPending: false,
  didSucceed: false,
  errors: {},
};

const recoveryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RecoveryTypes.RECOVER_FORM_PENDING:
      return { ...state, isPending: true, errors: {} };

    case RecoveryTypes.RECOVER_FORM_SUCCESS:
      return {
        ...state,
        isPending: false,
        didSucceed: true,
        errors: {},
      };

    case RecoveryTypes.RECOVER_FORM_FAILED:
      return {
        ...state,
        isPending: false,
        didSucceed: false,
        errors: action.payload,
      };

    case ClearErrorsTypes.CLEAR_ERRORS:
      return { ...state, errors: '' };

    case RecoveryTypes.RECOVER_FORM_RESET:
      return { ...state, errors: '', didSucceed: false };

    default:
      return state;
  }
};

export default recoveryReducer;
