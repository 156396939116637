import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as figureOne } from "../../../static/svg/overview-figure-1.svg";
import { ReactComponent as figureTwo } from "../../../static/svg/overview-figure-2.svg";
import { ReactComponent as figureThree } from "../../../static/svg/overview-figure-3.svg";
import { ReactComponent as figureFour } from "../../../static/svg/overview-figure-4.svg";

import "./GuideContent.scss";

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const GuideContent = ({ setMenuPositionOnLoad }) => {
  let contentDivRef = useRef(null);

  const [figures, setFigures] = useState([
    { image: figureOne, label: "Step 1 - Register with your email" },
    { image: figureTwo, label: "Step 2 - Connect your binance account" },
    { image: figureThree, label: "Step 3 - Set up your trading profile" },
    { image: figureFour, label: "Step 4 - Watch your profit grow" },
  ]);

  const [CurrentFigure, setCurrentFigure] = useState(figureOne);

  const [index, setIndex] = useState(0);

  const [currentLabel, setCurrentLabel] = useState(
    "Step 1 - Register with your email"
  );

  const cycleThroughFigures = () => {
    if (index < figures.length - 1) setIndex(index + 1);
    else setIndex(0);

    setCurrentFigure(figures[index].image);
    setCurrentLabel(figures[index].label);
  };

  useInterval(cycleThroughFigures, 3500);

  const handleOnContentLoad = () => {
    const topBound = contentDivRef.current.getBoundingClientRect().top;
    const bottomBound = contentDivRef.current.getBoundingClientRect().bottom;

    setMenuPositionOnLoad(topBound, bottomBound);
  };
  return (
    <div
      onLoad={handleOnContentLoad}
      ref={contentDivRef}
      className="guide-content"
    >
      <div className="guide-content__layout">
        <article
          className="guide-content__article"
          id="overview-how-to-set-up-an-account"
        >
          <h3 className="sub-section-headline">
            overview: how to set up an account
          </h3>
          <figure className="guide-content__figure">
            <CurrentFigure className="guide-content__figure-image" />
            <figcaption className="guide-content__figure-label">
              {currentLabel}
            </figcaption>
          </figure>
        </article>
        <article
          className="guide-content__article"
          id="register-with-your-email-address"
        >
          <h3 className="sub-section-headline">
            register with your email address
          </h3>
          <p className="body-text">
            To register an account, just go to our{" "}
            <Link to="/accounts/registration">registration</Link> page and fill
            out the form. Then, you will receive an email with a verification
            link. Click the link and you will have full access to your account.
            Make sure to set up your trading profile and link your binance
            account to take full advantage of our services!
          </p>
        </article>

        <article
          className="guide-content__article"
          id="connect-your-binance-account"
        >
          <h3 className="sub-section-headline">connect your binance account</h3>
          <p className="body-text">
            For an account to be considered "tradable" by our platform you must
            connect it to your Binance account. This is done by simply
            generating an API key and secret through the Binance website (this
            can not be done through the app) and then logging into your Altcoin
            Ninja account and entering your newly generated Binance credentials
            in the account settings section of your user dashboard.
          </p>
          <p className="body-text">
            You don't have to use your main Binance account. You can create a
            new account and deposit any assets that you wish to be traded. It is
            highly recommended to create a specific key for our platform and not
            use it with any other websites.
          </p>
          <div
            className="guide-content__article-step"
            id="register-or-login-with-your-binance-account"
          >
            <h4 className="step-headline">
              1 &#41; register or login with your binance account
            </h4>
            <img
              src="https://apettigrew.imgix.net/altninjas/binance-login.png?auto=compress"
              alt="Binance login form"
              className="guide-content__figure"
            />
          </div>
          <div
            className="guide-content__article-step"
            id="generate-your-api-key-and-secret"
          >
            <h4 className="step-headline">
              2 &#41; generate your <span className="uppercase">api</span> key
              and secret
            </h4>
            <p className="body-text">
              *Note: Only verified accounts are able to generate API keys with
              the necessary settings.
            </p>
            <p className="body-text">
              Navigate to the account settings page. This appears when you first
              login to your Binance account. If you don't see this page go to
              the top right and select the user icon and then "Account" and you
              will be taken there.
            </p>
            <img
              src="https://apettigrew.imgix.net/altninjas/api-settings.png"
              alt="Binance dashboard with API settings button circled"
              className="guide-content__figure"
            />

            <p className="body-text">Go to API settings (circled above).</p>
            <p className="body-text">
              Name your key and press submit. You will need to enable 2FA before
              this step.
            </p>
            <img
              src="https://apettigrew.imgix.net/altninjas/name-api.png?auto=compress"
              alt="Binance create new key form"
              className="guide-content__figure"
            />
            <p className="body-text">
              Copy your API key and secret. Make sure to store the secret in a
              safe location once added to the Ninja Trade App. Binance only
              shows the secret when you create the key so be sure to hold on to
              it.
            </p>
            {/*<img*/}
            {/*  src="https://apettigrew.imgix.net/altninjas/binance-api-settings-edit.png?auto=compress"*/}
            {/*  alt="Binance API key settings"*/}
            {/*  className="guide-content__figure"*/}
            {/*/>*/}
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/tutorial-api-list-whitelist.png"
              }
              alt="Binance API key settings"
              className="guide-content__figure"
            />
            <p className="body-text">
              Press edit and then ensure "Enable Spot & Margin Trading" is
              enabled and "Enable Withdrawals" is disabled.
            </p>
            <p className="body-text">
              Under "IP access restrictions" select "Restrict access to trusted
              IPs only (Recommended)", add <b>35.197.148.112</b> and{" "}
              <b>191.96.238.132</b>, then click "Confirm".
            </p>
            <p className="body-text">Afterwards, make sure to press "Save".</p>
            <p className="body-text">
              Your Binance trading API is now created and is ready to be added
              to the Ninja Trade App.
            </p>
          </div>
          <div
            className="guide-content__article-step"
            id="enter-your-api-key-and-secret-in-the-dashboard-settings"
          >
            <h4 className="step-headline">
              3 &#41; enter your <span className="uppercase">api</span> key and
              secret in the dashboard settings
            </h4>
            <p className="body-text">
              Copy and paste your API key and secret in the correct fields on
              the settings page of your Altcoin Ninja's dashboard.
            </p>
          </div>
        </article>
        <article
          className="guide-content__article"
          id="set-up-your-trading-profile"
        >
          <h3 className="sub-section-headline">setup your trading profile</h3>
          <p className="body-text">
            The trade profile helps us assess the amount of risk you are
            comfortable with while developing your portfolio. To set up your
            trade profile preferences head over to the{" "}
            <Link to="/dashboard/settings">dashboard settings</Link> page and
            select your preferences.
          </p>
        </article>
        <article className="guide-content__article" id="set-up-notifications">
          <h3 className="sub-section-headline">setup notifications</h3>
          <p className="body-text">
            Once you've registered, connected your Binance account and setup
            your trade profile, we can start making trades for you. At this
            point, you can connect your Discord or Telegram account and
            configure your settings to receive profit reports and/or per trade
            notifications. To set up your notification preferences head over to
            the <Link to="/dashboard/settings">dashboard settings</Link> page
            and select your preferences.{" "}
            <strong>
              For a complete step by step guide on how to connect your Discord
              or Telegram account continue reading.
            </strong>
          </p>
          <div className="guide-content__article-step" id="setup-discord">
            <h4 className="step-headline">discord</h4>
            <p className="body-text">
              Head over to our{" "}
              <a href="https://discord.gg/aQvg9wT">discord server</a> and send{" "}
              <strong>$link [username]</strong> in a message to the Altcoin
              Ninjas Bot, where [username] is the username you registered with.
            </p>
            <p className="body-text">
              A verification code will be sent to the email address you
              registered along with that username. Open the verification email
              and retrieve your code. Then type $verify [code], where code is
              the verification code in a direct message to the Altcoin Ninjas
              Bot on our Discord server.
            </p>
            <p className="body-text">
              Now you can edit your Discord notification settings by messaging
              the bot directly{" "}
              <strong>(for a list of commands type $commands)</strong> or
              through the settings page on the website's dashboard.
            </p>
          </div>
          <div className="guide-content__article-step" id="setup-telegram">
            <h4 className="step-headline">telegram</h4>
            <p className="body-text">
              Log in to your Telegram account add the{" "}
              <a href="https://web.telegram.org/#/im?p=@AltcoinNinjas_bot">
                Altcoin Ninjas Bot
              </a>
              . Then use the <strong>/menu</strong> command. Click the Link
              Username button and then enter your username.
            </p>
            <p className="body-text">
              A verification code will be sent to the email address you
              registered along with that username. Open the verification email
              to retrieve your code. Go back to the bot and click Verify
              Account. Enter your verification code to complete the process.
            </p>
            <p className="body-text">
              Now you can edit your Telegram notification settings in the menu
              by clicking the Settings button or through the settings page on
              the website's dashboard.
            </p>
          </div>
        </article>
        <article className="guide-content__article" id="faq">
          <h3 className="sub-section-headline faq">
            Frequently Asked Questions
          </h3>
          <div className="guide-content__article-step faq">
            <h4 className="step-headline">How much does our service cost?</h4>
            <p className="body-text">
              Our service is FREE to use, meaning there is no subscription. We
              make profit by taking 50% of the profit from successful trades
              only. This means it is in our best interest to make as many
              profitable trades for you as possible.
            </p>
          </div>
          <div className="guide-content__article-step faq">
            <h4 className="step-headline">How much profit will I make?</h4>
            <p className="body-text">
              Before we start, we must make it clear - we can’t and won’t
              guarantee any profit. Past performance is no guarantee for future
              results. Investing in cryptocurrencies is a big risk - only invest
              what you can afford to lose.
            </p>
            <p className="body-text">
              Our team will make sure that you will get the best possible
              signals, depending on the market condition. Normally there will be
              3-5 signals per day. Sometimes there will be none - it is
              completely normal. It can take up to one week for signals to reach
              its target. The target depends on the signal and market
              conditions, but mostly the team opts for 3-7% increase per trade.
            </p>
          </div>
          <div className="guide-content__article-step faq">
            <h4 className="step-headline">
              Is it safe to connect my Binance account?
            </h4>
            <p className="body-text">
              You do not have to use your main “Binance” account to connect to
              us - you can create a sub-account and fill it with funds (read
              more{" "}
              <a href="https://www.binance.com/en/support/articles/360020632811">
                here
              </a>
              ).
            </p>
            <p className="body-text">
              We connect to your Binance account using the Binance API. We will
              never ask you for your account password. We will never have full
              access to your account. All we need is permission to trade.
            </p>
          </div>
          <div className="guide-content__article-step faq">
            <h4 className="step-headline">
              I want to join our team. How can I do that?
            </h4>
            <p className="body-text">
              Our team is expanding and always looking for new talented people.
              If you think that you would be a great addition to our team, send
              us your CV and LinkedIn profile to{" "}
              <a href="mailto:admin@altninjas.com">admin@altninjas.com</a> with
              the subject “CV for us”
            </p>
          </div>
          <div className="guide-content__article-step faq">
            <h4 className="step-headline">
              I want offer business partnership or advertise my project. How can
              I do that?
            </h4>
            <p className="body-text">
              Our team is open for business proposals. If you have an idea how
              we could work together, or you want to advertise with us - send us
              an email at{" "}
              <a href="mailto:admin@altninjas.com">admin@altninjas.com</a> with
              subject “Business Proposal for us” or go to our{" "}
              <Link to="/contact-us">contact us</Link> page and fill out the
              form.
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

GuideContent.propTypes = {
  setMenuPositionOnLoad: PropTypes.func.isRequired,
};

export default GuideContent;
