import { DashActivityTypes } from "./dash-activity.types";
import { SnackbarTypes } from "../Snackbar/snackbar.types";
import {
  handleData,
  handleError,
  handleDataWithSnackbar,
  handleValidationError,
} from "../utils.redux";
import { Urls, getRequest, postRequest } from "../../api";
import { convertTimeZonePreserveDate } from "../../utils/date_utils";

export const getRegistrationData =
  ({ startDate, endDate }) =>
  (dispatch) => {
    dispatch({ type: DashActivityTypes.GET_REGISTRATION_DATA_PENDING });

    getRequest(
      Urls.server,
      `/statistics/registration?startDate=${convertTimeZonePreserveDate(
        startDate
      )}&endDate=${convertTimeZonePreserveDate(endDate)}`
    )
      .then((data) =>
        handleData({
          data,
          dispatch,
          type: DashActivityTypes.GET_REGISTRATION_DATA_SUCCESS,
        })
      )
      .catch((error) =>
        handleError({
          error,
          dispatch,
          type: DashActivityTypes.GET_REGISTRATION_DATA_FAILED,
          snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
        })
      );
  };

export const filterUserActivityData =
  ({ username, role, status, page }) =>
  (dispatch) => {
    dispatch({ type: DashActivityTypes.GET_USER_ACTIVITY_DATA_PENDING });

    getRequest(
      Urls.server,
      `/users/activity?username=${username}&role=${role}&status=${status}&page=${page}`
    )
      .then((data) =>
        handleData({
          data,
          dispatch,
          type: DashActivityTypes.GET_USER_ACTIVITY_DATA_SUCCESS,
        })
      )
      .catch((error) =>
        handleError({
          error,
          dispatch,
          type: DashActivityTypes.GET_USER_ACTIVITY_DATA_FAILED,
          snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
        })
      );
  };

export const toggleLockAccount =
  ({ userId, currentLockStatus, currentFilter }) =>
  (dispatch) => {
    dispatch({ type: DashActivityTypes.TOGGLE_LOCK_ACCOUNT_PENDING });

    postRequest(Urls.server, "/user/account-status/lock", {
      userId,
      currentLockStatus,
    })
      .then((data) => {
        handleData({
          data,
          dispatch,
          type: DashActivityTypes.TOGGLE_LOCK_ACCOUNT_SUCCESS,
        });
        dispatch(filterUserActivityData(currentFilter));
      })
      .catch((error) =>
        handleError({
          error,
          dispatch,
          type: DashActivityTypes.TOGGLE_LOCK_ACCOUNT_FAILED,
          snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
        })
      );
  };

export const sendSignalerRegistrationLink = (payload) => (dispatch) => {
  dispatch({ type: DashActivityTypes.SEND_SIGNALER_REGISTRATION_LINK_PENDING });

  postRequest(Urls.server, "/register/signaler/send-registration-link", payload)
    .then((data) =>
      handleDataWithSnackbar({
        data,
        dispatch,
        type: DashActivityTypes.SEND_SIGNALER_REGISTRATION_LINK_SUCCESS,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      })
    )
    .catch((error) =>
      handleValidationError({
        error,
        dispatch,
        type: DashActivityTypes.SEND_SIGNALER_REGISTRATION_LINK_FAILED,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      })
    );
};






