import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getRegistrationData } from '../../../redux/DashActivity/dash-activity.actions';
import ChartLegend from '../../../components/ChartLegend';
import Button from '../../../components/Button';
import DateRangeInput from '../../../components/DateRangeInput';
import './RegistrationChartFilters.scss';

const INITIAL_STATE = {
  startDate: null,
  endDate: null,
  focusInput: null,
};

const RegistrationChartFilters = ({ getRegistrationData }) => {
  const [fieldInputs, setFieldInputs] = useState(INITIAL_STATE);
  const handleDatesChange = ({ startDate, endDate }) =>
    setFieldInputs(prevState => ({
      ...prevState,
      startDate: startDate ? moment(startDate).startOf('day') : null,
      endDate: endDate ? moment(endDate).endOf('day') : null,
    }));

  const handleFocusChange = focusedInput =>
    setFieldInputs(prevState => ({ ...prevState, focusedInput }));

  const handleSubmit = e => {
    e.preventDefault();
    const { startDate: startDateObject, endDate: endDateObject } = fieldInputs;

    const startDate = startDateObject
      ? startDateObject.startOf('day').valueOf()
      : 0;
    const endDate = endDateObject ? endDateObject.endOf('day').valueOf() : 0;

    getRegistrationData({ startDate, endDate });
  };

  return (
    <div className="registration-chart-filters">
      <ChartLegend label="Number of Registrations" />
      <form onSubmit={handleSubmit}>
        <DateRangeInput
          startDate={fieldInputs.startDate}
          startDateId="signal-history-start-date"
          startDatePlaceholderText="Start Date"
          endDate={fieldInputs.endDate}
          endDateId="signal-history-end-date"
          endDatePlaceholderText="End Date"
          onDatesChange={handleDatesChange}
          focusedInput={fieldInputs.focusedInput}
          onFocusChange={handleFocusChange}
        />
        <Button type="solid" header={false} element="input" value="Filter" />
      </form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getRegistrationData: formData => dispatch(getRegistrationData(formData)),
});

export default connect(
  null,
  mapDispatchToProps,
)(RegistrationChartFilters);
