import React from 'react';

import BrokenCupImg from '../../static/img/caution-tape-big.png';

import './ErrorPage.scss';

const ErrorPage = () => (
  <main className="error-page">
    <div className="error-page__wrapper">
      <img src={BrokenCupImg} alt="" />
      <h2 className="sub-section-headline ">Oops! Something is broken</h2>
      <p className="body-text">
        We aplogize for the inconvenience, please feel free to{' '}
        <a href="#">let us know</a> what happened. Refresh the page and try
        again.
      </p>
    </div>
  </main>
);

export default ErrorPage;
