import {InvoicesTypes} from "./Invoices/invoices.types";
import {SnackbarTypes} from "./Snackbar/snackbar.types";

export const handleValidationError = ({
  error,
  dispatch,
  type,
  snackbarOpenType
}) => {
  const { data } = error.response;

  if (data.snackbar) {
    dispatch({ type });
    dispatch({ type: snackbarOpenType, payload: data.snackbar });
  } else dispatch({ type, payload: data });
};

export const handleError = ({ error, dispatch, type, snackbarOpenType }) => {
  const { data } = error.response;

  dispatch({ type });
  dispatch({ type: snackbarOpenType, payload: data.snackbar });
};

// helper for generic error handling with more coverage than the handleError function, wanted to use this in case of the error response being structured wrong
export const handleErrorStrict = (type, error, dispatch) => {
  if (error.response.data.snackbar) {
    dispatch({
      type,
      payload: null,
    });
    dispatch({
      type: SnackbarTypes.OPEN_SNACKBAR,
      payload: error.response.data.snackbar,
    });
  } else {
    dispatch({
      type,
    });
  }
};

export const handleDataWithSnackbar = ({
  data,
  dispatch,
  type,
  snackbarOpenType
}) => {
  if (data.snackbar) {
    dispatch({ type, payload: data });
    dispatch({ type: snackbarOpenType, payload: data.snackbar });
  } else dispatch({ type, payload: data });
};

export const handleData = ({ data, dispatch, type }) => {
  dispatch({ type, payload: data });
};
