import { combineReducers } from "redux";

import dashMenuReducer from "./DashMenu/dash-menu.reducer";
import messageModalReducer from "./MessageModal/message-modal.reducer";
import snackbarReducer from "./Snackbar/snackbar.reducer";
import registrationReducer from "./Registration/registration.reducer";
import loginReducer from "./Login/login.reducer";
import userReducer from "./User/user.reducer";
import usersReducer from "./Users/users.reducer";
import recoveryReducer from "./Recovery/recovery.reducer";
import appErrorReducer from "./AppError/app-error.reducer";
import contactFormReducer from "./ContactForm/contact-form.reducer";
import settingsReducer from "./Settings/settings.reducer";
import tradeInterfaceReducer from "./TradeInterface/trade-interface.reducer";
import signalHistoryReducer from "./SignalHistory/signal-history.reducer";
import signalModalReducer from "./SignalModal/signal-modal.reducer";
import dashHomeReducer from "./DashHome/dash-home.reducer";
import dashActivityReducer from "./DashActivity/dash-activity.reducer";
import withdrawalsAndDepositsReducer from "./WithdrawalsAndDeposits/withdrawals-and-deposits.reducer";
import InvoicesReducer from "./Invoices/invoices.reducer";
import invoiceModalReducer from "./InvoiceModal/invoice-modal.reducer";
import assignInvoiceToTransactionModalReducer from "./AssignTransactionToInvoiceModal/assign-modal.reducer";
import symbolsReducer from "./Symbols/symbols.reducer";
import allUserTypesReducer from "./AllUserTypes/all-user-types.reducer";

export default combineReducers({
  dashMenu: dashMenuReducer,
  messageModal: messageModalReducer,
  snackbar: snackbarReducer,
  registration: registrationReducer,
  login: loginReducer,
  user: userReducer,
  users: usersReducer,
  recovery: recoveryReducer,
  appError: appErrorReducer,
  contactForm: contactFormReducer,
  settings: settingsReducer,
  tradeInterface: tradeInterfaceReducer,
  signalHistory: signalHistoryReducer,
  signalModal: signalModalReducer,
  dashHome: dashHomeReducer,
  dashActivity: dashActivityReducer,
  withdrawalsAndDeposits: withdrawalsAndDepositsReducer,
  invoices: InvoicesReducer,
  invoiceModal: invoiceModalReducer,
  assignInvoiceModal: assignInvoiceToTransactionModalReducer,
  symbols: symbolsReducer,
  allUserTypes: allUserTypesReducer,
});
