import React, {useEffect} from "react";
import {connect} from "react-redux";
import {getUsersInvoiceTableData} from "../../../redux/Invoices/invoices.actions";
import DashboardLoading from "../../../components/DashboardLoading";
import {ReactComponent as FirstChevron} from "../../../static/svg/page-chevron-first.svg";
import {ReactComponent as LastChevron} from "../../../static/svg/page-chevron-last.svg";
import "./UserInvoiceTableDisplay.scss";
import UserInvoicesTableTableRow from "./UserInvoicesTableRow";

const UserInvoicesTableDisplay = ({getInvoiceTableData, headerTitleArray, removeHeaderAtSmallArray, totalPages, invoiceList, isPending, page, currentFilter, lowerDisplay, upperDisplay, totalInvoices, user}) => {
    const userID = user ? (user.data ? user.data.id : "" ) : "";

    const resultsPerPage = 5;

    const renderTableRows = () => {
        return invoiceList.map((row, i) => (
            <UserInvoicesTableTableRow key={row._id} data={row} index={i}/>
        ));
    };

    const renderTableHeaders = () => {
        return headerTitleArray.map(title => {
            let header;

            if (removeHeaderAtSmallArray.includes(title)) {
                header = (<span key={title} className="table-display__header-title remove-at-sm">{title}</span>);
            } else if (!removeHeaderAtSmallArray.includes(title)) {
                header = (<span key={title} className="table-display__header-title">{title}</span>);
            }
            return header;
        });
    };

    const createPageLinkHandler = i => () =>
        getInvoiceTableData({...currentFilter, page: i, user_id: userID});

    const renderPagination = () => {
        let paginationArr = [];
        const indexValue = Math.ceil(page / resultsPerPage);
        const startIndex = Math.ceil(indexValue * resultsPerPage - 4);
        const pageNumberLimit =
            indexValue * resultsPerPage > totalPages
                ? totalPages
                : indexValue * resultsPerPage;

        for (let i = startIndex; i <= pageNumberLimit; i++) {
            if (page === i)
                paginationArr.push(
                    <span className="table-display__pagination-link current" key={i}>
            {i}
          </span>
                );
            else {
                paginationArr.push(
                    <button
                        className="table-display__pagination-link"
                        key={i}
                        onClick={createPageLinkHandler(i)}
                    >
                        {i}
                    </button>
                );
            }
        }
        return paginationArr;
    };

    const handleFirstPageClick = () => {
        getInvoiceTableData({ ...currentFilter, page: 1, user_id: userID });
    };

    const handleReverseFivePages = () => {
        getInvoiceTableData({
            ...currentFilter,
            user_id: userID,
            page:
                Math.ceil(page / resultsPerPage) !== 1
                    ? (Math.ceil(page / resultsPerPage) - 1) * resultsPerPage
                    : resultsPerPage
        });
    };

    const handleSkipForwardFivePages = () => {
        getInvoiceTableData({
            ...currentFilter,
            user_id: userID,
            page:
                Math.ceil(page / resultsPerPage) <
                Math.ceil(totalPages / resultsPerPage)
                    ? Math.ceil(page / resultsPerPage + 1) * 5 - 4
                    : totalPages
        });
    };

    const handleLastPageClick = () => {
        getInvoiceTableData({ ...currentFilter, page: totalPages, user_id: userID });
    };

    return (
        <>
            {isPending ? <DashboardLoading /> : null}
            <div className="user-table-display-invoices">
                <div className="table-display__table-header">
                    {renderTableHeaders()}
                </div>

                <div className="table-display__table-body">
                    {invoiceList.length === 0 ? (
                        <div className="table-display__no-results">
                            <span>No Invoices</span>
                        </div>
                    ) : null}
                    {renderTableRows()}
                </div>

                {totalInvoices !== undefined && (
                    <div className="show-at-sm">
                        <div className="table-display__pagination">
                            <span className="table-display__pagination-total">{`Showing ${lowerDisplay} - ${upperDisplay} of ${totalInvoices}`}</span>
                        </div>
                    </div>
                )}

                <div className="table-display__pagination">
                    {totalInvoices !== undefined && (
                        <span className="table-display__pagination-total remove-at-sm">{`Showing ${lowerDisplay} - ${upperDisplay} of ${totalInvoices}`}</span>
                    )}

                    <button disabled={page === 1} onClick={handleFirstPageClick}>
                        First
                    </button>
                    <button
                        disabled={Math.ceil(page / resultsPerPage) === 1}
                        onClick={handleReverseFivePages}
                    >
                        <FirstChevron className="pagination__double-chevron" />
                    </button>

                    {renderPagination()}

                    <button
                        disabled={
                            Math.ceil(page / resultsPerPage) * resultsPerPage >= totalPages
                        }
                        onClick={handleSkipForwardFivePages}
                    >
                        <LastChevron className="pagination__double-chevron" />
                    </button>
                    <button disabled={page === totalPages} onClick={handleLastPageClick}>
                        Last
                    </button>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    getUsersInvoiceTableData: filterData => dispatch(getUsersInvoiceTableData({...filterData, pendingOnly: false}))
});

const mapStateToProps = ({invoices: {totalPages, invoiceList, isPending, page, currentFilter, lowerDisplay, upperDisplay, totalInvoices}, user}) => ({
    totalPages, invoiceList, isPending, page, currentFilter, lowerDisplay, upperDisplay, totalInvoices, user
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInvoicesTableDisplay);
