import React from 'react';

import './ChartLegend.scss';

const ChartLegend = ({ color, label }) => {
  return (
    <div className="chart-legend">
      {color ? (
        <div
          style={{ backgroundColor: `#${color}` }}
          className="chart-legend__color-reference"
        />
      ) : null}
      <h2 className="section-sub-headline">{label}</h2>
    </div>
  );
};

export default ChartLegend;
