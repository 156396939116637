import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

import "./style.scss";

const CheckBox = ({ size, value, callback, id, checked }) => {
  const handleCheckboxChange = (e) => {
    callback(e);
  };

  return (
    <Checkbox
      onChange={handleCheckboxChange}
      value={value}
      size={size}
      id={id}
      checked={checked}
    />
  );
};

export default CheckBox;
