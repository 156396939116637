import { UsersTypes } from './users.types';

const INITIAL_STATE = {
  isPending: false,
  users: [],
  errors: null,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UsersTypes.GET_USERS_PENDING:
      return { ...state, isPending: true };

    case UsersTypes.GET_USERS_SUCCESS:
      return { ...state, isPending: false, users: [...action.payload] };

    case UsersTypes.GET_USERS_FAILED:
      return { ...state, isPending: false, errors: action.payload };

    default:
      return state;
  }
};

export default usersReducer;
