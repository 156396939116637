import React, { useState } from "react";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";

import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import CircleLoader from "../../../components/CircleLoader";
import {
  submitBlacklistCoin,
  deleteBlacklistCoin
} from "../../../redux/Settings/settings.actions";
import "./BlacklistForm.scss";

const BlacklistForm = ({
  settings,
  errors,
  submitBlacklistCoin,
  deleteBlacklistCoin,
  updateBlacklistPending
}) => {
  const [symbol, setSymbol] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    submitBlacklistCoin({ symbol });
    setSymbol("");
  };

  const handleInputChange = e => {
    setSymbol(e.target.value.toUpperCase());
  };

  const handleChipDelete = e => {
    const symbol = e.currentTarget.parentElement.dataset.symbol;
    deleteBlacklistCoin(symbol);
  };

  const renderChips = chipLabelArray =>
    chipLabelArray.length
      ? chipLabelArray.map(label => (
          <Chip
            key={label}
            size="medium"
            label={label.toUpperCase()}
            clickable={true}
            onDelete={handleChipDelete}
            className={`blacklist-form__chip ${label}`}
            data-symbol={label}
          />
        ))
      : null;

  return (
    <div className="blacklist-form custom-mui-form">
      <form onSubmit={handleSubmit} className="custom-mui-form__form">
        <TextInput
          id="coin-blacklist-input"
          placeholder="BTC"
          type="text"
          label="symbol"
          fullWidth={true}
          name="symbol"
          variant="outlined"
          onChange={handleInputChange}
          value={symbol}
          helperText={errors ? errors.symbol : ""}
          error={errors ? errors.symbol || false : false}
          disabled={updateBlacklistPending}
        />

        <Button
          type="solid"
          header={false}
          element="button"
          actionType="submit"
          disabled={updateBlacklistPending}
        >
          {updateBlacklistPending ? <CircleLoader /> : "Blacklist"}
        </Button>
      </form>
      <div className="blacklist-form__chip-display">
        {settings &&
          settings.coinBlacklist &&
          renderChips(settings.coinBlacklist)}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  submitBlacklistCoin: formData => dispatch(submitBlacklistCoin(formData)),
  deleteBlacklistCoin: symbol => dispatch(deleteBlacklistCoin(symbol))
});

const mapStateToProps = ({
  settings: { settings, errors, updateBlacklistPending }
}) => ({
  settings,
  errors,
  updateBlacklistPending
});

export default connect(mapStateToProps, mapDispatchToProps)(BlacklistForm);
