import React from "react";
import { FormGroup } from "@material-ui/core";

import SwitchInput from "../../../../components/SwitchInput";
import SelectInput from "../../../../components/SelectInput";
import "./NotificationsInputColumn.scss";

const NotificationsInputColumn = ({
  type,
  icon,
  fieldInputs,
  setFieldInputs
}) => {
  const { enabled, perTrade, profitReport, interval, connected } = fieldInputs;

  const handleChange = e =>
    setFieldInputs(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));

  const handleSwitchChange = e =>
    setFieldInputs(prevState => ({
      ...prevState,
      [e.target.name]: !prevState[e.target.name]
    }));

  const disconnectedClass = connected
    ? ""
    : "notifications-input-column--disconnected";

  return (
    <div
      className={`notifications-input-column notifications-input-column--${type} ${disconnectedClass}`}
    >
      <div className="notifications-input-column__title">
        {icon}
        <span>{type}</span>
      </div>
      <FormGroup>
        <SwitchInput
          name="enabled"
          value={enabled}
          checked={enabled}
          onChange={handleSwitchChange}
          classnames={`switch-input--${type}`}
          disabled={!connected}
          id={`notifications-${type}-enabled-input`}
        />
        <SwitchInput
          name="perTrade"
          value={perTrade}
          checked={perTrade}
          onChange={handleSwitchChange}
          classnames={`switch-input--${type}`}
          disabled={!connected}
          id={`notifications-${type}-perTrade-input`}
        />
        <SwitchInput
          name="profitReport"
          value={profitReport}
          checked={profitReport}
          onChange={handleSwitchChange}
          classnames={`switch-input--${type}`}
          disabled={!connected}
          id={`notifications-${type}-profit-report-enabled-input`}
        />
        {profitReport ? (
            <div className="notifications-input-column-parent-helper">
              <SelectInput
                onChange={handleChange}
                menuItemArray={["WEEKLY", "MONTHLY"]}
                id={`notifications-${type}-profit-report-interval-input`}
                value={interval}
                name="interval"
                variant="outlined"
              />
            </div>
        ) : null}
      </FormGroup>
    </div>
  );
};

export default NotificationsInputColumn;
