import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { updateNotifications } from "../../../redux/Settings/settings.actions";
import Button from "../../../components/Button";
import CircleLoader from "../../../components/CircleLoader";
import NotificationsInputColumn from "./NotificationsInputColumn";
import { ReactComponent as EmailIcon } from "../../../static/svg/email-icon.svg";
import { ReactComponent as DiscordIcon } from "../../../static/svg/discord-icon.svg";
import { ReactComponent as TelegramIcon } from "../../../static/svg/telegram-icon.svg";

import "./Notifications.scss";

const Notifications = ({
  settings,
  updateNotifications,
  updateNotificationsPending
}) => {
  const { email, discord, telegram } =
    (settings && settings.notifications) || {};

  const INITIAL_NOTIFICATIONS_STATE = {
    enabled: false,
    perTrade: false,
    profitReport: false,
    interval: false,
    connected: false
  };

  const [emailFieldInputs, setEmailFieldInputs] = useState(
    INITIAL_NOTIFICATIONS_STATE
  );
  const [discordFieldInputs, setDiscordFieldInputs] = useState(
    INITIAL_NOTIFICATIONS_STATE
  );
  const [telegramFieldInputs, setTelegramFieldInputs] = useState(
    INITIAL_NOTIFICATIONS_STATE
  );

  useEffect(() => {
    setEmailFieldInputs({
      enabled: email && email.enabled,
      perTrade: email && email.perTrade.enabled,
      profitReport: email && email.profitReport.enabled,
      interval: email && email.profitReport.interval,
      connected: email && email.connected
    });
    setDiscordFieldInputs({
      enabled: discord && discord.enabled,
      perTrade: discord && discord.perTrade.enabled,
      profitReport: discord && discord.profitReport.enabled,
      interval: discord && discord.profitReport.interval,
      connected: discord && discord.connected
    });
    setTelegramFieldInputs({
      enabled: telegram && telegram.enabled,
      perTrade: telegram && telegram.perTrade.enabled,
      profitReport: telegram && telegram.profitReport.enabled,
      interval: telegram && telegram.profitReport.interval,
      connected: telegram && telegram.connected
    });
  }, [settings]);

  const handleSubmit = e => {
    e.preventDefault();
    updateNotifications({
      email: emailFieldInputs,
      discord: discordFieldInputs,
      telegram: telegramFieldInputs
    });
  };
  return (
    <form onSubmit={handleSubmit} className="notifications">
      <p className="body-text">
        Visit the <Link to="/guide">guide</Link> for more info on how to link
        your account to email, discord or telegram.
      </p>
      <div className="notifications__container">
        <div className="notifications__column">
          <span className="notifications__label">Enabled</span>
          <span className="notifications__label">Per Trade</span>
          <span className="notifications__label">Profit Report</span>
        </div>
        <NotificationsInputColumn
          type="email"
          icon={<EmailIcon />}
          fieldInputs={emailFieldInputs}
          setFieldInputs={setEmailFieldInputs}
        />
        <NotificationsInputColumn
          type="discord"
          icon={<DiscordIcon />}
          fieldInputs={discordFieldInputs}
          setFieldInputs={setDiscordFieldInputs}
        />
        <NotificationsInputColumn
          type="telegram"
          icon={<TelegramIcon />}
          fieldInputs={telegramFieldInputs}
          setFieldInputs={setTelegramFieldInputs}
        />
      </div>
      <Button
        type="solid"
        header={false}
        element="button"
        actionType="submit"
        disabled={updateNotificationsPending}
      >
        {updateNotificationsPending ? <CircleLoader /> : "save"}
      </Button>
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  updateNotifications: formData => dispatch(updateNotifications(formData))
});

const mapStateToProps = ({
  settings: { settings, updateNotificationsPending }
}) => ({ settings, updateNotificationsPending });

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
