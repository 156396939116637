import { DashActivityTypes } from "./dash-activity.types";
import { ClearErrorsTypes } from "../ClearErrors/clear-errors.types";

const INITIAL_STATE = {
  registrationDataIsPending: false,
  userActivityDataIsPending: false,
  lockAccountIsPending: false,
  sendSignalerRegistrationLinkIsPending: false,
  sendSignalerRegistrationLinkSuccess: false,
  registrationChartData: [],
  users: [],
  numberOfUsers: "N/A",
  page: null,
  nextPage: null,
  prevPage: null,
  totalPages: null,
  currentFilter: {
    username: "",
    role: "",
    status: ""
  },
  errors: null
};

const dashActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashActivityTypes.GET_REGISTRATION_DATA_PENDING:
      return { ...state, registrationDataIsPending: true };

    case DashActivityTypes.GET_REGISTRATION_DATA_SUCCESS:
      return {
        ...state,
        registrationDataIsPending: false,
        registrationChartData: [...action.payload]
      };

    case DashActivityTypes.GET_REGISTRATION_DATA_FAILED:
      return { ...state, registrationDataIsPending: false };

    case DashActivityTypes.GET_USER_ACTIVITY_DATA_PENDING:
      return { ...state, userActivityDataIsPending: true };

    case DashActivityTypes.GET_USER_ACTIVITY_DATA_FAILED:
      return { ...state, userActivityDataIsPending: false };

    case DashActivityTypes.GET_USER_ACTIVITY_DATA_SUCCESS:
      const { users, currentFilter, pagination } = action.payload;
      return {
        ...state,
        userActivityDataIsPending: false,
        users: users,
        page: pagination.page,
        totalPages: pagination.totalPages,
        numberOfUsers: users.length,
        currentFilter: {
          username: currentFilter.username || "",
          role: currentFilter.role || "",
          status: currentFilter.status || ""
        }
      };

    case DashActivityTypes.TOGGLE_LOCK_ACCOUNT_PENDING:
      return { ...state, lockAccountIsPending: true };

    case DashActivityTypes.TOGGLE_LOCK_ACCOUNT_SUCCESS:
      return { ...state, lockAccountIsPending: false };

    case DashActivityTypes.TOGGLE_LOCK_ACCOUNT_FAILED:
      return { ...state, lockAccountIsPending: false };

    case DashActivityTypes.SEND_SIGNALER_REGISTRATION_LINK_PENDING:
      return {
        ...state,
        sendSignalerRegistrationLinkIsPending: true,
        sendSignalerRegistrationLinkSuccess: false
      };

    case DashActivityTypes.SEND_SIGNALER_REGISTRATION_LINK_FAILED:
      return {
        ...state,
        sendSignalerRegistrationLinkIsPending: false,
        sendSignalerRegistrationLinkSuccess: false,
        errors: action.payload
      };

    case DashActivityTypes.SEND_SIGNALER_REGISTRATION_LINK_SUCCESS:
      return {
        ...state,
        sendSignalerRegistrationLinkIsPending: false,
        sendSignalerRegistrationLinkSuccess: true
      };

    case ClearErrorsTypes.CLEAR_ERRORS:
      return { ...state, errors: null };

    default:
      return state;
  }
};

export default dashActivityReducer;
