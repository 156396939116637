import React, { useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as CloseIcon } from "../../static/svg/close-icon.svg";
import "./FixedBanner.scss";

const FixedBanner = ({}) => {
  const [bannerClosed, setBannerClosed] = useState(false);
  const bannerClosedClass = bannerClosed ? "fixed-banner__closed" : null;

  const handleCloseButtonClick = () => {
    setBannerClosed(true);
  };

  return (
    <div className={`fixed-banner ${bannerClosedClass}`}>
      <div className="fixed-banner__content-wrapper">
        <button
          onClick={handleCloseButtonClick}
          className="fixed-banner__close-button"
        >
          <CloseIcon className="fixed-banner__close-icon" />
        </button>
        <p>
          You might have noticed that some data is missing from your portfolio
          home page. This is because we display data from your Binance account
          so you can monitor our progress! Head over to the{" "}
          <Link to="/dashboard/settings">
            <span>settings page</span>
          </Link>{" "}
          to submit your credentials.
        </p>
      </div>
    </div>
  );
};

export default FixedBanner;
