import React from "react";
import "../../scss/typography.scss";
import "./PrivacyPolicyPage.scss";
import PageTitle from "../../components/PageTitle";

const PrivacyPolicyPage = () => (
  <main className="privacy-policy">
    <PageTitle title="privacy policy" />

    <section className="privacy-policy__content">
      <h2 className="sub-section-headline">PRIVACY POLICY</h2>

      <h3 className="sub-section-headline">
        <u>Introductory provisions</u>
      </h3>
      <p className="body-text">
        <br />
        ALTCOIN NINJAS, (hereinafter "Service", “we” or “us”) treats everything
        that is related to personal data with respect and integrity. We are
        committed to safeguarding the privacy of our website, of our services as
        data protection is of a particularly high priority for the management of
        our company.
        <br />
        <br />
        It is very important for us to accurately inform the users of this
        website about our Privacy Policy, so in the following, we provide you
        with precise information on what kind of data we collect from you, how
        we use it, for what purpose we use it and what your rights are, in this
        context.
        <br />
        <br />
        First, we need to define, and it is important for you to understand, the
        expression: <b>"Personal Data"</b>. It generically represents any kind
        of information about a physical person, whose particularities can lead,
        directly or indirectly, to its identification. Here you have, by way of
        example, but not limited to: name and surname, geographical address, any
        identification number, political orientation, sexual orientation, email
        address, any location information and any other online identifier such
        as the device used to access the Internet, IP address, or cookie
        information.
        <br />
        <br />
        With regards of your agreement on collection and use of personal data,
        we have taken all the necessary technical measures to provide you with
        this detailed agreement, so you can have a crisp clear experience, from
        the first to the last interaction with our website or our services.
        <br />
        <br />
        Visitors and Users agree and accept that the use of our website is not
        possible without any indication of personal data.
      </p>

      <h3 className="sub-section-headline">
        <u>Definitions</u>
      </h3>
      <p className="body-text">
        <br />
        Our data protection policy should be legible and understandable for the
        general public, as well as for our Users and business partners. To
        ensure this, we would like to first explain the terminology used.
        <br />
        <br />
        In this data protection declaration, we use, inter alia, the following
        terms:
        <br />
        <br />
        <ul>
          <li>
            • <b>Data Subject or Data Principal</b> - is any identified or
            identifiable natural person, whose personal data is collected and
            processed by us.
          </li>
          <li>
            • <b>Processing</b> - is any operation which is performed on
            personal data, such as collection, recording, organization,
            structuring, storage, etc.
          </li>
          <li>
            • <b>Restriction of processing</b> - is the marking of stored
            personal data with the aim of limiting their processing in the
            future.
          </li>
          <li>
            • <b>Profiling</b> - means any form of automated processing of
            personal data consisting of the use of personal data to evaluate
            certain personal aspects relating to a natural person.
          </li>
          <li>
            • <b>Data Controller or Data Fiduciary</b> - is the natural or legal
            person, public authority, agency or other body, which determines the
            purposes and means of the processing of personal data;
          </li>
          <li>
            • <b>Processor</b> - is a natural or legal person, public authority,
            agency or other body which processes personal data on behalf of the
            controller.
          </li>
          <li>
            • <b>Recipient</b> - is a natural or legal person, public authority,
            agency or another body, to which the personal data are disclosed.
            The processing of those data by those public authorities shall be in
            compliance with the applicable data protection rules according to
            the purposes of the processing.
          </li>
          <li>
            • <b>Third party</b> - is a natural or legal person, public
            authority, agency or body other than the ones above, who, under the
            direct authority of the controller or processor, are authorized to
            process personal data.
          </li>
          <li>
            • <b>Consent</b> - is any freely given, specific, informed and
            unambiguous indication of data subject’s acceptance to the
            processing of their personal data.
          </li>
        </ul>
      </p>

      <h3 className="sub-section-headline">
        <u>Compliance</u>
      </h3>
      <p className="body-text">
        The processing of personal data will be in line with these main
        international legislations:
        <br />
        <br />
        <ul>
          <li>
            - Personal Information Protection and Electronic Documents Act,
            applicable in Canada (PIPEDA);
          </li>
          <li>
            - California Consumer Privacy Act (2018) and Privacy Act U.S.C. 552a
            (Privacy Act of USA);
          </li>
          <li>
            - General Data Protection Regulation (GDPR), applicable in Europe;
          </li>
          <li>
            - Australian Privacy Principles contained in the Privacy Act 1988;
          </li>
          <li>- Indian Personal Data Protection Bill (2018);</li>
          <li>- Singapore PDPA (2012);</li>
          <li>- Privacy Acts in Latin America;</li>
          <li>
            - Any other country-specific data protection regulations are
            applicable.
          </li>
        </ul>
        <br />
        We are able to sell our products and services worldwide. For the purpose
        of avoiding any compliance conflict with any terminology used by any
        particular legislation, in this document: <b>”Users”</b> are the{" "}
        <b>”data subjects”</b> or the <b>”data principals”</b> and us is the{" "}
        <b>”data controller”</b> or <b>”data fiduciary”</b>
      </p>

      <h3 className="sub-section-headline">
        <u>By this Privacy Policy we clearly explain:</u>
      </h3>
      <p className="body-text">
        <br />
        <ul>
          <li>1. What Personal Data we collect and why we collect it;</li>
          <li>2. How we use Personal Data;</li>
          <li>3. Who we share Personal Data with;</li>
          <li>
            4. The choices we offer, including how to access, update, and remove
            Personal Data;
          </li>
        </ul>
        <br />
        By means of this data protection declaration, our company would like to
        inform the general public of the nature, scope, and purpose of the
        personal data we collect, use and process. This document describes the
        protection of our users and Users personal data.
        <br />
        <br />
        Our collects, stores, uses and discloses certain information when the
        visitors use our website. As a data controller, we have implemented
        numerous technical and organizational measures to ensure the most
        complete protection of personal data processed through this website.
        <br />
        <br />
        Our website incorporates privacy controls which affect how we will
        process your personal data. By using the privacy controls, you will not
        receive direct marketing communications and will limit the publication
        of your information.
        <br />
        <br />
        In your relationship with us through the website, controller, for the
        purposes of the General Data Protection Regulation (GDPR), other data
        protection laws applicable in Member states of the European Union and
        other provisions related to data protection, is:
        <br />
        <br />
        <b>“Altcoin Ninjas Organization Inc”</b>, Unit 301 15047 Marine Dr White
        Rock, BC V4B 1C5
        <br />
        <br />
        This Policy does not apply to other companies’ or organizations’ sites
        to which we may link to or may link to us. You should carefully review
        the privacy policies of those sites in order to determine how they treat
        your personal information.
        <br />
        <br />
        If you do not agree to this Privacy Policy, please exit, and do not
        access or use our website.
        <br />
        <br />
        We have kept this simple for your understanding, but if you’re not
        familiar with terms like “cookies” or “IP addresses,” feel free to
        contact us. Your privacy is really important to us, so whether you’re
        new to us or a long-time user, please take the time to get to know our
        practices.
      </p>

      <h3 className="sub-section-headline">
        <u>Use Cases</u>
      </h3>
      <p className="body-text">
        <br />
        Please take a moment to understand which use case(s) set out in this
        Privacy Policy apply to you:
        <br />
        <br />
        <ul>
          <li>• Website Visitor</li>
        </ul>
        <br />
        You are a “Website Visitor” by definition when you visit our website
        https://trading.altninjas.com/ and any other eventual subdomains
        associated with our principal domain. As a website visitor, we use your
        information for our own purposes, primarily for improving the use of our
        website and to provide you with more relevant content.
        <br />
        <br />
        <ul>
          <li>• User</li>
        </ul>
        <br />
        You become a “User” if you create an account on our website in order to
        buy one of our products or services. If you are a User, our primary
        purpose of using your personal data is for providing the service and the
        services to you. We retain your personal information for a limited time
        and for limited purposes, such as to make it easier for you to re-join
        our service in the future or to wish you offers for services that we
        think you may be interested in.
      </p>

      <h3 className="sub-section-headline">
        <u>What Personal Data we collect and how do we collect it</u>
      </h3>
      <p className="body-text">
        <br />
        In this Section we have set out:
        <br />
        <br />
        <ul>
          <li>
            • the general categories of personal data that we may process;
          </li>
          <li>
            • in the case of personal data that we did not obtain directly from
            our Users, the source and specific categories of that data;
          </li>
          <li>• the purposes for which we may process personal data;</li>
          <li>• the legal bases of the processing.</li>
        </ul>
        <br />
        All Personal Data that is collected through the use of the website is
        stored on secure servers in Canada.
        <br />
        <br />
        Upon acquisition of our services, we will ask for your full name and a
        valid e-mail address, which will be included in our mailing list, for
        future maintenance, updates, and eventual marketing promotions. In
        addition, we will ask you to provide us with secure payment data, which
        will be used for processing the order. This particular set of data will
        be legally processed by our payment processor, and will not be stored by
        us.
        <br />
        <br />
        We may process data about your use of our website and services, or the
        User’s website ("usage data"). The usage data may include your IP
        address, geographical location, browser type, and version, operating
        system, referral source, length of visit, page views and website
        navigation paths, as well as information about the timing, frequency and
        pattern of your service use. The source of the usage data is our
        analytics tracking system. This usage data may be processed for the
        purposes of analyzing the use of the website and services.
        <br />
        <br />
        We may process your personal data that are provided in the course of the
        use of our services ("service data"). The service data may be processed
        for the purposes of providing our services, ensuring the security of our
        website and services, maintaining back-ups of our databases and
        communicating with you.
        <br />
        <br />
        We may process information contained in any inquiry you submit to us
        regarding services and/or services ("inquiry data"). The inquiry data
        may be processed for the purposes of providing our services, ensuring
        the security of our website and services, maintaining back-ups of our
        databases and communicating with you.
        <br />
        <br />
        We may process information relating to our User relationships, including
        User contact information ("User relationship data"). The User
        relationship data may include your name, your employer, your job title
        or role, all your contact details, and information contained in
        communications between us and you or your employer. The User
        relationship data may be processed for the purposes of providing our
        services, ensuring the security of our website and services, maintaining
        back-ups of our databases and communicating with you.
        <br />
        <br />
        We may process information relating to transactions, including purchases
        of goods and services, that you enter into with us and/or through our
        services ("transaction data"). The transaction data may include your
        contact details and the transaction details. The transaction data may be
        processed for the purposes of providing our services, ensuring the
        security of our website and services, maintaining back-ups of our
        databases and communicating with you.
        <br />
        <br />
        We may process information contained in or relating to any communication
        that you send to us ("correspondence data"). The correspondence data may
        include the communication content and metadata associated with the
        communication. Our website will generate the metadata associated with
        communications made using the website contact forms. The correspondence
        data may be processed for the purposes of providing our services,
        ensuring the security of our website and services, maintaining back-ups
        of our databases and communicating with you.
        <br />
        <br />
        “Automatically Collected" Information: The website collects a series of
        general data and information when a data subject or automated system
        calls up the website. This general data and information are stored in
        the server log files, and it is collected regardless of your quality:
        Website Visitor or User.
        <br />
        <br />
        <b>Note:</b> no personal information is collected through the service
        (plugin) itself. We only collects purchase codes, website URL, plugin
        version and WordPress version strictly for providing services.
        <br />
        <br />
        Collected may be:
        <br />
        <br />
        <ul>
          <li>• the ISP,</li>
          <li>• the operating system used by the accessing system</li>
          <li>
            • the website from which an accessing system reaches our website
            (so-called referrers)
          </li>
          <li>• the sub-website</li>
          <li>• the date and time of access to the website</li>
          <li>• an Internet Protocol address (IP address)</li>
          <li>• screen Resolution</li>
          <li>• locale Preferences</li>
          <li>• web page visited before you came to our website</li>
          <li>• information you search for on our website</li>
          <li>• date and time stamps associated with transactions</li>
          <li>
            • system configuration information and other interactions with the
            website.
          </li>
          <li>
            • social networking information (if we are provided with access to
            your account on social network connection services);
          </li>
          <li>
            • any other similar data and information that may be used in the
            event of attacks on our information technology systems.
          </li>
        </ul>
        <br />
        When using these general data and information, we do not draw any
        conclusions about the data subject. Rather, this information is needed
        to:
        <br />
        <br />
        <ul>
          <li>• deliver the content of our website correctly;</li>
          <li>
            • optimize the content of our website as well as its advertisement;
          </li>
          <li>
            • ensure the long-term viability of our information technology
            systems and website technology;
          </li>
          <li>
            • provide law enforcement authorities with the information necessary
            for criminal prosecution in case of a cyber-attack;
          </li>
        </ul>
        <br />
        In addition to the specific purposes for which we may process your
        personal data set out in this Section, we may also process any of your
        personal data where such processing is necessary for compliance with a
        legal obligation to which we are subject, or in order to protect your
        vital interests or the vital interests of another natural person.
        <br />
        <br />
        Please do not supply any other person's personal data to us, unless we
        prompt you to do so.
        <br />
        <br />
        Obviously, the access to our website for website visitors is free;
        however, we inform you that for the use of the website via mobile device
        the charges and the standard tariffs provided in the service contract
        that you have stipulated with them will still be applied by the
        telephone operators.
      </p>

      <h3 className="sub-section-headline">
        <u>How we use your personal information</u>
      </h3>
      <p className="body-text">
        <br />
        We use the Order Information that we collect generally to fulfill any
        orders placed through the website (including processing your payment
        information, arranging for shipping, and providing you with invoices
        and/or order confirmations).
        <br />
        <br />
        Additionally, we use the information:
        <br />
        <br />
        <ul>
          <li>- to screen our orders for potential risk or fraud;</li>
          <li>
            - when in line with the preferences you have shared with us, provide
            you with information or advertising relating to our products or
            services;
          </li>
          <li>
            - communicate with you about our Services, including to tell you
            about products and services that may be of interest to you
          </li>
          <li>
            - respond to your requests, inquiries, comments, and suggestions;
          </li>
          <li>
            - facilitate your engagement with the Services, including to enable
            you to post comments and reviews;
          </li>
          <li>- offer contests, sweepstakes, or other promotions;</li>
          <li>- conduct or administer surveys and other market research;</li>
          <li>
            - protect against, identify, investigate, and respond to fraud or
            other illegal activity.
          </li>
        </ul>
        <br />
        We use the Device Information that we collect to help us screen for
        potential risk and fraud (in particular, your IP address), and more
        generally to improve and optimize our website (for example, by
        generating analytics about how our customers browse and interact with
        the website, and to assess the success of our marketing and advertising
        campaigns).
      </p>

      <h3 className="sub-section-headline">
        <u>Use and Disclosure of personal data to others</u>
      </h3>
      <p className="body-text">
        <br />
        We do not rent or sell your information to third parties outside of us
        without your consent. We may share your information, as well as
        information from tools such as cookies or similar, with third-party
        organizations that help us provide you with the services, but only as
        far as is reasonably necessary.
        <br />
        <br />
        Compliance with Laws and Law Enforcement Requests: We may disclose to
        parties outside us, files stored in our database and personal data about
        you that we collect when we have a good faith belief that disclosure is
        reasonably necessary to (a) comply with a law, regulation or compulsory
        legal request; or (b) to protect our intellectual property rights. If we
        provide your Files to a law enforcement agency as set forth above, we
        will remove our encryption from the files before providing them to law
        enforcement.
        <br />
        <br />
        Business Transfers: In case we are involved in a merger, acquisition, or
        sale of all or a portion of our assets, your personal data may be
        transferred as part of that transaction, but we will notify you and/or
        your organization (for example, via email and/or a prominent notice on
        our website) of any change in control or use of your personal data or
        Files, or if either become subject to a different Privacy Policy.
        <br />
        <br />
        In addition to the specific disclosures of personal data set out in this
        Section, we may disclose your personal data where such disclosure is
        necessary for compliance with a legal obligation to which we are
        subject, or in order to protect your vital interests or the vital
        interests of another natural person. We may also disclose your personal
        data where such disclosure is necessary for the establishment, exercise
        or defense of legal claims, whether in court proceedings or in an
        administrative or out-of-court procedure.
        <br />
        <br />
        We share your Personal Information with third parties to help us use
        your Personal Information, as described above. For example, we use
        Shopify to power our online store--you can read more about how Shopify
        uses your Personal Information here:
        https://www.shopify.com/legal/privacy. We also use Google Analytics to
        help us understand how our customers use the Site--you can read more
        about how Google uses your Personal Information here:
        https://www.google.com/intl/en/policies/privacy/. You can also opt-out
        of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
        <br />
        <br />
        We can access your information as well as store and share it in response
        to a request when the law requires us to do so. In addition, we may
        access your information, as well as store and share it, if we believe in
        good faith that this is necessary to: detect, prevent and manage fraud
        and other illegal activities; protect ourselves, you and other people,
        even in the context of any investigations; prevent events that could
        cause imminent physical damage or death
      </p>

      <h3 className="sub-section-headline">
        <u>Behavioural advertising</u>
      </h3>
      <p className="body-text">
        <br />
        As described above, we use your Personal Information to provide you with
        targeted advertisements or marketing communications we believe may be of
        interest to you. For more information about how targeted advertising
        works, you can visit the Network Advertising Initiative’s (“NAI”)
        educational page at
        http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
        <br />
        <br />
        You can opt out of targeted advertising by visiting the following links
        depending upon the service(s) you are using:
        <br />
        <br />
        <ul>
          <li>FACEBOOK - https://www.facebook.com/settings/?tab=ads</li>
          <li>GOOGLE - https://www.google.com/settings/ads/anonymous</li>
          <li>
            BING -
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          </li>
        </ul>
        <br />
        For information on how our advertising partners allow you to opt out of
        receiving ads based on your web browsing history, please visit:
        http://optout.aboutads.info/.
        <br />
        <br />
        European users may opt out of receiving targeted advertising through the
        European Interactive Digital Advertising Alliance.
        <br />
        <br />
        Additionally, you can opt out of some of these services by visiting the
        Digital Advertising Alliance’s opt-out portal at:{" "}
        <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>.
      </p>

      <h3 className="sub-section-headline">
        <u>Links to Other Websites</u>
      </h3>
      <p className="body-text">
        <br />
        The Platform may, from time to time, contain links to and from the
        Platforms of advertisers and affiliates. The fact that our links to a
        website is not an endorsement, authorization or representation of our
        affiliation with that third party. We does not exercise control over
        third party websites. These other websites may place their own cookies
        or other files on the User’s device, collect data or solicit personally
        identifiable information from the User. If the User follows a link to
        any of these third-party websites, he does so at his own risk, these
        Applications have their own privacy policies and he waives any claim
        against us for any responsibility or liability for these policies.
      </p>

      <h3 className="sub-section-headline">
        <u>Personal Data Breaches</u>
      </h3>
      <p className="body-text">
        <br />
        Sub-processor will notify the Controller as soon as possible after it
        becomes aware of any Personal Data Breach affecting any Personal Data.
        At the Controller’s request, Sub-processor will promptly provide the
        Controller with all reasonable assistance necessary to enable the
        Controller to notify relevant Personal Data Breaches to competent
        authorities and/or affected Data Subjects, if Controller is required to
        do so under the Data Protection Law.
      </p>

      <h3 className="sub-section-headline">
        <u>Data Subject Requests</u>
      </h3>
      <p className="body-text">
        <br />
        If it is the case, sub-processor will provide reasonable assistance,
        including by appropriate technical and organizational measures and
        taking into account the nature of the Processing, to enable Controller
        to respond to any request from Data Subjects seeking to exercise their
        rights under the Data Protection Law with respect to Personal Data
        (including access, rectification, restriction, deletion or portability
        of Personal Data, as applicable), to the extent permitted by the law. If
        such request is made directly to sub-processor, it will promptly inform
        Controller and will advise Data Subjects to submit their request to the
        Controller. The controller shall be solely responsible for responding to
        any Data Subjects’ requests. The controller shall reimburse
        sub-processor for any potential costs arising from this assistance.
      </p>

      <h3 className="sub-section-headline">
        <u>Privacy of minors</u>
      </h3>
      <p className="body-text">
        <br />
        We do not collect or consciously request information from Users under
        the age of 18, nor do we consciously allow such Users to register with
        our services, except with parental consent. Our services and their
        contents are not intended for children under the age of 18.
        <br />
        <br />
        If we learn that we have collected personal information from a child
        under the age of 18 without parental consent, we will delete this
        information as soon as possible.
        <br />
        <br />
        If you believe we have any information about a child under the age of
        18, please contact us.
      </p>

      <h3 className="sub-section-headline">
        <u>Cookies and Tracking Technologies</u>
      </h3>
      <p className="body-text">
        <br />
        Many cookies contain a so-called cookie ID. A cookie ID is a unique
        identifier of the cookie. It consists of a character string through
        which internet pages, servers or apps can be assigned to the specific
        app or server in which the cookie was stored.
        <br />
        <br />
        Through the use of cookies, we can provide the Users of this website
        with more User-friendly services that would not be possible without the
        cookie setting.
        <br />
        <br />
        By means of a cookie, the information and offers on our websites can be
        optimized with the User in mind. Cookies allow us, as previously
        mentioned, to recognize our website Users. The purpose of this
        recognition is to make it easier for Users to utilize our websites. The
        website's User that uses cookies e.g. does not have to enter access data
        each time the website is accessed, because this is taken over by the
        websites, and the cookie is thus stored on the User's computer system.
        <br />
        <br />
        We may use “session ID cookies” to enable certain features of the
        Service, to better understand how you interact with the Service and to
        monitor aggregate usage and web traffic routing on the Service. You can
        instruct your browser, by changing its options, to stop accepting
        cookies or to prompt you before accepting a cookie from the websites you
        visit. If you do not accept cookies, however, you may not be able to use
        all aspects of the Application.
        <br />
        <br />
        Some web browsers may transmit “do-no-track” signals to websites with
        which the browser communicates.
        <br />
        <br />
        Our website does not currently respond to these “do-not-track” signals.
        <br />
        <br />
        <b>Strictly necessary cookies</b>
        <br />
        <br />
        These cookies are essential, as they enable you to move around the Site
        and use its features, such as accessing secure areas. Without these
        cookies, some services you have asked for, such as payment submission,
        can’t be provided.
        <br />
        <br />
        <b>Performance cookies</b>
        <br />
        <br />
        These cookies collect information about how you use the website, for
        example, which pages you go to most often and if you get error messages
        from certain pages. These cookies don’t gather the information that
        identifies you. All information these cookies collect is anonymous and
        is only used to improve how the Site works.
        <br />
        <br />
        <b>Functionality cookies</b>
        <br />
        <br />
        These cookies allow the Site to remember choices you make (such as your
        user name, language or the region you’re in). For instance, the Site
        uses functionality cookies to remember your language preference. These
        cookies can also be used to remember changes you’ve made to text size,
        font and other parts of pages that you can customize, or any kind of
        form that you left in between or unsubmitted. They may also be used to
        provide services you’ve asked for such as watching a video or commenting
        on a blog. The information these cookies collect may be anonymous and
        they cannot track your browsing activity on other websites.
        <br />
        <br />
        <b>Targeting cookies</b>
        <br />
        <br />
        These cookies are used to deliver advertisements that are more relevant
        to you and your interests. They are also used to limit the number of
        times you see an advertisement as well as help measure the effectiveness
        of an advertising campaign. They remember that you have visited a
        website and this information may be shared with other organizations such
        as advertisers. This means after you have been to the Site you may see
        some advertisements about our services elsewhere on the Internet.
        <br />
        <br />
        The data subject may, at any time, prevent the setting of cookies
        through our website by means of a corresponding setting of the browser
        or app, and may thus permanently deny the setting of cookies.
        Furthermore, already set cookies may be deleted at any time. If the user
        or the visitor deactivates the setting of cookies in the browser or app,
        then not all functions may be entirely usable.
        <br />
        <br />
        We use automatically collected information and other information
        collected during the Services through cookies and similar technologies
        to:
        <br />
        <br />
        <ul>
          <li>
            (i) personalize our Service, such as remembering a User’s or
            Visitor’s information or login details so that the User or Visitor
            will not have to re-enter it during a visit or on subsequent
            accessing;
          </li>
          <li>(ii) provide customized content and information;</li>
          <li>
            (iii) monitor and analyze the effectiveness of websites and
            third-party activities;
          </li>
          <li>
            (iv) monitor site usage metrics such as the number of visitors.
          </li>
        </ul>
      </p>

      <h3 className="sub-section-headline">
        <u>Data Quality</u>
      </h3>
      <p className="body-text">
        <br />
        We will take reasonable steps to ensure that personal information we
        have about you is accurate, complete and up to date when we use it.
        Generally, we rely on you to assist us in keeping your personal
        information accurate and up to date.
      </p>

      <h3 className="sub-section-headline">
        <u>Data Security</u>
      </h3>
      <p className="body-text">
        <br />
        We have taken reasonable steps to keep your personal information secure
        at all times and in accordance with our Information Security Policies.
        <br />
        <br />
        We also take steps to reasonably protect your personal information from
        misuse and loss, unauthorized access, modification or disclosure and
        maintained in an accurate, complete and up-to-date manner.
      </p>

      <h3 className="sub-section-headline">
        <u>Openness</u>
      </h3>
      <p className="body-text">
        <br />
        We will be honest and open with you about the type of personal
        information that we collect about you and the actual use of any such
        information. We will let you know at the time we collect your personal
        information, or soon after, how we will treat it.
        <br />
        <br />
        If you require any details of the personal information held by us about
        you, then please contact us by e-mail.
      </p>

      <h3 className="sub-section-headline">
        <u>Opting out from Communications</u>
      </h3>
      <p className="body-text">
        <br />
        If you receive emails from us, you may unsubscribe at any time by
        following the instructions contained within the email or by sending an
        email to the address provided in the “Contact” section.
        <br />
        <br />
        Please be aware that if you opt-out of receiving emails from us or
        otherwise modify the nature or frequency of promotional communications
        you receive from us, it may take up to ten (10) business days for us to
        process your request. Please note that you may still receive
        administrative messages from us regarding our Services.
      </p>

      <h3 className="sub-section-headline">
        <u>Sensitive Information</u>
      </h3>
      <p className="body-text">
        <br />
        We do not believe in an intrusive collection of your personal details
        and will not collect information that is considered highly personal or
        highly sensitive about you without your prior consent.
      </p>

      <h3 className="sub-section-headline">
        <u>Data Retention and Data Deletion</u>
      </h3>
      <p className="body-text">
        <br />
        We only retain the personal information collected from a User for as
        long as the User’s email address is active on our mailing list, or
        otherwise for a limited period of time as long as we need it to fulfill
        the purposes for which we have initially collected it, unless otherwise
        required by law.
        <br />
        <br />
        In some cases, it is not possible for us to specify in advance the
        periods for which your personal data will be retained. In such cases, we
        will determine the period of retention based on the 2-year criteria,
        after your relationship with us ends.
        <br />
        <br />
        We may retain your personal data where such retention is necessary for
        compliance with a legal obligation to which we are subject, or in order
        to protect your vital interests or the vital interests of another
        natural person.
      </p>

      <h3 className="sub-section-headline">
        <u>Your rights</u>
      </h3>
      <p className="body-text">
        <br />
        Some of the rights are complex, and not all of the details have been
        included in our summaries. Accordingly, you should read the relevant
        laws and guidance from the regulatory authorities for a full explanation
        of these rights.
        <br />
        <br />
        Your principal rights under data protection law are:
        <br />
        <br />
        <ul>
          <li>(a) the right to access;</li>
          <li>(b) the right to rectification;</li>
          <li>(c) the right to erasure;</li>
          <li>(d) the right to restrict processing;</li>
          <li>(e) the right to object to processing;</li>
          <li>(f) the right to data portability;</li>
          <li>(g) the right to complain to a supervisory authority; and</li>
          <li>(h) the right to withdraw consent.</li>
        </ul>
        <br />
        You have the right to confirmation as to whether or not we process your
        personal data and, where we do, access to the personal data, together
        with certain additional information. That additional information
        includes details of the purposes of the processing, the categories of
        personal data concerned and the recipients of the personal data.
        Providing the rights and freedoms of others are not affected, we will
        supply you a copy of your personal data. The first copy will be provided
        free of charge, but additional copies may be subject to a reasonable
        fee.
        <br />
        <br />
        You have the right to have any inaccurate personal data about you
        rectified and, considering the purposes of the processing, to have any
        incomplete personal data about you completed.
        <br />
        <br />
        In some circumstances, you have the right to the erasure of your
        personal data without undue delay. Those circumstances include: the
        personal data are no longer necessary in relation to the purposes for
        which they were collected or otherwise processed; you withdraw consent
        to consent-based processing; you object to the processing under certain
        rules of applicable data protection law; the processing is for direct
        marketing purposes, and the personal data have been unlawfully
        processed. However, there are exclusions of the right to erasure. The
        general exclusions include where processing is necessary: for exercising
        the right of freedom of expression and information; for compliance with
        a legal obligation; or for the establishment, exercise or defense of
        legal claims.
        <br />
        <br />
        In some circumstances, you have the right to restrict the processing of
        your personal data. Those circumstances are: you contest the accuracy of
        the personal data; processing is unlawful but you oppose erasure; we no
        longer need the personal data for the purposes of our processing, but
        you require personal data for the establishment, exercise or defense of
        legal claims; and you have objected to processing, pending the
        verification of that objection. Where processing has been restricted on
        this basis, we may continue to store your personal data. However, we
        will only otherwise process it: with your consent; for the
        establishment, exercise or defense of legal claims; for the protection
        of the rights of another natural or legal person; or for reasons of
        important public interest.
        <br />
        <br />
        You have the right to object to our processing of your personal data on
        grounds relating to your particular situation, but only to the extent
        that the legal basis for the processing is that the processing is
        necessary for: the performance of a task carried out in the public
        interest or in the exercise of any official authority vested in us; or
        the purposes of the legitimate interests pursued by us or by a third
        party. If you make such an objection, we will cease to process the
        personal information unless we can demonstrate compelling legitimate
        grounds for the processing which override your interests, rights, and
        freedoms, or the processing is for the establishment, exercise or
        defense of legal claims.
        <br />
        <br />
        You have the right to object to our processing of your personal data for
        direct marketing purposes (including profiling for direct marketing
        purposes). If you make such an objection, we will cease to process your
        personal data for this purpose.
        <br />
        <br />
        You have the right to object to our processing of your personal data for
        scientific or historical research purposes or statistical purposes on
        grounds relating to your particular situation unless the processing is
        necessary for the performance of a task carried out for reasons of
        public interest.
        <br />
        <br />
        If you consider that our processing of your personal information
        infringes data protection laws, you have a legal right to lodge a
        complaint with a supervisory authority responsible for data protection.
        You may do so in the EU member state of your habitual residence, your
        place of work or the place of the alleged infringement.
        <br />
        <br />
        To the extent that the legal basis for our processing of your personal
        information is consent, you have the right to withdraw that consent at
        any time. Withdrawal will not affect the lawfulness of processing before
        the withdrawal.
        <br />
        <br />
        You may exercise any of your rights in relation to your personal data by
        written notice to us.
      </p>

      <h3 className="sub-section-headline">
        <u>Complaints Resolution</u>
      </h3>
      <p className="body-text">
        <br />
        We are committed to providing our Users with a fair and responsive
        system for handling and resolving complaints concerning the handling of
        their personal information. You have a right to complain and to have
        your complaint handled efficiently if you are concerned about our
        handling of your personal information.
        <br />
        <br />
        If at any time you wish to lodge a complaint in respect of the handling,
        use or disclosure of your personal information by us, you may do so by
        contacting us directly.
        <br />
        <br />
        We aim to investigate and advise you of the outcome of the complaint
        promptly.
        <br />
        <br />
        If you are not satisfied with our handling of your complaint, you may
        contact the us at:
        <br />
        <br />
        admin@altninjas.com
      </p>

      <h3 className="sub-section-headline">
        <u>Amendments</u>
      </h3>
      <p className="body-text">
        <br />
        We may update this policy from time to time by publishing a new version
        on our websites.
        <br />
        <br />
        You should check this page occasionally to ensure you are happy with any
        changes to this policy.
        <br />
        <br />
        We may notify you of significant changes to this policy by email, as a
        website notice or through the private messaging system within our
        service system.
      </p>
    </section>
  </main>
);

export default PrivacyPolicyPage;
