import { ContactFormTypes } from './contact-form.types';
import { SnackbarTypes } from '../Snackbar/snackbar.types';
import { Urls, postRequest } from '../../api';

export const sendContactUsEmail = formData => dispatch => {
  dispatch({ type: ContactFormTypes.SEND_MESSAGE_PENDING });
  postRequest(Urls.server, '/contact', formData)
    .then(data => {
      dispatch({ type: ContactFormTypes.SEND_MESSAGE_SUCCESS });
      dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
    })
    .catch(error => {
      if (error.response.data.snackbar) {
        dispatch({ type: ContactFormTypes.SEND_MESSAGE_FAILED, payload: null });
        dispatch({
          type: SnackbarTypes.OPEN_SNACKBAR,
          payload: error.response.data.snackbar,
        });
      } else {
        dispatch({
          type: ContactFormTypes.SEND_MESSAGE_FAILED,
          payload: error.response.data,
        });
      }
    });
};

export const resetContactForm = () => dispatch =>
  dispatch({ type: ContactFormTypes.RESET_CONTACT_FORM });
