import React, {useEffect, useState} from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

// For reference to how this component was constructed visit this url: https://material-ui.com/components/autocomplete/

import { tradePairArray } from '../seedData';

import './AutoCompleteInput.scss';
import {toggleDashMenuFixed} from "../../../redux/DashMenu/dash-menu.actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getSymbols} from "../../../redux/Symbols/symbols.actions";

const suggestions = tradePairArray.map(pair => ({ label: pair }));

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
      }}
      {...other}
    />
  );
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

function AutoCompleteInput({
  handleChange,
  handleClick,
  value,
  helperText,
  error,
  classNames,
  getSymbols,
  symbolList,
}) {
    useEffect(() => {
        getSymbols();
    }, []);

  const [stateSuggestions, setSuggestions] = useState([]);

    function getSuggestions(value) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0
            ? []
            : symbolList.filter(suggestion => {
                const keep =
                    count < 5 &&
                    suggestion.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    }

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    // const matches = match(suggestion.label, query);
    const matches = match(suggestion, query);
    // const parts = parse(suggestion.label, matches);
    const parts = parse(suggestion, matches);

    return (
      <MenuItem
        onClick={() => handleClick(parts)}
        selected={isHighlighted}
        component="div"
      >
        <div>
          {parts.map(part => (
            <span
              key={part.text}
              className={part.highlight ? 'auto-complete-input__highlight' : ''}
            >
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          id: 'trade-interface-symbol-input',
          label: 'symbol',
          placeholder: 'search a trade pair',
          value: value,
          onChange: handleChange,
          name: 'symbol',
          variant: 'outlined',
          helperText,
          error,
          classNames,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}

const mapStateToProps = ({symbols: {symbolList}}) => ({
    symbolList: symbolList.map(v => v.withSlash)
});

const mapDispatchToProps = dispatch => ({
    getSymbols: () => dispatch(getSymbols())
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteInput);
