import { Urls, postRequest } from "../../api";
import { LoginTypes } from "./login.types";
import { SnackbarTypes } from "../Snackbar/snackbar.types";
import { loadUser } from "../User/user.actions";

export const loginUser = formData => dispatch => {
  dispatch({ type: LoginTypes.LOGIN_USER_PENDING });
  postRequest(Urls.server, "/login", formData)
    .then(() => {
      dispatch(loadUser());
      dispatch({ type: LoginTypes.LOGIN_USER_SUCCESS });
    })
    .catch(error => {
      if (error.response.data.snackbar) {
        dispatch({
          type: SnackbarTypes.OPEN_SNACKBAR,
          payload: error.response.data.snackbar
        });
        dispatch({ type: LoginTypes.LOGIN_USER_FAILED, payload: null });
      } else {
        dispatch({
          type: LoginTypes.LOGIN_USER_FAILED,
          payload: error.response.data
        });
      }
    });
};
