import { SettingsTypes } from "./settings.types";

const INITIAL_STATE = {
  isPending: false,
  binanceCredentialsPending: false,
  updateNotificationsPending: false,
  submitTradeProfilePending: false,
  changePasswordPending: false,
  updateBlacklistPending: false,
  settings: null,
  tradeProfileSubmitSuccess: false,
  errors: null
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SettingsTypes.GET_SETTINGS_PENDING:
      return { ...state, isPending: true, errors: null };

    case SettingsTypes.GET_SETTINGS_SUCCESS:
      return { ...state, isPending: false, settings: action.payload };

    case SettingsTypes.GET_SETTINGS_FAILED:
      return { ...state, isPending: false };

    case SettingsTypes.SUBMIT_TRADE_PROFILE_PENDING:
      return { ...state, submitTradeProfilePending: true, errors: null };

    case SettingsTypes.SUBMIT_TRADE_PROFILE_SUCCESS:
      return {
        ...state,
        submitTradeProfilePending: false,
        tradeProfileSubmitSuccess: true,
        settings: {
          ...state.settings,
          tradeProfile: action.payload.tradeProfile
        }
      };
    case SettingsTypes.SUBMIT_TRADE_PROFILE_FAILED:
      return {
        ...state,
        submitTradeProfilePending: false,
        errors: action.payload
      };

    case SettingsTypes.CHANGE_PASSWORD_PENDING:
      return { ...state, changePasswordPending: true, errors: null };

    case SettingsTypes.CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordPending: false };

    case SettingsTypes.CHANGE_PASSWORD_FAILED:
      return { ...state, changePasswordPending: false, errors: action.payload };

    case SettingsTypes.UPDATE_COIN_BLACKLIST_PENDING:
      return { ...state, updateBlacklistPending: true, errors: null };

    case SettingsTypes.UPDATE_COIN_BLACKLIST_SUCCESS:
      return {
        ...state,
        updateBlacklistPending: false,
        settings: {
          ...state.settings,
          coinBlacklist: action.payload.coinBlacklist
        }
      };

    case SettingsTypes.UPDATE_COIN_BLACKLIST_FAILED:
      return { ...state, updateBlacklistPending: false, errors: null };

    case SettingsTypes.UPDATE_NOTIFICATIONS_PENDING:
      return { ...state, updateNotificationsPending: true };

    case SettingsTypes.UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        updateNotificationsPending: false,
        settings: {
          ...state.settings,
          notifications: action.payload.notifications
        }
      };

    case SettingsTypes.UPDATE_NOTIFICATIONS_FAILED:
      return { ...state, updateNotificationsPending: false };

    case SettingsTypes.UPDATE_ACCOUNT_STATUS_PENDING:
      return { ...state, isPending: true, errors: null };

    case SettingsTypes.UPDATE_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        isPending: false,
        settings: {
          ...state.settings,
          accountStatus: {
            ...state.accountStatus,
            activated: action.payload.activatedStatus
          }
        }
      };

    case SettingsTypes.UPDATE_ACCOUNT_STATUS_FAILED:
      return { ...state, isPending: false, errors: action.payload };

    case SettingsTypes.EDIT_BINANCE_CREDENTIALS_PENDING:
      return { ...state, binanceCredentialsPending: true, errors: null };

    case SettingsTypes.EDIT_BINANCE_CREDENTIALS_SUCCESS:
      return {
        ...state,
        binanceCredentialsPending: false,
        errors: null,
        settings: {
          ...state.settings,
          credentialsComplete: action.payload.credentialsComplete
        }
      };

    case SettingsTypes.EDIT_BINANCE_CREDENTIALS_FAILED:
      return {
        ...state,
        binanceCredentialsPending: false,
        errors: action.payload
      };

    default:
      return state;
  }
};

export default settingsReducer;
