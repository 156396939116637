import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import CircularLoader from '../../../components/CircularLoader';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import { recoverUsername } from '../../../redux/Recovery/recovery.actions';
import { clearErrors } from '../../../redux/ClearErrors/clear-errors.actions';

import './AuthForm.scss';

const RecoverUsernameForm = ({
  isPending,
  errors,
  clearErrors,
  recoverUsername,
}) => {
  const INITIAL_STATE = {
    email: '',
  };

  const [fieldInputs, setFieldInputs] = useState(INITIAL_STATE);

  const handleFormSubmit = e => {
    e.preventDefault();
    recoverUsername(fieldInputs);
    clearInputs();
  };

  const clearInputs = () => {
    setFieldInputs(INITIAL_STATE);
  };

  const handleInputChange = e => {
    e.persist();
    setFieldInputs(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const loadingClass = isPending ? 'loading' : '';

  return (
    <div className={`auth-form ${loadingClass}`}>
      {isPending ? (
        <CircularLoader classNames={loadingClass} size={100} />
      ) : null}
      <h2 className="sub-section-headline sub-section-headline--white">
        Recover Username
      </h2>

      <form onSubmit={handleFormSubmit} className="auth-form__form">
        <TextInput
          id="email"
          type="email"
          name="email"
          variant="outlined"
          label="email"
          fullWidth={true}
          borderColor="white"
          value={fieldInputs.email}
          onChange={handleInputChange}
          helperText={errors ? errors.email : ''}
          error={errors ? errors.email || false : false}
        />
        <Button type="solid" value="recover" element="input" header={false} />
      </form>

      <div className="auth-form__links-container">
        <span>
          <p className="auth-form__text">
            I would like to{' '}
            <Link
              onClick={clearErrors}
              className="auth-form__link"
              to="/accounts/login"
            >
              Login
            </Link>
          </p>
        </span>
      </div>
    </div>
  );
};

RecoverUsernameForm.propTypes = {};

const mapDispatchToProps = dispatch => ({
  recoverUsername: formData => dispatch(recoverUsername(formData)),
  clearErrors: () => dispatch(clearErrors()),
});

const mapStateToProps = ({ recovery: { isPending, errors } }) => ({
  isPending,
  errors,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecoverUsernameForm);
