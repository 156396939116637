import React from 'react';
import { connect } from 'react-redux';

import ErrorPage from '../../scenes/ErrorPage';
import { setAppHasError } from '../../redux/AppError/app-error.actions';

class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      hasErrored: false,
    };
  }

  static getDerivedStateFromError(error) {
    // process error

    return { hasErrored: true };
  }

  componentDidCatch(error, info) {
    // side effects witherror
    this.props.setAppHasError(this.state.hasErrored);
    console.error(error);
  }

  render() {
    if (this.state.hasErrored) return <ErrorPage />;
    else return this.props.children;
  }
}

const mapDispatchToProps = dispatch => ({
  setAppHasError: hasError => dispatch(setAppHasError(hasError)),
});

export default connect(
  null,
  mapDispatchToProps,
)(ErrorBoundary);
