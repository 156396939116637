import { SignalModalTypes } from './signal-modal.types';
import { SnackbarTypes } from '../Snackbar/snackbar.types';
import { Urls, postRequest } from '../../api';

export const openSignalModal = signalData => dispatch => {
  dispatch({ type: SignalModalTypes.SIGNAL_MODAL_OPEN, payload: signalData });
};

export const closeSignalModal = () => dispatch => {
  dispatch({ type: SignalModalTypes.SIGNAL_MODAL_CLOSE });
};

export const cancelSignal = signalData => dispatch => {
  dispatch({ type: SignalModalTypes.SIGNAL_MODAL_CANCEL_SIGNAL_PENDING });

  postRequest(Urls.server, '/binance/cancel', signalData)
    .then(data => {
      dispatch({ type: SignalModalTypes.SIGNAL_MODAL_CANCEL_SIGNAL_FAILED });
      dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
      dispatch({ type: SignalModalTypes.SIGNAL_MODAL_CLOSE });
    })
    .catch(error => {
      dispatch({ type: SignalModalTypes.SIGNAL_MODAL_CANCEL_SIGNAL_FAILED });
      dispatch({
        type: SnackbarTypes.OPEN_SNACKBAR,
        payload: error.response.data.snackbar,
      });
      dispatch({ type: SignalModalTypes.SIGNAL_MODAL_CLOSE });
    });
};
