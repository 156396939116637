import {InvoicesTypes} from "./invoices.types";

const INITIAL_STATE = {
    isPending: false,
    invoiceList: [],
    page: null,
    nextPage: null,
    prevPage: null,
    totalPages: null,
    currentFilter: {}, // not needed yet as the invoices table doesn't allow filtering at the moment (but the api does take parameters, different based on where used - the assign modal for example)
    lowerDisplay: null,
    upperDisplay: null,
    totalInvoices: null,
};

const InvoicesReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case InvoicesTypes.INVOICES_PENDING:
            return {...state, isPending: true};

        case InvoicesTypes.INVOICES_SUCCESS:
            const {invoices, pagination, currentFilter} = action.payload;
            return {
                ...state,
                isPending: false,
                invoiceList: invoices,
                page: pagination ? Number(pagination.page) : 0,
                totalPages: pagination ? Number(pagination.totalPages) : 0,
                // currentFilter,
                lowerDisplay: pagination ? pagination.lowerDisplay : 0,
                upperDisplay: pagination ? pagination.upperDisplay : 0,
                totalInvoices: pagination ? pagination.totalInvoices : 0,
            };

        case InvoicesTypes.INVOICES_FAILED:
            return {...state, isPending: false};

        default:
            return state;
    }
};

export default InvoicesReducer;
