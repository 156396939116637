import {withStyles} from "@material-ui/core/styles";
import {Slider} from "@material-ui/core";

const AltCoinSlider = withStyles({
    root: {
        color: "#f3ad61",
        height: 8,
    },
    mark: {
        width: 7,
        height: 7,
        borderRadius: "50%",
        top: 'calc(50% - 7px)',
        marginLeft: -3,
    },
    markLabel: {
    },
    markActive: {
        width: 7,
        height: 7,
        borderRadius: "50%",
        top: 'calc(50% - 7px)',
        marginLeft: -3,
        backgroundColor: "#f3ad61",
    },
    thumb: {
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    valueLabel: {
        left: 'calc(-50% - 8px)',
        fontSize: 11,
    },
})(Slider);

export default AltCoinSlider;
