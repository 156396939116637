import React, { useState, useRef, useEffect } from "react";
import ReCaptcha from "react-google-recaptcha";
import { connect } from "react-redux";

import MessagePending from "./MessagePending.component";
import MessageSuccess from "./MessageSuccess.component";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import PageTitle from "../../components/PageTitle";
import RegisterCTA from "../../components/RegisterCTA";
import {
  sendContactUsEmail,
  resetContactForm,
} from "../../redux/ContactForm/contact-form.actions";
import { clearErrors } from "../../redux/ClearErrors/clear-errors.actions";
import { openSnackbar } from "../../redux/Snackbar/snackbar.actions";
import site_keys from "../../site_keys";

import "./ContactUs.scss";

const INITIAL_STATE = {
  name: "",
  email: "",
  message: "",
  reCaptchaToken: "",
};

const ContactUs = ({
  isPending,
  didSucceed,
  errors,
  sendContactUsEmail,
  clearErrors,
  resetContactForm,
}) => {
  let reCaptchaRef = useRef();
  const [fieldInputs, setFieldInputs] = useState(INITIAL_STATE);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { reCaptchaToken } = fieldInputs;
    if (!reCaptchaToken) {
      reCaptchaRef.current.reset();
    }

    sendContactUsEmail(fieldInputs);
    setFieldInputs(INITIAL_STATE);
  };

  const handleInputChange = (e) => {
    e.persist();
    setFieldInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCaptchaChange = (reCaptchaToken) => {
    setFieldInputs((prevState) => ({ ...prevState, reCaptchaToken }));
  };

  useEffect(() => {
    reCaptchaRef.current.reset();
    reCaptchaRef.current.execute();
  }, []);

  useEffect(() => {
    resetContactForm();

    window.addEventListener("click", clearErrors);

    return () => {
      window.addEventListener("click", clearErrors);
    };
  }, [clearErrors, resetContactForm]);

  return (
    <main className="contact-us">
      <ReCaptcha
        ref={reCaptchaRef}
        size="invisible"
        sitekey={site_keys.recaptcha}
        theme="light"
        badge="bottom-right"
        onChange={handleCaptchaChange}
      />
      <PageTitle title="contact us" />
      <section className="contact-us__message">
        {didSucceed ? (
          <MessageSuccess />
        ) : (
          <>
            <h3 className="sub-section-headline">Send us a message</h3>
            <form onSubmit={handleSubmit} className="contact-us__form">
              {isPending ? <MessagePending isPending={isPending} /> : null}

              <div className="contact-us__form-row contact-us__form-row--top">
                <TextInput
                  id="contact-us-name-input"
                  type="text"
                  label="name*"
                  fullWidth={true}
                  name="name"
                  variant="outlined"
                  onChange={handleInputChange}
                  helperText={errors ? errors.name : ""}
                  error={errors ? errors.name || false : false}
                />

                <TextInput
                  id="contact-us-email-input"
                  type="email"
                  label="email*"
                  fullWidth={true}
                  name="email"
                  variant="outlined"
                  onChange={handleInputChange}
                  helperText={errors ? errors.email : ""}
                  error={errors ? errors.email || false : false}
                />
              </div>
              <div className="contact-us__form-row contact-us__form-row--bottom">
                <TextInput
                  id="contact-us-message-input"
                  type="text"
                  variant="outlined"
                  label="message*"
                  multiline={true}
                  rowsMax={10}
                  fullWidth={true}
                  name="message"
                  onChange={handleInputChange}
                  helperText={errors ? errors.message : ""}
                  error={errors ? errors.message || false : false}
                />
              </div>
              <Button
                type="solid"
                header={false}
                element="input"
                value="Send Message"
              />
            </form>
          </>
        )}
      </section>
      {/* <section className="contact-us__discord">
        <h3 className="sub-section-headline">Chat on Discord</h3>
        <Button type="solid" header={false} element="link" href="#">
          Chat Now
        </Button>
      </section> */}

      <RegisterCTA />
    </main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendContactUsEmail: (formData) => dispatch(sendContactUsEmail(formData)),
  clearErrors: () => dispatch(clearErrors()),
  resetContactForm: () => dispatch(resetContactForm()),
  openSnackbar: (message) => dispatch(openSnackbar(message)),
});

const mapStateToProps = ({
  contactForm: { isPending, didSucceed, errors },
}) => ({
  isPending,
  didSucceed,
  errors,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
