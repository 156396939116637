import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import moment from "moment";
import SelectInput from "../SelectInput";
import {ReactComponent as CalendarIcon} from "../../static/svg/calendar-icon.svg";
import {DateRangePicker} from "react-dates";
import Button from "../Button";
import {getEligibleUsers} from "../../redux/TradeInterface/trade-interface.actions";
import {
    clearWithdrawalsAndDeposits,
    filterWithdrawalsAndDeposits
} from "../../redux/WithdrawalsAndDeposits/withdrawals-and-deposits.actions";
import "./WithdrawalsAndDepositsFilters.scss";
import {convertTimeZonePreserveDate} from "../../utils/date_utils";

const INITIAL_STATE = {
    startDate: null,
    endDate: null,
    selectedUserID: -1,
};

const clearUserNameDropDownString = "X CLEAR USERNAME";

const WithdrawalsAndDepositsFilters = ({role, filterTransactions, getEligibleUsers, userList, forceKey, updateSelectedUserForManager, clearWithdrawalsAndDeposits}) => {
    const [fieldInputs, setFieldInputs] = useState(INITIAL_STATE);

    useEffect(() => {
        getEligibleUsers();

        // on un-mount clear the withdrawals/deposits (easier than persisting the currentFilter from redux into the form, as it's the same amount of api calls either way
        return () => {
            clearWithdrawalsAndDeposits()
        }
    }, []);

    useEffect(() => {
        if (fieldInputs && fieldInputs.selectedUserID && fieldInputs.selectedUserID > -1) {
            handleFilterSubmit();
        }
    }, [forceKey]);

    const falseFunc = () => false;

    const handleDatesChange = ({startDate, endDate}) =>
        setFieldInputs(prevState => ({
            ...prevState,
            startDate: startDate ? moment(startDate).startOf('day') : null,
            endDate: endDate ? moment(endDate).endOf('day') : null,
        }));

    const handleFocusChange = focusedInput =>
        setFieldInputs(prevState => ({...prevState, focusedInput}));

    const handleUserFormInputChange = e => {
        if (e.target.value === clearUserNameDropDownString) {
            setFieldInputs({
                ...fieldInputs,
                selectedUserID: -1,
            });
            return;
        }
        const currentUserIndex = userList.map((user) => user.username).indexOf(e.target.value);
        setFieldInputs({
            ...fieldInputs,
            selectedUserID: userList[currentUserIndex].id,
        });
    };

    const handleFilterSubmit = e => {
        if (e) {
            e.preventDefault();
        }

        let {startDate, endDate, selectedUserID} = fieldInputs;

        startDate = startDate ? startDate.valueOf() : '';
        endDate = endDate ? endDate.valueOf() : '';

        const formData = {
            user_id: selectedUserID ? selectedUserID : '',
            startTime: convertTimeZonePreserveDate(startDate),
            endTime: convertTimeZonePreserveDate(endDate),
        };
        filterTransactions(formData);
        const currentUserIndex = userList.map((user) => user.id).indexOf(selectedUserID);
        updateSelectedUserForManager(userList[currentUserIndex]);
    };

    const getUserListDropDown = () => {
        const _userList = userList.map((user) => user.username);
        _userList.splice(0, 0, clearUserNameDropDownString);
        return _userList;
    };

    const getUserNameDisplayText = id => {
        if (id < 0) {
            return "";
        }
        const currentUserIndex = userList.map((user) => user.id).indexOf(id);
        return userList[currentUserIndex].username;
    };

    return (
        <form onSubmit={handleFilterSubmit} className="dash-component-filters-wad">
            <SelectInput
                id="invoicing-username-filter"
                label="username"
                fullWidth={true}
                name="username"
                variant="outlined"
                onChange={handleUserFormInputChange}
                menuItemArray={getUserListDropDown()}
                value={getUserNameDisplayText(fieldInputs.selectedUserID)}
                condensed={true}
                classNames="text-input user-drop-down"
                style={{minWidth: 300}}
            />

            <div className="dash-component-filters__date-picker-wrapper">
                <CalendarIcon className="calendar-icon"/>
                <DateRangePicker
                    startDate={fieldInputs.startDate}
                    startDateId="invoicing-start-date"
                    startDatePlaceholderText="Start Date"
                    endDate={fieldInputs.endDate}
                    endDateId="invoicing-end-date"
                    endDatePlaceholderText="End Date"
                    onDatesChange={handleDatesChange}
                    focusedInput={fieldInputs.focusedInput}
                    onFocusChange={handleFocusChange}
                    noBorder={true}
                    small={true}
                    numberOfMonths={1}
                    isOutsideRange={falseFunc}
                    showClearDates={true}
                />
            </div>
            <Button type="solid" header={false} element="input" value="Search"/>
        </form>
    );
};

const mapDispatchToProps = (dispatch, props) => ({
    getEligibleUsers: () => dispatch(getEligibleUsers()),
    filterTransactions: formData => dispatch(filterWithdrawalsAndDeposits({...formData, page: 1})),
    clearWithdrawalsAndDeposits: () => dispatch(clearWithdrawalsAndDeposits()),
    ...props,
});

const mapStateToProps = ({tradeInterface: {userList}, withdrawalsAndDeposits: {forceKey, currentFilter}}) => ({
    userList,
    forceKey,
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalsAndDepositsFilters);
