import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "react-dates/initialize";

import socket from "./socketIO";
import ErrorBoundary from "./components/ErrorBoundary";
import ScrollToTop from "./components/ScrollToTop";
import Snackbar from "./components/Snackbar";
import Home from "./scenes/Home";
import AboutUs from "./scenes/AboutUs";
import ContactUs from "./scenes/ContactUs";
import Accounts from "./scenes/Accounts";
import Guide from "./scenes/Guide";
import Dashboard from "./components/Dashboard";
import PageNotFound from "./scenes/PageNotFound";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import { closeSnackbar } from "./redux/Snackbar/snackbar.actions";
import { loadUser } from "./redux/User/user.actions";

import "normalize.css";
import "./App.scss";
import TermsAndConditionsPage from "./scenes/TermsAndConditionsPage";
import PrivacyPolicyPage from "./scenes/PrivacyPolicyPage";

const App = ({
  closeSnackbar,
  loadUser,
  snackbarMessage,
  snackbarIsOpen,
  userData,
  history,
  location
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => setIsMenuOpen(prevState => !prevState);

  const localStorage = window.localStorage;

  useEffect(() => {
    const pushToDashboardIfLoggedIn = ({ userData, socket }) => {
      if (userData) {
        socket.emit("JOIN_PRIVATE_SESSION", { room: userData.id });
        const pathname = localStorage.getItem("pathname");
        const pushLocation = pathname ? pathname : "/dashboard/home";
        history.push(pushLocation);
      }
    };

    const loadUserIfAuthenticated = userData => {
      if (!userData) loadUser();
      pushToDashboardIfLoggedIn({ userData, socket });
    };

    loadUserIfAuthenticated(userData);
  }, [userData, history, loadUser, localStorage]);

  useEffect(() => {
    if (location.pathname.split("/")[1] === "dashboard") {
      localStorage.setItem("pathname", location.pathname);
    }
  }, [location.pathname, localStorage]);

  useEffect(() => {
    socket.on("JWT Expired", () => {
      history.push("/accounts/login");
    });
  }, []);

  return (
    <div className="App">
      <Snackbar
        open={snackbarIsOpen}
        onClose={closeSnackbar}
        message={snackbarMessage}
      />
      <Header
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        setIsMenuOpen={setIsMenuOpen}
        user={userData}
      />
      <ScrollToTop />
      <ErrorBoundary>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route
            path="/guide"
            render={() => <Guide isAppMenuOpen={isMenuOpen} />}
          />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route
            exact
            path={[
              "/accounts/:form",
              "/accounts/register/:registrationCode",
              "/accounts/new-password/:recoveryCode"
            ]}
            component={Accounts}
          />
          <PrivateRoute
            path="/dashboard"
            user={userData}
            securityLevel="low"
            component={Dashboard}
          />

          <Route component={PageNotFound} />
        </Switch>
      </ErrorBoundary>

      <Footer />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  closeSnackbar: () => dispatch(closeSnackbar()),
  loadUser: () => dispatch(loadUser())
});

const mapStateToProps = ({ snackbar, user }) => ({
  snackbarIsOpen: snackbar.isOpen,
  snackbarMessage: snackbar.message,
  userData: user.data,
  socket: user.socket
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
