import React, {useEffect, useState} from 'react';
import moment from 'moment-timezone';
import {ResponsiveLine} from '@nivo/line';
import {connect} from 'react-redux';

import './PerformanceChart.scss';

let i = 1;

const PerformanceChart = ({performanceChartData}) => {
    i = 1;

    const [allowAnimate, setAllowAnimate] = useState(false);

    useEffect(() => {
        if (!allowAnimate) {
            setTimeout(() => {
                setAllowAnimate(true);
            }, 4000)
        }
    }, [allowAnimate]);

    useEffect(() => {
        setAllowAnimate(false);
    }, [JSON.stringify(performanceChartData)]);

    const [cursorIsOnLeftSideOfScreen, setCursorIsOnLeftSideOfScreen] = useState(
        false,
    );

    const minProfitValue =
        (performanceChartData &&
            performanceChartData.length &&
            Math.min(...performanceChartData[0].data.map(dataPoint => dataPoint.y)) -
            0.00000007) ||
        'auto';

    const maxProfitValue =
        (performanceChartData &&
            performanceChartData.length &&
            Math.max(...performanceChartData[0].data.map(dataPoint => dataPoint.y)) +
            0.00000007) ||
        'auto';

    let xInterval = (performanceChartData && performanceChartData.length && Math.floor(performanceChartData[0].data.length / 20)) || 0;
    if (xInterval < 48) {
        xInterval = 48;
    }

    const [toolTipTransformValue, setToolTipTransformValue] = useState(0);

    useEffect(() => {
        const handleWindowScroll = e => {
            const chartBounds = document
                .querySelector('.performance-chart')
                .getBoundingClientRect();

            const leftSideOfChart = chartBounds.left + chartBounds.width / 2;

            if (e.screenX > leftSideOfChart && cursorIsOnLeftSideOfScreen)
                setCursorIsOnLeftSideOfScreen(false);
            else if (e.screenX <= leftSideOfChart && !cursorIsOnLeftSideOfScreen)
                setCursorIsOnLeftSideOfScreen(true);
        };
        window.addEventListener('mousemove', handleWindowScroll);

        setToolTipTransformValue(cursorIsOnLeftSideOfScreen ? 0 : -110);

        return () => {
            window.removeEventListener('mousemove', handleWindowScroll);
        };
    }, [cursorIsOnLeftSideOfScreen, setCursorIsOnLeftSideOfScreen]);

    return (
        <div className="performance-chart">
            {performanceChartData ? (
                <ResponsiveLine
                    animate={allowAnimate}
                    data={performanceChartData}
                    margin={{top: 30, right: 20, bottom: 60, left: 60}}
                    xScale={{type: 'point'}}
                    yScale={{
                        type: 'linear',
                        stacked: false,
                        min: minProfitValue,
                        max: maxProfitValue,
                    }}
                    enableSlices="x"
                    enableGridX={false}
                    crosshairType="x"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 90,
                        legend: '',
                        legendOffset: -40,
                        legendPosition: 'middle',
                        tickValues: 50,
                        format: (d) => {
                            if (i % xInterval === 0) {
                                i = 1;
                                return moment(d).tz("America/Vancouver").format('MMM D');
                            } else {
                                i++;
                                return null;
                            }

                        },
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendOffset: -40,
                        legendPosition: 'middle',
                        format: d => {
                            return d;
                            // return Numeral(d).format('0,0e+0')
                        },
                    }}
                    sliceTooltip={({slice}) => {
                        return (
                            <div
                                style={{
                                    background: 'white',
                                    padding: '9px 12px',
                                    border: '1px solid #ccc',
                                    transform: `translateX(${toolTipTransformValue}%)`,
                                    boxShadow: '1px 1px 2px rgba(0,0,0,0.2)',
                                }}
                            >
                                {slice.points.map(point => (
                                    <div
                                        key={point.id}
                                        style={{
                                            padding: '3px 0',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginBottom: '10px',
                                                maxWidth: '150px',
                                                lineHeight: '170%',
                                            }}
                                        >
                                            <strong>Date:</strong>{' '}
                                            {moment(point.data.xFormatted).tz("America/Vancouver").format(
                                                'MMMM Do YYYY, h:mm:ss a',
                                            )}
                                        </div>
                                        <div>
                                            <strong>Profit:</strong>{' '}
                                            {point.data.yFormatted.toFixed(8)} BTC
                                        </div>
                                    </div>
                                ))}
                            </div>
                        );
                    }}
                    colors={{scheme: 'nivo'}}
                    enablePoints={false}
                    pointColor={{theme: 'background'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    enableArea={true}
                    areaOpacity={0.8}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'top-left',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: -20,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            ) : (
                <h2>NO TRADES FOR THIS DATE RANGE</h2>
            )}
        </div>
    );
};

const mapStateToProps = ({
                             dashHome: {
                                 statistics: {performanceChartData},
                             },
                         }) => ({
    performanceChartData,
});

export default connect(mapStateToProps)(PerformanceChart);
