import axios from "axios";

export const Urls = {
  server: "",
  coincap: "https://api.coincap.io/v2",
};

const getCSRFTokenFromCookie = (cookieString) => {
  const partialCookieArray = cookieString.split("XSRF-TOKEN=");
  const csrfCookieValue = partialCookieArray[1].split(";")[0];
  return csrfCookieValue;
};

export const postRequest = async (url, endpoint, data, headers = {}) => {
  const options = {
    method: "POST",
    url: url + endpoint,
    headers: headers,
    data,
    withCredentials: true,
  };

  const csrfToken = getCSRFTokenFromCookie(document.cookie);

  axios.defaults.headers.common["x-csrf-token"] = csrfToken;
  const response = await axios(options);
  return response.data;
};

export const patchRequest = async (url, endpoint, data, headers = {}) => {
  const options = {
    method: "PATCH",
    url: url + endpoint,
    headers: headers,
    data,
    withCredentials: true,
  };

  const csrfToken = getCSRFTokenFromCookie(document.cookie);

  axios.defaults.headers.common["x-csrf-token"] = csrfToken;
  const response = await axios(options);
  return response.data;
};

export const putRequest = async (url, endpoint, data, headers = {}) => {
  const options = {
    method: "PUT",
    url: url + endpoint,
    headers: headers,
    data,
    withCredentials: true,
  };

  const csrfToken = getCSRFTokenFromCookie(document.cookie);

  axios.defaults.headers.common["x-csrf-token"] = csrfToken;
  const response = await axios(options);
  return response.data;
};

export const deleteRequest = async (url, endpoint, headers = {}) => {
  const options = {
    method: "DELETE",
    url: url + endpoint,
    headers: headers,
    withCredentials: true,
  };
  const csrfToken = getCSRFTokenFromCookie(document.cookie);

  axios.defaults.headers.common["x-csrf-token"] = csrfToken;
  const response = await axios(options);
  return response.data;
};

export const getRequest = async (url, endpoint, headers = {}) => {
  const options = {
    method: "GET",
    url: url + endpoint,
    headers: headers,
  };

  const response = await axios(options).catch(console.err);

  return response.data;
};
