import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import SelectInput from "../../../components/SelectInput";
import {TextField} from "@material-ui/core";
import "./MakeInvoice.scss";
import {getRequest, postRequest, Urls} from "../../../api";
import {handleData, handleError, handleValidationError} from "../../../redux/utils.redux";
import {SnackbarTypes} from "../../../redux/Snackbar/snackbar.types";
import Button from "../../../components/Button";
import DashboardLoading from "../../../components/DashboardLoading";
import {TradeInterfaceTypes} from "../../../redux/TradeInterface/trade-interface.types";
import {getEligibleUsers, getOpenOrders, getUserBalances} from "../../../redux/TradeInterface/trade-interface.actions";
import {getInvoiceTableData} from "../../../redux/Invoices/invoices.actions";

const FORM_INITIAL_STATE = {
    quantity: "",
};

// reference in place of interface; don't pass this object into the useState
const PROFIT_REPORT_INITIAL_STATE = {
    profit: "",
    startDate: "",
    endDate: ""
};

const MakeInvoice = ({dispatch, getEligibleUsers, getInvoiceTableData, handleError, userList, errors, invoicesPage}) => {

    const [selectedUser, setSelectedUser] = useState();
    const [inputFields, setInputFields] = useState(FORM_INITIAL_STATE);
    const [profitReport, setProfitReport] = useState();
    const [isPending, setIsPending] = useState(false);

    useEffect(() => {
        getEligibleUsers();
    }, []);

    const handleSignalFormInputChange = (e) => {
        e.persist();

        setInputFields({
            ...inputFields,
            [e.target.name]: e.target.value,
        });
    };

    const getCurrentUserIndex = (username) => {
        return userList.map((user) => user.username).indexOf(username);
    };

    const handleUserFormInputChange = (e) => {
        const currentUserIndex = getCurrentUserIndex(e.target.value);
        const currentUser = userList[currentUserIndex];

        setSelectedUser(currentUser);
        getUserProfitReport(currentUser).then().catch();
    };

    async function getUserProfitReport(currentUser) {
        getRequest(Urls.server, `/profit-report?interval=MONTHLY&id=${currentUser.id}`)
            .then(data => {
                setProfitReport(data[1]);
            })
            .catch(error => {
                handleError(error, "GET_PROFIT_REPORT_FAILED");
            });
    }

    const handleSignalFormSubmit = (e) => {
        e.preventDefault();
        setIsPending(true);

        const formData = {
            user_id: selectedUser.id,
            amount: inputFields.quantity,
        };

        postRequest(Urls.server, "/invoices/create", formData)
            .then((data) => {
                setIsPending(false);
                setInputFields({...inputFields, ...FORM_INITIAL_STATE});
                getInvoiceTableData(invoicesPage);

            })
            .catch((error) => {
                setIsPending(false);
                if (error.response.data.snackbar) {
                    handleError(error, "MAKE_INVOICE_FAILED");
                }
            });

    };

    const getUserListForDropDown = (_users) => {
        return _users.map((_user) => {
            return _user.username;
        })
    };

    return (
        <section className="dash-make-invoice">
            {isPending ? <DashboardLoading /> : null}

            <form
                onSubmit={handleSignalFormSubmit}
                className="custom-mui-form custom-mui-form--half-width"
            >
                <div className="dash-make-invoice__load-user">
                    <SelectInput
                        onChange={handleUserFormInputChange}
                        menuItemArray={getUserListForDropDown(userList)}
                        id="dash-make-invoice-user-select"
                        value={selectedUser && selectedUser.username}
                        name="user"
                        label="user"
                        variant="outlined"
                        helperText={errors ? errors.userId : ""}
                        error={errors ? errors.userId || false : false}
                    />

                    <div className="dash-make-invoice__user-profile">
                        <div className="dash-make-invoice__user-profile-data">
                        <span className="dash-make-invoice__user-profile-data--title">
                            profit
                        </span>
                            <span className="dash-make-invoice__user-profile-data--info">
                            {profitReport ? (profitReport.profit + " BTC") : "N/A"}
                        </span>
                        </div>

                        <div className="dash-make-invoice__user-profile-data">
                        <span className="dash-make-invoice__user-profile-data--title">
                            time range (monthly)
                        </span>
                            <span className="dash-make-invoice__user-profile-data--info">
                            {profitReport ? profitReport.startDate + " - " + profitReport.endDate : "N/A"}
                        </span>
                        </div>
                    </div>
                </div>

                <div className="dash-make-invoice__load-bottom-row" style={{paddingTop: 0}}>
                    <TextField
                        id="dash-make-invoice-quantity-input"
                        type="number"
                        variant="outlined"
                        label="BTC Quantity"
                        name="quantity"
                        onChange={handleSignalFormInputChange}
                        value={inputFields.quantity}
                        inputProps={{step: "0.00000001", min: "0"}}
                        helperText={errors ? errors.quantity : ""}
                        error={errors ? errors.quantity || false : false}
                    />

                    <Button
                        type="solid"
                        header={false}
                        element="input"
                        value="Make Invoice"
                    />
                </div>
            </form>
        </section>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getEligibleUsers: () => dispatch(getEligibleUsers()),
    handleError: (error, type) => {
        handleError({
        error: {
            response: {
                data: {
                    snackbar: error.response ? ((error.response.data && error.response.data.length > 1 && error.response.data[0] == true) ? error.response.data[1].error : "Unknown error.") : "Unknown error."
                }
            },
        },
        dispatch,
        type,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
    })},
    getInvoiceTableData: (page) => dispatch(getInvoiceTableData({page, pendingOnly: false}))
});

const mapStateToProps = ({users, invoices: {page}, tradeInterface: {userList}}) => {
    return {
        userList,
        errors: null,
        invoicesPage: page
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeInvoice);
