import React from 'react';
import PropTypes from 'prop-types';

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';

import './SelectInput.scss';

const SelectInput = ({
  onChange,
  menuItemArray,
  label = '',
  id,
  name,
  value,
  classNames = '',
  disabled,
  variant,
  error,
  helperText,
  outlinedInputClassName
}) => {
  return (
    <FormControl
      variant={variant}
      className={`dash-trade-interface__${name}-select custom-mui-select ${classNames}`}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        onChange={onChange}
        value={value}
        disabled={disabled}
        error={error}
        input={<OutlinedInput name={label} id={id} className={outlinedInputClassName}/>}
        inputProps={{
          name,
          id,
        }}
      >
        {menuItemArray.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText> {helperText}</FormHelperText>
    </FormControl>
  );
};

SelectInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  menuItemArray: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
};

export default SelectInput;
