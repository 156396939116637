import {InvoiceModalTypes} from "./invoice-modal.types";

const INITIAL_STATE = {
    isOpen: false,
    invoiceData: null,
    cancelInvoicePending: false,
    userIsOpen: false,
    userInvoiceData: null,
};

const invoiceModalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case InvoiceModalTypes.USER_INVOICE_MODAL_OPEN:
            return { ...state, userIsOpen: true, userInvoiceData: action.payload };

        case InvoiceModalTypes.USER_INVOICE_MODAL_CLOSE:
            return { ...state, userIsOpen: false, userInvoiceData: null};

        case InvoiceModalTypes.INVOICE_MODAL_OPEN:
            return { ...state, isOpen: true, invoiceData: action.payload };

        case InvoiceModalTypes.INVOICE_MODAL_CLOSE:
            return { ...state, isOpen: false, invoiceData: null };

        case InvoiceModalTypes.INVOICE_MODAL_CANCEL_INVOICE_PENDING:
            return { ...state, cancelInvoicePending: true };

        case InvoiceModalTypes.INVOICE_MODAL_CANCEL_INVOICE_SUCCESS:
            return { ...state, cancelInvoicePending: false };

        case InvoiceModalTypes.INVOICE_MODAL_CANCEL_INVOICE_FAILED:
            return { ...state, cancelInvoicePending: false };

        default:
            return state;
    }
};

export default invoiceModalReducer;
