// import {convertDateTimeToCurrentTimezone, removeNegativeFromZero} from "../../../../format";
import React from "react";
import {convertDateTimeToCurrentTimezone} from "../../../../format";
import "./InvoiceTableRow.scss";
import { connect } from "react-redux";
import {openInvoiceModal} from "../../../../redux/InvoiceModal/invoice-modal.actions";

const InvoicesTableTableRow = ({data, index, openInvoiceModal}) => {
    const darkBgClass = index % 2 !== 0 ? "" : "invoice-table-row--dark";

    function openModal() {
        openInvoiceModal(data);
    }

    return (
        <div
            onClick={openModal}
            tabIndex={1}
            className={`invoice-table-row ${darkBgClass}`}
        >
            <span className="invoice-table-row__data remove-at-sm">
                {data.id}
            </span>
            <span className="invoice-table-row__data remove-at-sm">
              {convertDateTimeToCurrentTimezone({
                  dateTime: data.created_at,
                  // format: "h:mm a - M/D/YYYY"
                  format: "M/D/YYYY"
              })}
            </span>
            <span className="invoice-table-row__data">
                {data.username}
            </span>
            <span className="invoice-table-row__data">
              {data.amount}
            </span>
            <span className="invoice-table-row__data remove-at-sm text-concat-cell">
              {data.withdraw_id ? data.withdraw_id : "N/A"}
            </span>
            <span className="invoice-table-row__data remove-at-sm remove-at-md text-break">
              {data.reason ? data.reason : "N/A"}
            </span>
            <span className="invoice-table-row__data">
              {data.paid_externally ? "Paid Externally" : (data.withdraw_id ? "Paid" : "Pending")}
            </span>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    openInvoiceModal: invoiceData => dispatch(openInvoiceModal(invoiceData)),
});

export default connect(null, mapDispatchToProps)(InvoicesTableTableRow);
