import React from 'react';

import Button from '../../../../components/Button';
import './TradeProfileComplete.scss';

const TradeProfileComplete = ({ setEditRisk }) => {
  const handleEditClick = () => {
    setEditRisk(true);
  };
  return (
    <div className="trade-profile-complete">
      <p className="body-text">You have completed your trade profile!</p>
      <Button
        onClick={handleEditClick}
        element="button"
        type="solid"
        header={false}
      >
        Edit
      </Button>
    </div>
  );
};

export default TradeProfileComplete;
