import { UserTypes } from "./user.types";

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  isLoggingOut: false,
  showGreetingPending: false
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.LOAD_USER_PENDING:
      return { ...state, isLoading: true };

    case UserTypes.LOAD_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };

    case UserTypes.LOAD_USER_FAILED:
      return { ...state, isLoading: false, data: null };

    case UserTypes.LOGOUT_USER_PENDING:
      return { ...state, isLoggingOut: true };

    case UserTypes.LOGOUT_USER_SUCCESS:
      return { ...state, data: null, isLoggingOut: false };

    case UserTypes.LOGOUT_USER_FAILED:
      return { ...state, data: null, isLoggingOut: false };
    case UserTypes.UPDATE_SHOW_GREETING_PENDING:
      return { ...state, showGreetingPending: true };

    case UserTypes.UPDATE_SHOW_GREETING_SUCCESS:
      return {
        ...state,
        showGreetingPending: false,
        data: { ...state.data, showGreeting: action.payload }
      };
    case UserTypes.UPDATE_SHOW_GREETING_FAILED:
      return { ...state, showGreetingPending: false };

    default:
      return state;
  }
};

export default userReducer;
