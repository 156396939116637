import React, {useEffect} from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import DashComponent from "../../components/Dashboard/DashComponent";
import "./UserDashInvoicing.scss";
import UserInvoicesTableDisplay from "./UserInvoiceTableDisplay";
import {getUsersInvoiceTableData} from "../../redux/Invoices/invoices.actions";

const DashUserInvoicing = ({role = 'user', user, getUsersInvoiceTableData}) => {

    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            getUsersInvoiceTableData({page: 1, pendingOnly: false, user_id: user.data.id});
        }
    }, [JSON.stringify(user)]);

    return (
        <section className="user-dash-invoicing">
            <DashComponent title="Your Invoices">
                {/*<p className="body-text invoice-table-instruction">*/}
                {/*    Click an entry for the ability to cancel (if status is pending), as well as to view full details.*/}
                {/*</p>*/}
                <UserInvoicesTableDisplay
                    headerTitleArray={['id', 'created', 'amount', 'withdraw ID', 'status']}
                    removeHeaderAtSmallArray={['created', 'withdraw ID']}
                />
            </DashComponent>

        </section>
    );
};

const mapStateToProps = ({user}) => ({
    user,
});

const mapDispatchToProps = dispatch => ({
    getUsersInvoiceTableData: filterData => dispatch(getUsersInvoiceTableData(filterData))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashUserInvoicing));
