import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

import "./TextInput.scss";

const TextInput = ({
  value,
  onChange,
  id,
  label,
  name,
  placeholder,
  type = "text",
  fullWidth = true,
  variant,
  multiline = false,
  rowsMax,
  borderColor,
  helperText = "",
  error = false,
  disabled
}) => {
  return (
    <TextField
      className={`text-input ${
        borderColor === "white" ? "text-input--border-white" : ""
      }`}
      id={id}
      type={type}
      variant={variant}
      label={label}
      fullWidth={fullWidth}
      name={name}
      onChange={onChange}
      value={value}
      multiline={multiline}
      rowsMax={rowsMax}
      helperText={helperText}
      error={error}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool
};

export default TextInput;
