import React, {useEffect} from "react";
import {connect} from "react-redux";

import {convertDateTimeToCurrentTimezone} from "../../../format";
import {ReactComponent as CloseIcon} from "../../../static/svg/close-icon.svg";
import {ReactComponent as CancelSignalIcon} from "../../../static/svg/cancel-signal-icon.svg";

import "./UserInvoiceModal.scss";
import {
    cancelInvoice,
    closeInvoiceModal,
    closeUserInvoiceModal
} from "../../../redux/InvoiceModal/invoice-modal.actions";
import Button from "../../Button";
import {paidInvoiceExternally, unPairInvoice} from "../../../redux/Invoices/invoices.actions";

const DataGroup = ({label, data, labelColorClass, className}) => (
    <div className={"data-group" + (className ? " " + className : "")}>
        <span className={`data-group__label ${labelColorClass}`}>{label}</span>
        <span className="data-group__data">{data}</span>
    </div>
);

const UserInvoiceModal = ({isOpen, userInvoiceData, closeUserInvoiceModal}) => {

    useEffect(() => {
        const handleClickOnBackground = (e) => {
            const {classList} = e.target;

            if (classList.contains("dashboard") && isOpen) closeUserInvoiceModal();
        };

        window.addEventListener("click", handleClickOnBackground);

        return () => {
            window.removeEventListener("click", handleClickOnBackground);
        };
    }, [isOpen, closeUserInvoiceModal]);

    return (
        <div className="invoice-modal">
            <CloseIcon
                aria-label="close"
                onClick={closeUserInvoiceModal}
                className="invoice-modal__close-btn"
            />
            <div className="invoice-modal__content">
                <div className="invoice-modal__row">
                    <DataGroup
                        label="invoice id"
                        data={userInvoiceData.id}
                        labelColorClass="data-group__label--green"
                    />
                </div>

                <div className="invoice-modal__row">
                    <DataGroup
                        label="created"
                        data={convertDateTimeToCurrentTimezone({
                            dateTime: userInvoiceData.created_at,
                            format: "h:mm a - M/D/YYYY"
                            // format: "M/D/YYYY"
                        })}
                        labelColorClass="data-group__label--blue"
                    />
                    {/*<DataGroup*/}
                    {/*    label="username"*/}
                    {/*    data={userInvoiceData.username}*/}
                    {/*    labelColorClass="data-group__label--blue"*/}
                    {/*/>*/}
                    <DataGroup
                        label="amount"
                        data={userInvoiceData.amount}
                        labelColorClass="data-group__label--blue"
                    />
                </div>
                <div className="invoice-modal__row">
                    <DataGroup
                        label="Withdraw ID"
                        data={userInvoiceData.withdraw_id ? userInvoiceData.withdraw_id : "N/A"}
                        labelColorClass="data-group__label--yellow"
                        className="word-break-all-helper"
                    />
                    {/*<DataGroup*/}
                    {/*    label="reason"*/}
                    {/*    data={userInvoiceData.reason ? userInvoiceData.reason : "N/A"}*/}
                    {/*    labelColorClass="data-group__label--yellow"*/}
                    {/*/>*/}

                    <DataGroup
                        label="status"
                        data={userInvoiceData.paid_externally ? "Paid Externally" : (userInvoiceData.withdraw_id ? "Paid" : "Pending")}
                        labelColorClass="data-group__label--yellow"
                    />
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    closeUserInvoiceModal: () => dispatch(closeUserInvoiceModal()),
});

const mapStateToProps = ({invoiceModal: {userIsOpen, userInvoiceData}}) => ({
    userIsOpen,
    userInvoiceData,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInvoiceModal);
