import React from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import { ReactComponent as CalendarIcon } from '../../static/svg/calendar-icon.svg';
import './DateRangeInput.scss';

const falseFunc = () => false;

const DateRangeInput = ({
  classnames = '',
  startDate,
  startDateId,
  startDatePlaceholderText,
  endDate,
  endDateId,
  endDatePlaceholderText,
  onDatesChange,
  focusedInput,
  onFocusChange,
  noBorder = true,
  small = true,
  numberOfMonths = 1,
  isOutsideRange = falseFunc,
}) => (
  <div className={`date-range-input ${classnames}`}>
    <CalendarIcon className="calendar-icon" />
    <DateRangePicker
      startDate={startDate}
      startDateId={startDateId}
      startDatePlaceholderText={startDatePlaceholderText}
      endDate={endDate}
      endDateId={endDateId}
      endDatePlaceholderText={endDatePlaceholderText}
      onDatesChange={onDatesChange}
      focusedInput={focusedInput}
      onFocusChange={onFocusChange}
      noBorder={noBorder}
      small={small}
      numberOfMonths={numberOfMonths}
      isOutsideRange={isOutsideRange}
    />
  </div>
);

export default DateRangeInput;
