import React, {useState, useEffect} from "react";
import {Slider, TextField} from "@material-ui/core";
import {connect} from "react-redux";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import {find} from "lodash";

import Checkbox from "../../components/Checkbox";
import MuiBasicTable from "../../components/MuiBasicTable";
import RadioButton from "../../components/RadioButton";
import Button from "../../components/Button";
import DashboardLoading from "../../components/DashboardLoading";
import DashComponent from "../../components/Dashboard/DashComponent";
import AutoCompleteInput from "./AutoCompleteInput";
import SelectInput from "../../components/SelectInput";
import {
    getEligibleUsers,
    sendSignal,
    getUserBalances,
    clearUserBalances,
    getOpenOrders,
    cancelSelectedOrders,
    sellSelectedOrders,
} from "../../redux/TradeInterface/trade-interface.actions";
import socket from "../../socketIO";
import {getRequest, Urls} from "../../api";
import {
    orderTypeArray,
    balancesTableHeadList,
    openOrderTableHeadList,
} from "./seedData";
import {ReactComponent as TradeInterfaceIcon} from "../../static/svg/trade-interface-icon-white.svg";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import "./DashTradeInterface.scss";
import AltCoinSlider from "../../components/AltCoinSlider";
import TradingViewWidget from "./tradingViewWidget";

const FORM_INITIAL_STATE = {
    symbol: "",
    side: "",
    quantity: "",
    type: "",
    timeInForce: "GTC",
    stopPrice: "",
    price: "",
    icebergQuantity: "",
};

const USER_INITIAL_STATE = {
    eligibleUsers: [],
    currentUser: null,
};

const BALANCES_INITIAL_STATE = {
    balance: "",
    balanceOptions: [],
    acquiredBy: "",
    acquiredByOptions: [],
};

const DashTradeInterface = ({
                                role,
                                // Redux actions
                                getEligibleUsers,
                                getUserBalances,
                                getOpenOrders,
                                clearUserBalances,
                                cancelSelectedOrders,
                                sellSelectedOrders,
                                // Redux state
                                balances,
                                openOrders,
                                userList,
                                isPending,
                                signalerId,
                                sendSignal,
                                errors,
                                success,
                            }) => {
    const [signalFormInputFields, setSignalFormInputFields] = useState(
        FORM_INITIAL_STATE
    );
    const [users, setUsers] = useState(USER_INITIAL_STATE);
    const [balancesInputs, setBalancesInputs] = useState(BALANCES_INITIAL_STATE);
    const [signalType, setSignalType] = useState("byUsername");
    const [selectedSymbolData, setSelectedSymbolData] = useState({});
    const [isSymbolSelected, setIsSymbolSelected] = useState(0);
    const [isUserBalancesOpen, setIsUserBalancesOpen] = useState(false);
    const [isOpenOrdersOpen, setIsOpenOrdersOpen] = useState(false);
    const [openOrdersValues, setOpenOrdersValues] = useState({});
    const [initialLoad, setInitialLoad] = useState(true);
    const [sliderValue, setSliderValue] = useState(0);
    const [sliderShouldReset, setSliderShouldReset] = useState(true);
    const [sliderEnabled, setSliderEnabled] = useState(true);
    const [updatePriceAfterChoosingSymbol, setUpdatePriceAfterChoosingSymbol] = useState(false);

    useEffect(() => {
        if (initialLoad) {
            if (openOrders.length > 0) {
                setInitialLoad(false);
            }
            return;
        }
        setIsOpenOrdersOpen(false);
        setTimeout(() => {
            setIsOpenOrdersOpen(true);
        }, 100);
    }, [JSON.stringify(openOrders)]);

    useEffect(() => {
        socket.on("ORDER_CANCELED", () => {
            const id = users.currentUser && users.currentUser.id;

            if (id) {
                getOpenOrders(id);
                getUserBalances(id);
            }
        });
    }, [getOpenOrders, getUserBalances, users.currentUser]);

    useEffect(() => {
        const newOpenOrdersValues = {};

        openOrders.forEach(({transact_time, symbol, order_id}) => {
            newOpenOrdersValues[`${transact_time}-${order_id}-${symbol}`] = false;
        });

        setOpenOrdersValues(newOpenOrdersValues);
    }, [openOrders]);

    useEffect(() => {
        if (success && isPending) setSignalFormInputFields(FORM_INITIAL_STATE);
    }, [success, isPending]);

    useEffect(() => {
        const {symbol: symbolWithSlash} = signalFormInputFields;

        const symbol = symbolWithSlash.split("/").join("");
        const symbol_1 = symbolWithSlash.split("/")[0];
        const symbol_2 = symbolWithSlash.split("/")[1];
        const symbol_with_slash = symbolWithSlash.split("/").join(" / ");

        const setSymbolData = async () => {
            if (symbol && isSymbolSelected) {
                const response = await getRequest(
                    Urls.server,
                    `/binance/ticker?symbol=${symbol}`
                ).catch((e) => {
                    // console.error(e)
                });

                if (response) {
                    setSelectedSymbolData({
                        ...response,
                        symbol_1,
                        symbol_2,
                        symbol_with_slash,
                    });
                }

                if (updatePriceAfterChoosingSymbol) {
                    setSignalFormInputFields((prevState) => ({
                        ...prevState,
                        price: response.price
                    }));
                    setUpdatePriceAfterChoosingSymbol(false);
                }
            }
        };

        setSymbolData().then().catch();
    }, [isSymbolSelected]);

    useEffect(() => {
        if (signalType === "allUsers") clearUserBalances();
        return () => {
            clearUserBalances();
        };
    }, [clearUserBalances, signalType]);

    useEffect(() => {
        if (signalType === "allUsers") {
            setUsers(USER_INITIAL_STATE);
        } else {
            getEligibleUsers();
            setUsers({
                currentUser: null,
                eligibleUsers: userList,
            });
        }
    }, [signalType]);

    useEffect(() => {
        getEligibleUsers();
        setUsers({
            currentUser: null,
            eligibleUsers: userList,
        });
    }, [userList.length]);

    useEffect(() => {
        if (balances.length) {
            const balanceOptions = getBalanceSymbols(balances);
            const [, ...acquiredByOptions] = Object.keys(balances[0]);
            setBalancesInputs((prevState) => ({
                ...prevState,
                balanceOptions,
                balance: balances[0],
                acquiredByOptions,
                acquiredBy: acquiredByOptions[0],
            }));
        }
    }, [
        balances.length,
        ...balances.map((balance) => balance.user),
        ...balances.map((balance) => balance.ninjas),
    ]);

    useEffect(() => {
        if (!sliderShouldReset) {
            setSliderShouldReset(true);
        } else {
            updateAdjustedQuantity();
        }
    }, [sliderValue, JSON.stringify(signalFormInputFields)]);

    const getCurentUserIndex = (username) => {
        return users.eligibleUsers.map((user) => user.username).indexOf(username);
    };

    const getBalanceSymbols = (balances) =>
        balances.map((balance) => balance.symbol);

    const handleUserFormInputChange = (e) => {
        const currentUserIndex = getCurentUserIndex(e.target.value);
        const currentUser = users.eligibleUsers[currentUserIndex];
        getUserBalances(currentUser.id);
        getOpenOrders(currentUser.id);

        setUsers((prevState) => ({
            ...prevState,
            currentUser,
        }));
    };

    const handleSignalFormInputChange = (e) => {
        e.persist();

        setSliderShouldReset(false);
        setSignalFormInputFields((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSideInputChange = (e) => {
        e.persist();

        setSliderShouldReset(true);
        setSliderValue(0);
        setSignalFormInputFields((prevState) => ({
            ...prevState,
            quantity: "0",
            [e.target.name]: e.target.value,
        }));
    }

    const handlePriceInputChange = (e) => {
        e.persist();

        setSignalFormInputFields((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    const handleQuantityInputChange = (e) => {
        e.persist();

        setSliderValue(0);
        setSliderShouldReset(false);

        handleSignalFormInputChange(e);
    }

    const handleTypeChange = (e) => {
        if (e.target.value === "market") {
            setSliderEnabled(false);
            setSliderValue(0);
            setSliderShouldReset(false);
            setSignalFormInputFields((prevState) => ({
                ...prevState,
                price: "",
                [e.target.name]: e.target.value,
            }));

        } else {
            setSliderEnabled(true);
            setSliderShouldReset(false);

            if (selectedSymbolData && Object.keys(selectedSymbolData).length > 0) {
                setSignalFormInputFields((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                    price: selectedSymbolData.price,
                }));
            } else {
                setSignalFormInputFields((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                }));
            }
        }

    }

    const handleAutocompleteChange = (e, {newValue}) => {
        e.persist();

        setSliderShouldReset(false);
        setSignalFormInputFields((prevState) => ({
            ...prevState,
            [e.target.name]: newValue,
        }));
    };

    const handleAutocompleteClick = (parts) => {
        const combineParts = (parts) => parts.map((part) => part.text).join("");

        setSliderShouldReset(false);
        setSignalFormInputFields((prevState) => ({
            ...prevState,
            symbol: combineParts(parts),
        }));
        setIsSymbolSelected(isSymbolSelected + 1);

        if (
            type === "limit" ||
            type === "stop loss limit" ||
            type === "take profit limit" ||
            type === "limit maker"
        ) {
            setUpdatePriceAfterChoosingSymbol(true);
        }
    };

    const handleSignalFormSubmit = (e) => {
        e.preventDefault();

        const formData = {
            ...signalFormInputFields,
            type: replaceSpacesWithUnderscores(signalFormInputFields.type),
            symbol: removeSlashFromSymbolPair(signalFormInputFields.symbol),
            symbolWithSlash: signalFormInputFields.symbol,
            userId: users.currentUser ? users.currentUser.id : "",
            quantity: signalFormInputFields.quantity,
            signalerId,
            signalType,
        };

        sendSignal(formData);
    };

    // This is needed to stop the form from submitting when enter is pressed to select an option
    // from the autocomplete form
    const handleEnterKeyPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            setIsSymbolSelected(isSymbolSelected + 1);
        }
    };

    const removeSlashFromSymbolPair = (symbolPair) =>
        symbolPair.replace(/\//g, "");

    const replaceSpacesWithUnderscores = (type) => type.replace(/\s/g, "_");

    const formatCoinBlacklist = (blacklist) => blacklist.join(", ");

    const userProfileColorClass = (currentUser) => {
        if (!users.currentUser || !users.currentUser.trade_risk) return "";
        else {
            switch (users.currentUser.trade_risk) {
                case "high":
                    return "dash-trade-interface__user-profile-data--red";
                case "medium":
                    return "dash-trade-interface__user-profile-data--yellow";
                case "low":
                    return "dash-trade-interface__user-profile-data--green";
                default:
                    return "";
            }
        }
    };

    const renderTimeInForceInput = () => {
        if (
            type === "limit" ||
            type === "stop loss limit" ||
            type === "take profit limit"
        )
            return (
                <SelectInput
                    onChange={handleSignalFormInputChange}
                    menuItemArray={["GTC", "IOC", "FOK"]}
                    id="dash-trade-interface-time-in-force"
                    value={
                        signalFormInputFields.icebergQuantity > 0
                            ? "GTC"
                            : signalFormInputFields.timeInForce
                    }
                    name="timeInForce"
                    label="Time In Force"
                    variant="outlined"
                    disabled={signalFormInputFields.icebergQuantity > 0}
                />
            );
        else return null;
    };

    const renderPriceInput = () => {
        if (
            type === "limit" ||
            type === "stop loss limit" ||
            type === "take profit limit" ||
            type === "limit maker"
        )
            return (
                <TextField
                    id="dash-trade-interface-price-input"
                    type="number"
                    variant="outlined"
                    label="price"
                    name="price"
                    onChange={handlePriceInputChange}
                    value={signalFormInputFields.price}
                    inputProps={{step: "0.00000001", min: "0"}}
                    helperText={errors ? errors.price : ""}
                    error={errors ? errors.price || false : false}
                />
            );
        else return null;
    };

    const renderStopPriceInput = () => {
        if (type === "stop loss limit" || type === "take profit limit")
            return (
                <TextField
                    id="dash-trade-interface-stop-price-input"
                    type="number"
                    variant="outlined"
                    label="stop price"
                    name="stopPrice"
                    onChange={handleSignalFormInputChange}
                    value={signalFormInputFields.stopPrice}
                    inputProps={{step: "0.00000001", min: "0"}}
                    helperText={errors ? errors.stopPrice : ""}
                    error={errors ? errors.stopPrice || false : false}
                />
            );
        else return null;
    };

    const renderIcebergQuantityInput = () => {
        if (
            type === "limit" ||
            type === "stop loss limit" ||
            type === "take profit limit"
        )
            return (
                <TextField
                    id="dash-trade-interface-iceberg-quantity-input"
                    type="number"
                    variant="outlined"
                    label="iceberg qty"
                    name="icebergQuantity"
                    onChange={handleSignalFormInputChange}
                    value={signalFormInputFields.icebergQuantity}
                    inputProps={{step: "0.00000001", min: "0"}}
                />
            );
        else return null;
    };

    const {type} = signalFormInputFields;

    const formattedBalancesTableRowList = balances.map(
        ({asset, available, locked, total, valueBtc, valueUsd}) => {
            available = available ? available : "0.00000000";
            locked = locked ? locked : "0.00000000";
            total = total ? total : "0.00000000";
            valueBtc = valueBtc ? valueBtc : "0.00000000";
            valueUsd = valueUsd ? valueUsd : "0.00000000";

            return [
                {data: total + ` ${asset}`, align: "center"},
                {data: available + ` ${asset}`, align: "center"},
                {data: locked + ` ${asset}`, align: "center"},
                {data: valueBtc + " BTC", align: "center"},
                {data: "$" + valueUsd, align: "center"},
            ];
        }
    );

    const formattedOpenOrdersTableRowList = openOrders.map(
        ({
             symbol_with_slash,
             symbol,
             side,
             type,
             price,
             btc_value,
             usd_value,
             stop_price,
             quantity,
             status,
             transact_time,
             order_id,
         }) => {
            const id = `${transact_time}-${order_id}-${symbol}`;
            return [
                {
                    data: (
                        <Checkbox
                            callback={(e) => {
                                const id = e.target.getAttribute("id");
                                setOpenOrdersValues((prevState) => ({
                                    ...prevState,
                                    [id]: !prevState[id],
                                }));
                            }}
                            size="medium"
                            value={openOrdersValues[id]}
                            checked={openOrdersValues[id]}
                            id={id}
                        />
                    ),
                    align: "left",
                },
                {data: symbol_with_slash, align: "center"},
                {data: side, align: "center"},
                {data: type, align: "center"},
                {data: status, align: "center"},
                {data: quantity, align: "center"},
                {data: stop_price + " " + symbol_with_slash.split("/")[1], align: "center"},
                {data: price + " " + symbol_with_slash.split("/")[1], align: "center"},
                {data: btc_value + " BTC", align: "center"},
                {data: "$" + usd_value, align: "center"},
            ];
        }
    );

    const handleToggleUserBalances = () => {
        setIsUserBalancesOpen(!isUserBalancesOpen);
    };
    const handleToggleOpenOrders = () => {
        setIsOpenOrdersOpen(!isOpenOrdersOpen);
    };

    const [areAllOpenOrdersSelected, setAreAllOpenOrdersSelected] = useState(false);

    const [areAllOpenOrdersDeselected, setAreAllOpenOrdersDeselected] = useState(false);

    useEffect(() => {
        const findAreAllOpenOrdersSelected = () => {
            let totalOrders = 0;

            const ordersSelectedCount = Object.keys(openOrdersValues).filter((id) => {
                totalOrders += 1;
                return openOrdersValues[id];
            }).length;

            if (ordersSelectedCount === totalOrders)
                setAreAllOpenOrdersSelected(true);
            else setAreAllOpenOrdersSelected(false);

            if (ordersSelectedCount === 0) setAreAllOpenOrdersDeselected(true);
            else setAreAllOpenOrdersDeselected(false);
        };

        findAreAllOpenOrdersSelected();
    }, [openOrdersValues]);

    const handleSelectAllToggle = () => {
        const newOpenOrdersValues = {};

        for (const id in openOrdersValues) {
            newOpenOrdersValues[id] = !areAllOpenOrdersSelected;
        }

        setOpenOrdersValues(newOpenOrdersValues);
    };

    const handleSellSelectedOrders = () => {
        const user_id = users.currentUser.id;

        const orders = Object.keys(openOrdersValues)
            .filter((key) => openOrdersValues[key])
            .map((key) => {
                // `key` has a format of `timestamp-quantity-symbol` as a string
                const [timestamp, order_id, symbol] = key.split("-");

                return find(openOrders, {
                    transact_time: timestamp,
                    order_id,
                    symbol,
                });
            })
            .filter((orderData) => !!orderData);

        const payload = {user_id, orders};

        sellSelectedOrders(payload);
    };

    const handleCancelSelectedOrders = () => {
        const user_id = users.currentUser.id;
        const orders = Object.keys(openOrdersValues)
            .filter((key) => openOrdersValues[key])
            .map((key) => {
                // `key` has a format of `timestamp-quantity-symbol` as a string
                const [timestamp, order_id, symbol] = key.split("-");

                return {timestamp, order_id, symbol};
            });

        const payload = {user_id, orders};

        cancelSelectedOrders(payload);
    };

    const isOpenOrdersButtonVisibleClass = areAllOpenOrdersDeselected
        ? ""
        : " visible";

    /**
     * Update the state value for the slider.
     *
     * @param event
     * @param _value
     */
    function onSliderChange(event, _value) {
        setSliderValue(_value);
    }

    /**
     * If a "side" (buy or sell) has been selected,
     * then when the admin moves the slider, this function will run to
     * determine how to manipulate the quantity field.
     *
     * If doing a buy order,
     * determines what quantity of symbol_1 to buy based on the entered price,
     * and the % of symbol_2 that the selected user owns.
     * For example, select "ETH/BTC" for symbol, trying to buy ETH with user's BTC-
     * So we'll take X% of the user's BTC and divide by the entered price to determine how much
     * ETH we will be trying to buy.
     *
     * If doing a sell order,
     * simply adjust the quantity to be X% of the user's owned amount of symbol_1.
     *
     */
    function updateAdjustedQuantity() {

        function _resetQuantity() {
            setSignalFormInputFields({
                ...signalFormInputFields,
                quantity: "0",
            });
        }

        if (signalFormInputFields.side === "buy") {
            if (sliderValue > 0 && signalFormInputFields?.price) {
                const usersAsset = find(balances, ["asset", selectedSymbolData.symbol_2]);

                if (!usersAsset) {
                    _resetQuantity();
                } else {
                    const usersAssetAdjustedAmount = usersAsset.available * sliderValue;
                    const adjustedQuantity = (usersAssetAdjustedAmount / parseFloat(signalFormInputFields.price)).toFixed(8);

                    setSignalFormInputFields({
                        ...signalFormInputFields,
                        quantity: adjustedQuantity,
                    });
                }
            } else {
                _resetQuantity();
            }
        } else if (signalFormInputFields.side === "sell") {
            if (sliderValue > 0 && Object.keys(selectedSymbolData).length > 0) {
                const usersAsset = find(balances, ["asset", selectedSymbolData.symbol_1]);

                if (!usersAsset) {
                    _resetQuantity();
                } else {
                    const usersAssetAdjustedAmount = usersAsset.available * sliderValue;
                    const adjustedQuantity = (usersAssetAdjustedAmount).toFixed(8);

                    setSignalFormInputFields({
                        ...signalFormInputFields,
                        quantity: adjustedQuantity,
                    });
                }
            } else {
                _resetQuantity();
            }
        }
    }

    /**
     * Logic for determining if the slider should be disabled.
     * Had some overlap with the "sliderEnabled" useState I set up,
     * but it should be fine.
     *
     * @returns {boolean}
     */
    function isSliderDisabled() {
        if (signalFormInputFields.side === "") {
            return true;
        }

        if (signalFormInputFields.side === "sell") {
            return false;
        }

        if (signalFormInputFields.side === "buy" && signalFormInputFields?.type?.length < 1) {
            return true;
        }

        if (sliderEnabled) {
            return false
        }

        if (signalFormInputFields?.type?.length > 0 && signalFormInputFields?.type !== "market") {
            return false
        }

        return true;
    }

    return (
        <section className="dash-trade-interface">
            {isPending ? <DashboardLoading/> : null}

            <DashComponent
                svg={<TradeInterfaceIcon/>}
                role={role}
                title="trade interface"
            >
                {/*<FormControl*/}
                {/*  className="dash-trade-interface__trade-type-group"*/}
                {/*  component="fieldset"*/}
                {/*>*/}
                {/*  <RadioGroup*/}
                {/*    aria-label="Search Type"*/}
                {/*    name="signalType"*/}
                {/*    value={signalType}*/}
                {/*    onChange={handleSignalTypeChange}*/}
                {/*  >*/}
                {/*    <RadioButton*/}
                {/*      label="all users"*/}
                {/*      value="allUsers"*/}
                {/*      type="dark"*/}
                {/*      checked={signalType === "allUsers"}*/}
                {/*    />*/}
                {/*    <RadioButton*/}
                {/*      label="by username"*/}
                {/*      value="byUsername"*/}
                {/*      type="dark"*/}
                {/*      checked={signalType === "byUsername"}*/}
                {/*    />*/}
                {/*  </RadioGroup>*/}
                {/*</FormControl>*/}

                {/*{signalType === "byUsername" ? (*/}
                <>
                    <div className="dash-trade-interface__load-user">
                        <SelectInput
                            onChange={handleUserFormInputChange}
                            menuItemArray={users.eligibleUsers.map((user) => user.username)}
                            id="dash-trade-interface-user-select"
                            value={users.currentUser && users.currentUser.username}
                            name="user"
                            label="user"
                            variant="outlined"
                            helperText={errors ? errors.userId : ""}
                            error={errors ? errors.userId || false : false}
                        />

                        <div className="dash-trade-interface__user-profile">
                            <div
                                className={`dash-trade-interface__user-profile-data ${userProfileColorClass(
                                    users.currentUser
                                )}`}
                            >
                                <span className="dash-trade-interface__user-profile-data--title">
                                    risk per trade
                                </span>
                                <span className="dash-trade-interface__user-profile-data--info">
                                    {users.currentUser ? users.currentUser.trade_risk : "N/A"}
                                </span>
                            </div>
                            <div className="dash-trade-interface__user-profile-data">
                                <span className="dash-trade-interface__user-profile-data--title">
                                    coin blacklist
                                </span>
                                <ul
                                    className={
                                        users.currentUser && users.currentUser.coinBlacklist
                                            ? `dash-trade-interface__blacklist`
                                            : ""
                                    }
                                >
                                    <li>
                                        {users.currentUser
                                            ? formatCoinBlacklist(users.currentUser.coinBlacklist)
                                            : "N/A"}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="dash-trade-interface__user-profile-data">
                            {selectedSymbolData.symbol ? (
                                <TradingViewWidget
                                    symbol_1={selectedSymbolData.symbol_1}
                                    symbol_2={selectedSymbolData.symbol_2}
                                />    
                            ) : null}
                        </div>
                    </div>
                    {users.currentUser ? (
                        <>
                            <div className="dash-trade-interface__balances-table-container">
                                <button
                                    disabled={!balances.length}
                                    onClick={handleToggleUserBalances}
                                >
                                    {`User Balances (${balances.length})`}
                                </button>

                                {isUserBalancesOpen ? (
                                    formattedBalancesTableRowList.length ? (
                                        <MuiBasicTable
                                            headList={balancesTableHeadList}
                                            rowList={formattedBalancesTableRowList}
                                            noDataMessage="No User Balances Available"
                                        />
                                    ) : null
                                ) : null}
                            </div>
                        </>
                    ) : null}

                    {users.currentUser ? (
                        <>
                            <div className="dash-trade-interface__open-orders-table-container">
                                <button
                                    disabled={!openOrders.length}
                                    onClick={handleToggleOpenOrders}
                                >{`Open Orders (${openOrders.length})`}</button>

                                {isOpenOrdersOpen ? (
                                    <>
                                        {formattedOpenOrdersTableRowList.length ? (
                                            <>
                                                <MuiBasicTable
                                                    headList={openOrderTableHeadList}
                                                    rowList={formattedOpenOrdersTableRowList}
                                                    noDataMessage="No Open Orders"
                                                />
                                                <div className="dash-trade-interface__open-orders-buttons">
                                                    <button
                                                        className="dash-trade-interface__open-orders-button dash-trade-interface__open-orders-buttons--select-all"
                                                        onClick={handleSelectAllToggle}
                                                    >
                                                        Select All
                                                    </button>

                                                    {/*<button*/}
                                                    {/*    className={*/}
                                                    {/*        "open-orders-sell-button" +*/}
                                                    {/*        isOpenOrdersButtonVisibleClass*/}
                                                    {/*    }*/}
                                                    {/*    onClick={handleSellSelectedOrders}*/}
                                                    {/*>*/}
                                                    {/*    Sell*/}
                                                    {/*</button>*/}
                                                    <button
                                                        className={
                                                            "open-orders-cancel-button" +
                                                            isOpenOrdersButtonVisibleClass
                                                        }
                                                        onClick={handleCancelSelectedOrders}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </>
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                        </>
                    ) : null}
                </>
                {/*) : null}*/}

                <form
                    onKeyPress={handleEnterKeyPress}
                    onSubmit={handleSignalFormSubmit}
                    className="dash-trade-interface__signal-form custom-mui-form custom-mui-form--half-width"
                >
                    {selectedSymbolData.symbol ? (
                        <div className="dash-trade-interface__selected-symbol">
                            <h2>{selectedSymbolData.symbol_with_slash}</h2>

                            <p>
                                {selectedSymbolData.price}{" "}
                                <span>{selectedSymbolData.symbol_2} </span>
                            </p>
                        </div>
                    ) : null}
                    <div className="dash-trade-interface__input-row ">
                        <AutoCompleteInput
                            handleChange={handleAutocompleteChange}
                            handleClick={handleAutocompleteClick}
                            value={signalFormInputFields.symbol}
                            helperText={errors ? errors.symbol : ""}
                            error={errors ? errors.symbol || false : false}
                        />
                        <SelectInput
                            onChange={handleSideInputChange}
                            menuItemArray={["sell", "buy"]}
                            id="dash-trade-interface-side-input"
                            value={signalFormInputFields.side}
                            name="side"
                            label="side"
                            variant="outlined"
                            helperText={errors ? errors.side : ""}
                            error={errors ? errors.side || false : false}
                        />
                    </div>
                    <div className="dash-trade-interface__input-row">
                        <div>
                            <TextField
                                id="dash-trade-interface-quantity-input"
                                type="number"
                                variant="outlined"
                                label="quantity"
                                name="quantity"
                                onChange={handleQuantityInputChange}
                                value={signalFormInputFields.quantity}
                                inputProps={{step: "0.00000001", min: "0"}}
                                helperText={errors ? errors.quantity : ""}
                                error={errors ? errors.quantity || false : false}
                            />

                            {/*<div style={{marginTop: 30}}>*/}
                            {/*    <AltCoinSlider*/}
                            {/*        value={sliderValue}*/}
                            {/*        onChange={onSliderChange}*/}
                            {/*        valueLabelFormat={(t) => {*/}
                            {/*            return (t * 100).toFixed(0) + "%";*/}
                            {/*        }}*/}
                            {/*        disabled={isSliderDisabled()}*/}
                            {/*        valueLabelDisplay="auto"*/}
                            {/*        step={0.25}*/}
                            {/*        min={0}*/}
                            {/*        max={1}*/}
                            {/*        marks={[*/}
                            {/*            {*/}
                            {/*                value: 0,*/}
                            {/*                label: "0%"*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                value: 0.25,*/}
                            {/*                label: "25%"*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                value: 0.5,*/}
                            {/*                label: "50%"*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                value: 0.75,*/}
                            {/*                label: "75%"*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                value: 1,*/}
                            {/*                label: "100%"*/}
                            {/*            },*/}
                            {/*        ]}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>

                        <SelectInput
                            onChange={handleTypeChange}
                            menuItemArray={orderTypeArray}
                            id="dash-trade-interface-side-type"
                            value={signalFormInputFields.type}
                            name="type"
                            label="type"
                            variant="outlined"
                            helperText={errors ? errors.type : ""}
                            error={errors ? errors.type || false : false}
                        />
                    </div>

                    <div className="dash-trade-interface__input-row">
                        <div className="dash-trade-interface__slider-container">
                            <span className="dash-trade-interface__user-profile-data--title">
                                Optionally, adjust quantity with slider
                            </span>

                            <div style={{height: 10}}/>

                            <AltCoinSlider
                                value={sliderValue}
                                onChange={onSliderChange}
                                valueLabelFormat={(t) => {
                                    return (t * 100).toFixed(0) + "%";
                                }}
                                disabled={isSliderDisabled()}
                                valueLabelDisplay="auto"
                                step={0.25}
                                min={0}
                                max={1}
                                marks={[
                                    {
                                        value: 0,
                                        label: "0%"
                                    },
                                    {
                                        value: 0.25,
                                        label: "25%"
                                    },
                                    {
                                        value: 0.5,
                                        label: "50%"
                                    },
                                    {
                                        value: 0.75,
                                        label: "75%"
                                    },
                                    {
                                        value: 1,
                                        label: "100%"
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <div className="dash-trade-interface__input-row dash-trade-interface__input-row--three">
                        {renderTimeInForceInput()}
                        {renderStopPriceInput()}
                        {renderPriceInput()}
                        {/*{renderIcebergQuantityInput()}*/}
                    </div>

                    <Button
                        type="solid"
                        header={false}
                        element="input"
                        value="Dispatch"
                    />
                </form>
            </DashComponent>
        </section>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getEligibleUsers: () => dispatch(getEligibleUsers()),
    sendSignal: (formData) => dispatch(sendSignal(formData)),
    getUserBalances: (userId) => dispatch(getUserBalances(userId)),
    getOpenOrders: (userId) => dispatch(getOpenOrders(userId)),
    cancelSelectedOrders: (payload) => dispatch(cancelSelectedOrders(payload)),
    sellSelectedOrders: (payload) => dispatch(sellSelectedOrders(payload)),
    clearUserBalances: () => dispatch(clearUserBalances()),
});

const mapStateToProps = ({
                             tradeInterface: {
                                 userList,
                                 balances,
                                 openOrders,
                                 isPending,
                                 errors,
                                 success,
                             },
                             user: {data},
                         }) => ({
    userList,
    isPending,
    signalerId: data.id,
    balances,
    errors,
    success,
    openOrders,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashTradeInterface);
