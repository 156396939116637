import { Urls, getRequest } from '../../api';
import { DashHomeTypes } from './dash-home.types';
import { SnackbarTypes } from '../Snackbar/snackbar.types';
import {convertTimeZonePreserveDate} from "../../utils/date_utils";

export const getStatistics = ({
  startDate,
  endDate,
  queryId,
  searchType,
}) => dispatch => {
  dispatch({ type: DashHomeTypes.GET_STATISTICS_PENDING });

  getRequest(
    Urls.server,
    `/statistics?startDate=${convertTimeZonePreserveDate(startDate)}&endDate=${convertTimeZonePreserveDate(endDate)}&queryId=${queryId}&searchType=${searchType}`,
  )
    .then(data =>
      dispatch({ type: DashHomeTypes.GET_STATISTICS_SUCCESS, payload: data }),
    )
    .catch(error => {
      dispatch({ type: DashHomeTypes.GET_STATISTICS_FAILED });
      dispatch({
        type: SnackbarTypes.OPEN_SNACKBAR,
        payload: error.response.data.snackbar,
      });
    });
};
