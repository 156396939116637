import {convertObjectToQueryString} from "../SignalHistory/signal-history.actions";
import {WithdrawalsAndDepositsTypes} from "./withdrawals-and-deposits.types";
import {Urls, getRequest} from '../../api/index';
import {SnackbarTypes} from "../Snackbar/snackbar.types";

const handleWithdrawalsAndDepositsQueryError = (error, dispatch) => {
    if (error.response.data.snackbar) {
        dispatch({
            type: WithdrawalsAndDepositsTypes.WAD_FILTER_QUERY_FAILED,
            payload: null,
        });
        dispatch({
            type: SnackbarTypes.OPEN_SNACKBAR,
            payload: error.response.data.snackbar,
        });
    } else {
        dispatch({
            type: WithdrawalsAndDepositsTypes.WAD_FILTER_QUERY_FAILED,
        });
    }
};

export const filterWithdrawalsAndDeposits = formData => dispatch => {
    const queryString = convertObjectToQueryString(formData);

    dispatch({type: WithdrawalsAndDepositsTypes.WAD_FILTER_QUERY_PENDING});

    getRequest(Urls.server, `/binance/get-withdrawals-and-deposits${queryString}`)
        .then(data =>
            dispatch({
                type: WithdrawalsAndDepositsTypes.WAD_FILTER_QUERY_SUCCESS,
                payload: data,
            }),
        )
        .catch(error => handleWithdrawalsAndDepositsQueryError(error, dispatch));
};

export const forceNewWithdrawalsDepositsGet = () => {
    return {
        type: WithdrawalsAndDepositsTypes.WAD_FILTER_QUERY_FORCE_NEW,
        payload: null,
    }
};

export const clearWithdrawalsAndDeposits = () => {
    return {
        type: WithdrawalsAndDepositsTypes.WAD_CLEAR,
        payload: null,
    }
};
