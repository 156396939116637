export const SettingsTypes = {
  GET_SETTINGS_PENDING: 'GET_SETTINGS_PENDING',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILED: 'GET_SETTINGS_FAILED',
  SUBMIT_TRADE_PROFILE_PENDING: 'SUBMIT_TRADE_PROFILE_PENDING',
  SUBMIT_TRADE_PROFILE_SUCCESS: 'SUBMIT_TRADE_PROFILE_SUCCESS',
  SUBMIT_TRADE_PROFILE_FAILED: 'SUBMIT_TRADE_PROFILE_FAILED',
  CHANGE_PASSWORD_PENDING: 'CHANGE_PASSWORD_PENDING',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
  UPDATE_COIN_BLACKLIST_PENDING: 'UPDATE_COIN_BLACKLIST_PENDING',
  UPDATE_COIN_BLACKLIST_SUCCESS: 'UPDATE_COIN_BLACKLIST_SUCCESS',
  UPDATE_COIN_BLACKLIST_FAILED: 'UPDATE_COIN_BLACKLIST_FAILED',
  UPDATE_NOTIFICATIONS_PENDING: 'UPDATE_NOTIFICATIONS_PENDING',
  UPDATE_NOTIFICATIONS_SUCCESS: 'UPDATE_NOTIFICATIONS_SUCCESS',
  UPDATE_NOTIFICATIONS_FAILED: 'UPDATE_NOTIFICATIONS_FAILED',
  UPDATE_ACCOUNT_STATUS_PENDING: 'UPDATE_ACCOUNT_STATUS_PENDING',
  UPDATE_ACCOUNT_STATUS_SUCCESS: 'UPDATE_ACCOUNT_STATUS_SUCCESS',
  UPDATE_ACCOUNT_STATUS_FAILED: 'UPDATE_ACCOUNT_STATUS_FAILED',
  EDIT_BINANCE_CREDENTIALS_PENDING: 'EDIT_BINANCE_CREDENTIALS_PENDING',
  EDIT_BINANCE_CREDENTIALS_SUCCESS: 'EDIT_BINANCE_CREDENTIALS_SUCCESS',
  EDIT_BINANCE_CREDENTIALS_FAILED: 'EDIT_BINANCE_CREDENTIALS_FAILED',
};
