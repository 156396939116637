import {DashHomeTypes} from './dash-home.types';

const INITIAL_STATE = {
    statistics: {
        netProfitBTC: 0,
        netProfitUSDT: 0,
        trades: 0,
        portfolioValue: 0,
        portfolioBTC: 0,
        startingBalanceUSDT: 0,
        startingBalanceBTC: 0,
        withdrawTotalUSDT: 0,
        withdrawTotalBTC: 0,
        depositTotalUSDT: 0,
        depositTotalBTC: 0,
        performanceChartData: [],
        coinAllocationChartData: [],
        tradeFrequencyChartData: [],
        assetList: [],
    },
    isPending: false,
};

const dashHomeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DashHomeTypes.GET_STATISTICS_PENDING:
            return {...state, isPending: true, statistics: {...state.statistics, assetList: []}};

        case DashHomeTypes.GET_STATISTICS_SUCCESS:
            const {
                netProfitBTC,
                netProfitUSDT,
                trades,
                portfolioValue,
                portfolioBTC,
                startingBalanceUSDT,
                startingBalanceBTC,
                withdrawTotalUSDT,
                withdrawTotalBTC,
                depositTotalUSDT,
                depositTotalBTC,
                performanceChartData,
                coinAllocationChartData,
                tradeFrequencyChartData,
                assetList,
            } = action.payload;

            return {
                ...state,
                isPending: false,
                statistics: {
                    netProfitBTC,
                    netProfitUSDT,
                    trades,
                    portfolioValue,
                    portfolioBTC,
                    startingBalanceUSDT,
                    startingBalanceBTC,
                    withdrawTotalUSDT,
                    withdrawTotalBTC,
                    depositTotalUSDT,
                    depositTotalBTC,
                    performanceChartData,
                    coinAllocationChartData,
                    tradeFrequencyChartData,
                    assetList,
                },
            };

        case DashHomeTypes.GET_STATISTICS_FAILED:
            return {...state, isPending: false};

        default:
            return state;
    }
};

export default dashHomeReducer;
