import React from 'react';

import { ReactComponent as SuccessIcon } from '../../static/svg/check-and-circle.svg';
import Button from '../../components/Button';

import './MessageSuccess.scss';

const MessageSuccess = () => (
  <section className="message-success">
    <div className="message-success__content">
      <SuccessIcon />
      <div className="message-success__message">
        <h3 className="sub-section-headline">Success!</h3>
        <p className="body-text">
          Thank you for your time. We will get back to you as soon as possible.
        </p>
      </div>
    </div>
  </section>
);

export default MessageSuccess;
