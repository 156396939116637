import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReCaptcha from "react-google-recaptcha";

import CircularLoader from "../../../components/CircularLoader";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import { registerUser } from "../../../redux/Registration/registration.actions";
import { clearErrors } from "../../../redux/ClearErrors/clear-errors.actions";
import { openSnackbar } from "../../../redux/Snackbar/snackbar.actions";
import site_keys from "../../../site_keys";

import "./AuthForm.scss";
import {CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon} from "@material-ui/icons";
import {Checkbox} from "@material-ui/core";

const RegistrationForm = ({
  registerUser,
  isPending,
  didSucceed,
  errors,
  registrationCode,
  history,
}) => {
  const initialState = {
    email: "",
    username: "",
    password: "",
    agree: false,
    reCaptchaToken: "",
    regCodeValue: "",
    regCodeSubmittted: "",
    submitted: false,
  };

  let reCaptchaRef = useRef();

  const [fieldInputs, setFieldInputs] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (didSucceed && submitted) history.push("/accounts/login");
  }, [didSucceed, history, submitted]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const { reCaptchaToken } = fieldInputs;
    if (!reCaptchaToken) {
      reCaptchaRef.current.reset();
    }

    fieldInputs.registrationCode =
      registrationCode || fieldInputs.regCodeSubmittted;

    setSubmitted(true);
    registerUser(fieldInputs);
  };

  const handleInputChange = (e) => {
    e.persist();
    setFieldInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    reCaptchaRef.current.reset();
    reCaptchaRef.current.execute();
  }, []);

  const handleCaptchaChange = (reCaptchaToken) => {
    setFieldInputs((prevState) => ({ ...prevState, reCaptchaToken }));
  };

  const handleTokenSubmit = (e) => {
    e.preventDefault();
    setFieldInputs((prevState) => ({
      ...prevState,
      regCodeSubmittted: fieldInputs.regCodeValue,
    }));
  };

  const handleReEnterToken = () => {
    setFieldInputs((prevState) => ({
      ...prevState,
      regCodeSubmittted: "",
      regCodeValue: "",
    }));
    clearErrors();
    history.push("/accounts/register");
  };

  const handleCheckboxInputChange = () => {
    setFieldInputs((prevState) => ({
      ...prevState,
      agree: !prevState.agree,
    }));
  }

  const loadingClass = isPending ? "loading" : "";

  return (
    <>
      <ReCaptcha
        ref={reCaptchaRef}
        size="invisible"
        sitekey={site_keys.recaptcha}
        theme="dark"
        badge="bottom-right"
        onChange={handleCaptchaChange}
      />
      <div className={`auth-form ${loadingClass}`}>
        {isPending ? (
          <CircularLoader classNames={loadingClass} size={100} />
        ) : null}
        {!registrationCode && !fieldInputs.regCodeSubmittted ? (
          <div className="auth-form__success">
            <h2 className="sub-section-headline sub-section-headline--white">
              Registration Code
            </h2>

            <form className="auth-form__form" onSubmit={handleTokenSubmit}>
              <TextInput
                id="registration-code"
                type="text"
                name="regCodeValue"
                variant="outlined"
                value={fieldInputs.regCodeValue}
                onChange={handleInputChange}
                label="registration code"
                fullWidth={true}
                borderColor="white"
                helperText={errors ? errors.registrationCode : ""}
                error={errors ? errors.registrationCode || false : false}
              />

              <div className="auth-form__checkbox-container">
                {fieldInputs.agree ? (
                    <Checkbox
                        icon={<CheckBoxIcon fontSize="large" style={{color: "#f1ab61"}}/>}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        value={fieldInputs.agree}
                        name="email"
                        onChange={handleCheckboxInputChange}
                    />
                ) : (
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" style={{color: "#f1ab61"}}/>}
                        checkedIcon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        value={fieldInputs.agree}
                        name="email"
                        onChange={handleCheckboxInputChange}
                    />
                )}
                <p>
                  I agree to the <Link to="/terms-and-conditions">Terms and Conditions</Link> & <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
              </div>

              <Button
                type="solid"
                value="continue"
                element="input"
                header={false}
                disabled={fieldInputs.agree !== true}
              />
            </form>
          </div>
        ) : (
          <>
            <h2 className="sub-section-headline sub-section-headline--white">
              Create an account
            </h2>
            <form onSubmit={handleFormSubmit} className="auth-form__form">
              <TextInput
                id="username"
                type="text"
                name="username"
                variant="outlined"
                label="username"
                fullWidth={true}
                borderColor="white"
                value={fieldInputs.username}
                onChange={handleInputChange}
                helperText={errors ? errors.username : ""}
                error={errors ? errors.username || false : false}
              />
              <TextInput
                borderColor="white"
                id="email"
                name="email"
                type="text"
                variant="outlined"
                label="email"
                fullWidth={true}
                value={fieldInputs.email}
                onChange={handleInputChange}
                helperText={errors ? errors.email : ""}
                error={errors ? errors.email || false : false}
              />
              <TextInput
                borderColor="white"
                id="password"
                name="password"
                type="password"
                variant="outlined"
                label="password"
                fullWidth={true}
                value={fieldInputs.password}
                onChange={handleInputChange}
                helperText={errors ? errors.password : ""}
                error={errors ? errors.password || false : false}
              />
              <Button
                type="solid"
                value="register"
                element="input"
                header={false}
              />
            </form>

            <div className="auth-form__links-container">
              <span>
                <p className="auth-form__text">
                  <button
                    onClick={handleReEnterToken}
                    className="auth-form__link auth-form__link--button"
                  >
                    Re-Enter Token
                  </button>
                </p>
                <p className="auth-form__text">
                  Already a member?{" "}
                  <Link
                    onClick={clearErrors}
                    className="auth-form__link"
                    to="/accounts/login"
                  >
                    Login
                  </Link>
                </p>
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

RegistrationForm.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  registerUser: (formData) => dispatch(registerUser(formData)),
  clearErrors: () => dispatch(clearErrors()),
  openSnackbar: (message) => dispatch(openSnackbar(message)),
});

const mapStateToProps = ({
  registration: { isPending, errors, didSucceed },
}) => ({
  isPending,
  errors,
  didSucceed,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegistrationForm));
