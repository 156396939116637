import React, {useEffect} from "react";
import { connect } from "react-redux";

import { filterSignals } from "../../../redux/SignalHistory/signal-history.actions";
import DashboardLoading from "../../../components/DashboardLoading";
import SignalTableRow from "./SignalTableRow";
import { ReactComponent as FirstChevron } from "../../../static/svg/page-chevron-first.svg";
import { ReactComponent as LastChevron } from "../../../static/svg/page-chevron-last.svg";
import socket from "../../../socketIO";
import "./SignalsTableDisplay.scss";

const TableDisplay = ({
  headerTitleArray,
  removeHeaderAtSmallArray,
  totalPages,
  signals,
  isPending,
  page = 1,
  filterSignals,
  currentFilter,
  lowerDisplay,
  upperDisplay,
  totalSignals,
  role
}) => {
  const resultsPerPage = 5;

  socket.on("SIGNAL_RECORDED", () => {
    if (page) filterSignals({ ...currentFilter, page: !isPending && page });
  });

  const renderSignalTableRows = () =>
    signals.map((row, i) => (
      <SignalTableRow key={row._id} data={row} index={i} role={role}/>
    ));

  const renderTableHeaders = () =>
    headerTitleArray.map(title => {
      let header;

      if (removeHeaderAtSmallArray.includes(title))
        header = (
          <span
            key={title}
            className="signals-table-display__header-title remove-at-sm"
          >
            {title}
          </span>
        );
      else if (!removeHeaderAtSmallArray.includes(title))
        header = (
          <span key={title} className="signals-table-display__header-title">
            {title}
          </span>
        );
      return header;
    });

  const createPageLinkHandler = i => () =>
    filterSignals({ ...currentFilter, page: i });

  const renderPagination = () => {
    let paginationArr = [];
    const indexValue = Math.ceil(page / resultsPerPage);
    const startIndex = Math.ceil(indexValue * resultsPerPage - 4);
    const pageNumberLimit =
      indexValue * resultsPerPage > totalPages
        ? totalPages
        : indexValue * resultsPerPage;

    for (let i = startIndex; i <= pageNumberLimit; i++) {
      if (page === i)
        paginationArr.push(
          <span className="signals-table-display__pagination-link current" key={i}>
            {i}
          </span>
        );
      else {
        paginationArr.push(
          <button
            className="signals-table-display__pagination-link"
            key={i}
            onClick={createPageLinkHandler(i)}
          >
            {i}
          </button>
        );
      }
    }
    return paginationArr;
  };

  const handleFirstPageClick = () => {
    filterSignals({ ...currentFilter, page: 1 });
  };

  const handleReverseFivePages = () => {
    filterSignals({
      ...currentFilter,
      page:
        Math.ceil(page / resultsPerPage) !== 1
          ? (Math.ceil(page / resultsPerPage) - 1) * resultsPerPage
          : resultsPerPage
    });
  };

  const handleSkipForwardFivePages = () => {
    filterSignals({
      ...currentFilter,
      page:
        Math.ceil(page / resultsPerPage) <
        Math.ceil(totalPages / resultsPerPage)
          ? Math.ceil(page / resultsPerPage + 1) * 5 - 4
          : totalPages
    });
  };

  const handleLastPageClick = () => {
    filterSignals({ ...currentFilter, page: totalPages });
  };

  return (
    <>
      {isPending ? <DashboardLoading /> : null}
      <div className="signals-table-display">
        <div className="signals-table-display__table-header">
          {renderTableHeaders()}
        </div>

        <div className="signals-table-display__table-body">
          {signals.length === 0 ? (
            <div className="signals-table-display__no-results">
              <span>NO SIGNALS FOUND</span>
            </div>
          ) : null}
          {renderSignalTableRows()}
        </div>

        {totalSignals !== undefined && (
            <div className="show-at-sm">
              <div className="signals-table-display__pagination">
                <span className="signals-table-display__pagination-total">{`Showing ${lowerDisplay} - ${upperDisplay} of ${totalSignals}`}</span>
              </div>
            </div>
        )}

        <div className="signals-table-display__pagination">
          {totalSignals !== undefined && (
            <span className="signals-table-display__pagination-total remove-at-sm">{`Showing ${lowerDisplay} - ${upperDisplay} of ${totalSignals}`}</span>
          )}

          <button disabled={page === 1} onClick={handleFirstPageClick}>
            First
          </button>
          <button
            disabled={Math.ceil(page / resultsPerPage) === 1}
            onClick={handleReverseFivePages}
          >
            <FirstChevron className="pagination__double-chevron" />
          </button>

          {renderPagination()}

          <button
            disabled={
              Math.ceil(page / resultsPerPage) * resultsPerPage >= totalPages
            }
            onClick={handleSkipForwardFivePages}
          >
            <LastChevron className="pagination__double-chevron" />
          </button>
          <button disabled={page === totalPages} onClick={handleLastPageClick}>
            Last
          </button>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  filterSignals: filterData => dispatch(filterSignals(filterData))
});

const mapStateToProps = ({
  signalHistory: { totalPages, signals, isPending, page, currentFilter, lowerDisplay, upperDisplay, totalSignals }
}) => ({
  totalPages,
  signals,
  isPending,
  page,
  currentFilter,
  lowerDisplay,
  upperDisplay,
  totalSignals,
});

export default connect(mapStateToProps, mapDispatchToProps)(TableDisplay);
