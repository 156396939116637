import { ContactFormTypes } from './contact-form.types';
import { ClearErrorsTypes } from '../ClearErrors/clear-errors.types';

const INITIAL_STATE = {
  errors: null,
  isPending: false,
  didSucceed: false,
};

const contactFormReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContactFormTypes.SEND_MESSAGE_PENDING:
      return { ...state, isPending: true, errors: null };

    case ContactFormTypes.SEND_MESSAGE_FAILED:
      return { ...state, isPending: false, errors: action.payload };

    case ContactFormTypes.SEND_MESSAGE_SUCCESS:
      return { ...state, isPending: false, didSucceed: true, errors: null };

    case ClearErrorsTypes.CLEAR_ERRORS:
      return { ...state, errors: null };

    case ContactFormTypes.RESET_CONTACT_FORM:
      return { ...state, didSucceed: false };

    default:
      return state;
  }
};

export default contactFormReducer;
