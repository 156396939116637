import React, {useEffect} from "react";

const TradingViewWidget = ({symbol_1, symbol_2}) => {
    var symbolPair = symbol_1 + symbol_2;
    
    useEffect(() => {
      var binanceSymbolPair = "BINANCE:" + symbolPair;

      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        interval: '1m',
        width: 350,
        height: 375,
        isTransparent: false,
        symbol: binanceSymbolPair,
        showIntervalTabs: true,
        locale: 'en',
        colorTheme: 'light',
      });
      document.getElementsByClassName('tradingview-widget-container__widget')[0].appendChild(script);
  
      return () => {
        document.getElementsByClassName('tradingview-widget-container__widget')[0].innerHTML = '';
      };
    }, [symbolPair]);
  
    return (
      <div className="tradingview-widget-container">
        <div className="tradingview-widget-container__widget"></div>
      </div>
    );
  };
  
  export default TradingViewWidget;