import React from 'react';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';

import Button from '../Button';

import './RegisterCTA.scss';

const RegisterCTA = ({ handleEnterBounds, handleLeaveBounds }) => (
  <Waypoint onEnter={handleEnterBounds} onLeave={handleLeaveBounds}>
    <div className="register-cta">
      <h2 className="section-headline section-headline--white">
        Start Winning Now
      </h2>
      <Button
        header={false}
        element="link"
        type="solid"
        href="/accounts/register"
      >
        Register
      </Button>
    </div>
  </Waypoint>
);

RegisterCTA.propTypes = {
  handleEnterBounds: PropTypes.func,
  handleLeaveBounds: PropTypes.func,
};

export default RegisterCTA;
