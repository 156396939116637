import { TradeInterfaceTypes } from "./trade-interface.types";

const INITIAL_STATE = {
  userList: [],
  balances: [],
  openOrders: [],
  isPending: false,
  errors: null,
  success: null,
};

const tradeInterfaceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TradeInterfaceTypes.TRADE_INTERFACE_REQUEST_PENDING:
      return { ...state, isPending: true, success: null, errors: null };

    case TradeInterfaceTypes.SEND_TRADE_SIGNAL_SUCCESS:
      return { ...state, isPending: false, success: true, errors: null };

    case TradeInterfaceTypes.SEND_TRADE_SIGNAL_FAILED:
      return {
        ...state,
        isPending: false,
        errors: action.payload,
        success: false,
      };

    case TradeInterfaceTypes.GET_ELIGIBLE_USERS_SUCCESS:
      return { ...state, isPending: false, userList: action.payload };

    case TradeInterfaceTypes.GET_ELIGIBLE_USERS_FAILED:
      return { ...state, isPending: false, userList: [] };

    case TradeInterfaceTypes.GET_USER_BALANCES_PENDING:
      return { ...state, isPending: true };
    case TradeInterfaceTypes.GET_USER_BALANCES_SUCCESS:
      return { ...state, isPending: false, balances: action.payload.balances };
    case TradeInterfaceTypes.GET_USER_BALANCES_FAILED:
      return { ...state, isPending: false, balances: [] };

    case TradeInterfaceTypes.CLEAR_USER_BALANCES:
      return { ...state, balances: [] };

    case TradeInterfaceTypes.GET_OPEN_ORDERS_PENDING:
      return { ...state, isPending: true };
    case TradeInterfaceTypes.GET_OPEN_ORDERS_SUCCESS:
      return {
        ...state,
        isPending: false,
        openOrders: action.payload.openOrders,
      };
    case TradeInterfaceTypes.GET_OPEN_ORDERS_FAILED:
      return { ...state, isPending: false, openOrders: [] };

    case TradeInterfaceTypes.CANCEL_SELECTED_ORDERS_PENDING:
      return { ...state, isPending: true };
    case TradeInterfaceTypes.CANCEL_SELECTED_ORDERS_SUCCESS:
      return { ...state, isPending: false };
    case TradeInterfaceTypes.CANCEL_SELECTED_ORDERS_FAILED:
      return { ...state, isPending: false };
    case TradeInterfaceTypes.SELL_SELECTED_ORDERS_PENDING:
      return { ...state, isPending: true };
    case TradeInterfaceTypes.SELL_SELECTED_ORDERS_SUCCESS:
      return { ...state, isPending: false };
    case TradeInterfaceTypes.SELL_SELECTED_ORDERS_FAILED:
      return { ...state, isPending: false };
    default:
      return state;
  }
};

export default tradeInterfaceReducer;
