import {SymbolsTypes} from "./symbols.types";
import {getRequest, Urls} from "../../api";
import {handleError} from "../utils.redux";
import {SnackbarTypes} from "../Snackbar/snackbar.types";

export const getSymbols = () => dispatch => {

    dispatch({type: SymbolsTypes.GET_SYMBOLS_PENDING});

    getRequest(Urls.server, "/binance/get-symbols")
        .then(data =>
            dispatch({
                type: SymbolsTypes.GET_SYMBOLS_SUCCESS,
                payload: data,
            })
        )
        .catch(error =>
            handleError({
                error,
                dispatch,
                type: SymbolsTypes.GET_SYMBOLS_FAILED,
                snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR
            })
        )
};
