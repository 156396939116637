import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import CircularLoader from '../../../components/CircularLoader';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import { setNewPassword } from '../../../redux/Recovery/recovery.actions';
import { clearErrors } from '../../../redux/ClearErrors/clear-errors.actions';

import './AuthForm.scss';

const NewPasswordForm = ({
  isPending,
  errors,
  clearErrors,
  setNewPassword,
  recoveryCode,
  didSucceed,
  history,
}) => {
  const INITIAL_STATE = {
    password: '',
  };

  const [fieldInputs, setFieldInputs] = useState(INITIAL_STATE);

  const handleFormSubmit = e => {
    e.preventDefault();
    setNewPassword({ password: fieldInputs.password, recoveryCode });
    clearInputs();
  };

  const clearInputs = () => {
    setFieldInputs(INITIAL_STATE);
  };

  const handleInputChange = e => {
    e.persist();
    setFieldInputs(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const pushToLoginOnSuccess = didSucceed => {
      if (didSucceed) history.push('/accounts/login');
    };

    pushToLoginOnSuccess(didSucceed);
  }, [didSucceed, history]);

  const loadingClass = isPending ? 'loading' : '';

  return (
    <div className={`auth-form ${loadingClass}`}>
      {isPending ? (
        <CircularLoader classNames={loadingClass} size={100} />
      ) : null}
      <h2 className="sub-section-headline sub-section-headline--white">
        New Password
      </h2>

      <form onSubmit={handleFormSubmit} className="auth-form__form">
        <TextInput
          id="recover-new-password-input"
          type="password"
          name="password"
          variant="outlined"
          label="password"
          fullWidth={true}
          borderColor="white"
          value={fieldInputs.password}
          onChange={handleInputChange}
          helperText={errors ? errors.password : ''}
          error={errors.password ? errors.password || false : false}
        />
        <Button
          type="solid"
          value="change password"
          element="input"
          header={false}
        />
      </form>

      <div className="auth-form__links-container">
        <span>
          <p className="auth-form__text">
            I would like to{' '}
            <Link
              onClick={clearErrors}
              className="auth-form__link"
              to="/accounts/login"
            >
              Login
            </Link>
          </p>
        </span>
      </div>
    </div>
  );
};

NewPasswordForm.propTypes = {};

const mapDispatchToProps = dispatch => ({
  setNewPassword: formData => dispatch(setNewPassword(formData)),
  clearErrors: () => dispatch(clearErrors()),
});

const mapStateToProps = ({ recovery: { isPending, errors, didSucceed } }) => ({
  isPending,
  errors,
  didSucceed,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NewPasswordForm));
