import React, { useState, useEffect } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { getProfileSettings } from '../../redux/Settings/settings.actions';
import DashboardMenu from './DashboardMenu';
import DashHome from '../../scenes/DashHome';
import DashTradeInterface from '../../scenes/DashTradeInterface';
import DashSignalHistory from '../../scenes/DashSignalHistory';
import DashActivity from '../../scenes/DashActivity';
import DashSettings from '../../scenes/DashSettings';
import DashInvoicing from '../../scenes/DashInvoicing';
import SignalModal from './SignalModal';
import MessageModal from './MessageModal';
import './Dashboard.scss';
import InvoiceModal from "./InvoiceModal";
import AssignInvoiceModal from "./AssignInvoiceModal";
import DashUserInvoicing from "../../scenes/DashUserInvoicing";
import UserInvoiceModal from "./UserInvoiceModal";

const Dashboard = ({
  location,
  user,
  dashMenuIsOpen,
  messageModalIsOpen,
  getProfileSettings,
  signalModalIsOpen,
  invoiceModalIsOpen,
  userInvoiceModalIsOpen,
  assignInvoiceModalIsOpen
}) => {
  const { role } = user;

  const [route, setRoute] = useState('');

  const menuOpenClass =
    dashMenuIsOpen || signalModalIsOpen || invoiceModalIsOpen || userInvoiceModalIsOpen || messageModalIsOpen || assignInvoiceModalIsOpen
      ? 'menu-open'
      : '';

  useEffect(() => {
    getProfileSettings();
  }, []);

  useEffect(() => {
    const splitPathnameArray = location.pathname.split('/');
    const lastNameInPath =
      splitPathnameArray.length === 2
        ? splitPathnameArray[1]
        : splitPathnameArray[2];

    setRoute(lastNameInPath);
  }, [location]);

  document.body.style.overflow =
    dashMenuIsOpen || signalModalIsOpen || messageModalIsOpen
      ? 'hidden'
      : 'auto';

  document.documentElement.style.overflowY =
    dashMenuIsOpen || signalModalIsOpen || messageModalIsOpen
      ? 'hidden'
      : 'auto';

  document.body.style.overflow = signalModalIsOpen ? 'hidden' : 'auto';

  return (
    <main className={`dashboard ${menuOpenClass}`}>
      {signalModalIsOpen ? <SignalModal /> : null}
      {invoiceModalIsOpen ? <InvoiceModal /> : null}
      {userInvoiceModalIsOpen ? <UserInvoiceModal /> : null}
      {assignInvoiceModalIsOpen ? <AssignInvoiceModal/> : null}

      {messageModalIsOpen ? <MessageModal /> : null}
      <DashboardMenu route={route} user={user} />
      <div className="dashboard-content">
        <Switch>
          <Route exact path="/dashboard/home" render={() => <DashHome />} />
          <Route
            exact
            path="/dashboard/trade-interface"
            render={() => <DashTradeInterface role={role} />}
          />
          <Route
            exact
            path="/dashboard/signal-history"
            render={() => <DashSignalHistory role={role} />}
          />
          <Route
              exact
              path="/dashboard/user-invoices"
              render={() => <DashUserInvoicing role={role} />}
          />
          <Route
              exact
              path="/dashboard/invoicing"
              render={() => <DashInvoicing role={role} />}
          />
          <Route
            path="/dashboard/activity-and-notifications"
            render={() => <DashActivity />}
          />
          <Route
            path="/dashboard/settings"
            render={props => <DashSettings {...props} role={role} />}
          />
        </Switch>
      </div>
    </main>
  );
};

const mapDispatchToProps = dispatch => ({
  getProfileSettings: () => dispatch(getProfileSettings()),
});

const mapStateToProps = ({ dashMenu, messageModal, signalModal, invoiceModal, assignInvoiceModal }) => ({
  dashMenuIsOpen: dashMenu.isOpen,
  messageModalIsOpen: messageModal.isOpen,
  signalModalIsOpen: signalModal.isOpen,
  invoiceModalIsOpen: invoiceModal.isOpen,
  userInvoiceModalIsOpen: invoiceModal.userIsOpen,
  assignInvoiceModalIsOpen: assignInvoiceModal.isOpen,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Dashboard));
