import React, { useState } from "react";
import { connect } from "react-redux";

import { sendSignalerRegistrationLink } from "../../../redux/DashActivity/dash-activity.actions";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import Button from "../../../components/Button";
import "./RegisterSignalerForm.scss";

const INITIAL_STATE = {
  email: "",
  first_name: "",
  last_name: "",
  role: "user"
};

const RegisterSignalerForm = ({
  sendSignalerRegistrationLink,
  errors,
  isPending,
  linkSent
}) => {
  const [formData, setFormData] = useState(INITIAL_STATE);

  const handleFormSubmit = e => {
    e.preventDefault();
    sendSignalerRegistrationLink(formData);
    if (linkSent && !isPending) setFormData(INITIAL_STATE);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="register-signaler-form">
      <p className="body-text">
        Send an email invite to the application sign up.{" "}
        <span className="warning">
          This will allow them to potentially register as a signaler or admin.
        </span>
      </p>
      <form
        onSubmit={handleFormSubmit}
        className="register-signaler-form__form custom-mui-form"
      >
        <TextInput
          id="register-signaler-email-input"
          type="email"
          variant="outlined"
          label="email"
          fullWidth={true}
          name="email"
          onChange={handleInputChange}
          value={formData.email}
          helperText={errors ? errors.email : ""}
          error={errors ? errors.email || false : false}
        />
        <TextInput
          id="register-signaler-first-name-input"
          type="text"
          variant="outlined"
          label="First Name"
          fullWidth={true}
          name="first_name"
          onChange={handleInputChange}
          value={formData.first_name}
          helperText={errors ? errors.first_name : ""}
          error={errors ? errors.first_name || false : false}
        />
        <TextInput
          id="register-signaler-last-name-input"
          type="text"
          variant="outlined"
          label="Last Name"
          fullWidth={true}
          name="last_name"
          onChange={handleInputChange}
          value={formData.last_name}
          helperText={errors ? errors.last_name : ""}
          error={errors ? errors.last_name || false : false}
        />
        <SelectInput
          value={formData.role}
          onChange={handleInputChange}
          menuItemArray={["user", "signaler", "superAdmin"]}
          id="egister-signaler-role-input"
          name="role"
          label="Role"
        />
        <Button type="solid" header={false} element="input" value="send" />
      </form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  sendSignalerRegistrationLink: email =>
    dispatch(sendSignalerRegistrationLink(email))
});

const mapStateToProps = ({
  dashActivity: {
    errors,
    sendSignalerRegistrationLinkIsPending,
    sendSignalerRegistrationLinkSuccess
  }
}) => ({
  errors,
  isPending: sendSignalerRegistrationLinkIsPending,
  linkSent: sendSignalerRegistrationLinkSuccess
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSignalerForm);
