import {InvoicesTypes} from "./invoices.types";
import {Urls, getRequest, postRequest, putRequest} from '../../api/index';
import {WithdrawalsAndDepositsTypes} from "../WithdrawalsAndDeposits/withdrawals-and-deposits.types";
import {convertObjectToQueryString} from "../SignalHistory/signal-history.actions";
import {SnackbarTypes} from "../Snackbar/snackbar.types";
import {handleError} from "../utils.redux";
import {DashActivityTypes} from "../DashActivity/dash-activity.types";
import {InvoiceModalTypes} from "../InvoiceModal/invoice-modal.types";
import {filterWithdrawalsAndDeposits} from "../WithdrawalsAndDeposits/withdrawals-and-deposits.actions";

export const handleInvoicesQueryError = (error, dispatch) => {
    if (error.response.data.snackbar) {
        dispatch({
            type: InvoicesTypes.INVOICES_FAILED,
            payload: null,
        });
        dispatch({
            type: SnackbarTypes.OPEN_SNACKBAR,
            payload: error.response.data.snackbar,
        });
    } else {
        dispatch({
            type: InvoicesTypes.INVOICES_FAILED,
        });
    }
};

export const getInvoiceTableData = formData => dispatch => {
    dispatch({type: InvoicesTypes.INVOICES_PENDING});

    const queryString = (formData && Object.keys(formData).length > 0) ? convertObjectToQueryString(formData) : "";

    getRequest(Urls.server, `/invoices/get-list${queryString}`)
        .then(data =>
            dispatch({
                type: InvoicesTypes.INVOICES_SUCCESS,
                payload: data,
            }),
        )
        .catch(error => handleInvoicesQueryError(error, dispatch));
};

export const getUsersInvoiceTableData = formData => dispatch => {
    dispatch({type: InvoicesTypes.INVOICES_PENDING});

    const queryString = (formData && Object.keys(formData).length > 0) ? convertObjectToQueryString(formData) : "";

    getRequest(Urls.server, `/invoices/user-get-list${queryString}`)
        .then(data =>
            dispatch({
                type: InvoicesTypes.INVOICES_SUCCESS,
                payload: data,
            }),
        )
        .catch(error => handleInvoicesQueryError(error, dispatch));
};

export const paidInvoiceExternally = (formData) => dispatch => {
    dispatch({type: InvoicesTypes.INVOICES_PENDING});

    putRequest(Urls.server, "/invoices/mark-as-paid-externally", formData)
        .then(data => {
            dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
            dispatch({ type: InvoiceModalTypes.INVOICE_MODAL_CLOSE });
            dispatch(getInvoiceTableData({page: 1, pendingOnly: false}));
        })
        .catch(error =>
            handleError({
                error,
                dispatch,
                type: InvoicesTypes.PAY_EXTERNALLY_INVOICE_FAILED,
                snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR
            })
        )
};

// parameter for this function has 2 fields, one for the formData, one for the withdrawals/deposits filter to re-fetch that data as well.
export const unPairInvoice = (unPairInvoiceObject) => dispatch => {
    dispatch({type: InvoicesTypes.INVOICES_PENDING});

    putRequest(Urls.server, "/invoices/unpair", unPairInvoiceObject.formData)
        .then(data => {
            dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
            dispatch({ type: InvoiceModalTypes.INVOICE_MODAL_CLOSE });
            dispatch(getInvoiceTableData({page: 1, pendingOnly: false}));
            if (unPairInvoiceObject.withdrawalsFilter && unPairInvoiceObject.withdrawalsFilter.user_id && unPairInvoiceObject.withdrawalsFilter.user_id.length > 0) {
                dispatch(filterWithdrawalsAndDeposits({...unPairInvoiceObject.withdrawalsFilter, page: 1}));
            }
        })
        .catch(error =>
            handleError({
                error,
                dispatch,
                type: InvoicesTypes.UN_PAIR_INVOICE_FAILED,
                snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR
            })
        );
};
