import { RecoveryTypes } from './recovery.types';
import { Urls, postRequest } from '../../api';
import { SnackbarTypes } from '../Snackbar/snackbar.types';

const createRecoveryActionCreator = endpoint => formData => dispatch => {
  dispatch({ type: RecoveryTypes.RECOVER_FORM_PENDING });

  postRequest(Urls.server, endpoint, formData)
    .then(data => {
      dispatch({ type: RecoveryTypes.RECOVER_FORM_SUCCESS });
      dispatch({ type: SnackbarTypes.OPEN_SNACKBAR, payload: data.snackbar });
    })
    .catch(error => {
      if (error.response.data.snackbar) {
        dispatch({ type: RecoveryTypes.RECOVER_FORM_FAILED, payload: {} });
        dispatch({
          type: SnackbarTypes.OPEN_SNACKBAR,
          payload: error.response.data.snackbar,
        });
      } else {
        dispatch({
          type: RecoveryTypes.RECOVER_FORM_FAILED,
          payload: error.response.data,
        });
      }
    });
};

export const recoverPassword = createRecoveryActionCreator('/recover/password');
export const recoverUsername = createRecoveryActionCreator('/recover/username');
export const setNewPassword = createRecoveryActionCreator(
  '/recover/new-password',
);
export const resetRecoverForm = () => dispatch =>
  dispatch({ type: RecoveryTypes.RECOVER_FORM_RESET });
