import React from "react";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import RegisterCTA from "../../components/RegisterCTA";

import "../../scss/typography.scss";
import "./AboutUs.scss";

const AboutUs = () => (
  <main className="about-us">
    <PageTitle title="about us" />
    <section className="about-us__content">
      {/* <h2 className="sub-section-headline">Altcoin Ninjas</h2> */}
      <p className="body-text">
        Our organization employs many altcoin strategists focused on every
        aspect of cryptocurrency. From mining to ICO’s, we have specialists in
        each field to guide new members, as well as experts. We are very serious
        about cryptocurrency, and have a fast growing talented team. Our CEO is
        very active in the community and be found on discord, discussing crypto
        with our members. head office is located in 15047 Marine Dr #200, White
        Rock, BC V4B 1C5, Canada.
        <a
          href="https://www.linkedin.com/in/george-samaris-853573a4/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textTransform: "none" }}
        >
          Check him out on Linkedin
        </a>
      </p>

      <h2 className="sub-section-headline">Trading platform</h2>
      <p className="body-text">
        This trading platform was created by our team to alleviate stress of
        manual trading. We watch the markets 24/7 and make the best possible
        decisions for a desirable outcome.
      </p>
      <p className="body-text">
        When you are using our platform:
        <br />
        <br />
        <ul className="body-text default-ul-style">
          <li>You don't need to keep track all the crypto news and trends.</li>
          <li>You don't need to know TA.</li>
          <li>You don't need to purchase an expensive subscribtion.</li>
          <li>You don't need to be gamble.</li>
        </ul>
      </p>

      <p className="body-text">
        Just connect your Binance account, set up your trading profile and
        preferred notifications and let everything else to be taken care of by
        our professionals. We are not able to withdraw your funds or deposit.
        Your money is safe in your own hands. You will receive daily/weekly
        reports so you can keep track of our progress. You will be charged only
        when you start making profit.
      </p>

      <div className="about-us__feature about-us__feature--computer-bg">
        <span className="about-us__feature-title">
          Easy to use, free to start
        </span>
      </div>
      <div className="about-us__feature about-us__feature--lock-bg">
        <span className="about-us__feature-title">
          Secure trades, made on your behalf
        </span>
      </div>
      <p className="body-text">
        Go to our{` `}
        <Link to="/guide">guide</Link> to learn about how to set up your trading
        profile, connect your binance account, and more.
      </p>
    </section>
    <RegisterCTA />
  </main>
);

export default AboutUs;
