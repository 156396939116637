import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';

import { filterSignals } from '../../redux/SignalHistory/signal-history.actions';
import Button from '../Button';
import TextInput from '../TextInput';
import { ReactComponent as CalendarIcon } from '../../static/svg/calendar-icon.svg';
import './SignalHistoryFilters.scss';
import SelectInput from "../SelectInput";
import {getEligibleUsers} from "../../redux/TradeInterface/trade-interface.actions";
import AutoCompleteInput from "../../scenes/DashTradeInterface/AutoCompleteInput";
import {convertTimeZonePreserveDate} from "../../utils/date_utils";

const INITIAL_STATE = {
  startDate: moment().startOf("day").subtract(6, "days"),
  endDate: moment().endOf("day"),
  focusedInput: null,
  symbol: '',
  username: '',
};

export const clearUserNameDropDownString = "X CLEAR USERNAME";

const SignalHistoryFilters = ({ role, filterSignals, getEligibleUsers, userList }) => {
  const [fieldInputs, setFieldInputs] = useState(INITIAL_STATE);

  useEffect(() => {
    if (role === 'signaler' || role === 'superAdmin') {
      getEligibleUsers();
    }
  }, []);

  const falseFunc = () => false;

  const handleDatesChange = ({ startDate, endDate }) =>
    setFieldInputs(prevState => ({
      ...prevState,
      startDate: startDate ? moment(startDate).startOf('day') : null,
      endDate: endDate ? moment(endDate).endOf('day') : null,
    }));

  const handleFocusChange = focusedInput =>
    setFieldInputs(prevState => ({ ...prevState, focusedInput }));

  const handleInputChange = e => {
    e.persist();
    setFieldInputs(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUserFormInputChange = e => {
    if (e.target.value === clearUserNameDropDownString) {
      setFieldInputs({
        ...fieldInputs,
        username: "",
      });
      return;
    }
    const currentUserIndex = userList.map((user) => user.username).indexOf(e.target.value);
    setFieldInputs({
      ...fieldInputs,
      username: userList[currentUserIndex].username,
    });
  };

  const handleAutocompleteClick = (parts) => {
    const combineParts = (parts) => parts.map((part) => part.text).join("");

    setFieldInputs({
      ...fieldInputs,
      symbol: combineParts(parts),
    });
  };

  const removeSlashFromSymbolPair = (symbolPair) => {
    return symbolPair.replace(/\//g, "");
  };

  const handleFilterSubmit = e => {
    e.preventDefault();

    let { symbol, username, startDate, endDate } = fieldInputs;

    startDate = startDate ? startDate.valueOf() : '';
    endDate = endDate ? endDate.valueOf() : '';

    const formData = {
      symbol: symbol ? removeSlashFromSymbolPair(symbol.toUpperCase()) : '',
      username: username ? username.toLowerCase() : '',
      startDate: convertTimeZonePreserveDate(startDate),
      endDate: convertTimeZonePreserveDate(endDate),
    };
    filterSignals(formData);
  };
  const getUserListDropDown = () => {
    const _userList = userList.map((user) => user.username);
    _userList.splice(0, 0, clearUserNameDropDownString);
    return _userList;
  };

  return (
    <form onSubmit={handleFilterSubmit} className="dash-component-filters">
      {role === 'signaler' || role === 'superAdmin' ? (
          <React.Fragment>
            <SelectInput
                id="signal-history-username-filter"
                label="username"
                fullWidth={true}
                name="username"
                variant="outlined"
                onChange={handleUserFormInputChange}
                menuItemArray={getUserListDropDown()}
                value={fieldInputs.username}
                condensed={true}
                classNames="text-input user-drop-down"
                style={{minWidth: 300}}
            />
          </React.Fragment>
      ) : null}

      <div className="auto-complete-parent">
        <AutoCompleteInput
            handleChange={handleInputChange}
            handleClick={handleAutocompleteClick}
            value={fieldInputs.symbol}
            classNames="text-input"
        />
      </div>

      <div className="dash-component-filters__date-picker-wrapper">
        <CalendarIcon className="calendar-icon" />
        <DateRangePicker
          startDate={fieldInputs.startDate}
          startDateId="signal-history-start-date"
          startDatePlaceholderText="Start Date"
          endDate={fieldInputs.endDate}
          endDateId="signal-history-end-date"
          endDatePlaceholderText="End Date"
          onDatesChange={handleDatesChange}
          focusedInput={fieldInputs.focusedInput}
          onFocusChange={handleFocusChange}
          noBorder={true}
          small={true}
          numberOfMonths={1}
          isOutsideRange={falseFunc}
          showClearDates={true}
        />
      </div>
      <Button type="solid" header={false} element="input" value="Search" />
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  getEligibleUsers: () => dispatch(getEligibleUsers()),
  filterSignals: formData => dispatch(filterSignals(formData)),
});

const mapStateToProps = ({
  tradeInterface: {
    userList,
  }
}) => ({
  userList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignalHistoryFilters);
