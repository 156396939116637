import React, {useEffect} from "react";
import {connect} from "react-redux";

import {convertDateTimeToCurrentTimezone} from "../../../format";
import {ReactComponent as CloseIcon} from "../../../static/svg/close-icon.svg";
import {ReactComponent as CancelSignalIcon} from "../../../static/svg/cancel-signal-icon.svg";

import "./InvoiceModal.scss";
import {cancelInvoice, closeInvoiceModal} from "../../../redux/InvoiceModal/invoice-modal.actions";
import Button from "../../Button";
import {paidInvoiceExternally, unPairInvoice} from "../../../redux/Invoices/invoices.actions";

const DataGroup = ({label, data, labelColorClass, className}) => (
    <div className={"data-group" + (className ? " " + className : "")}>
        <span className={`data-group__label ${labelColorClass}`}>{label}</span>
        <span className="data-group__data">{data}</span>
    </div>
);

const InvoiceModal = ({role, isOpen, invoiceData, closeInvoiceModal, cancelInvoice, paidInvoiceExternally, unPairInvoice, withdrawalsFilter}) => {

    useEffect(() => {
        const handleClickOnBackground = (e) => {
            const {classList} = e.target;

            if (classList.contains("dashboard") && isOpen) closeInvoiceModal();
        };

        window.addEventListener("click", handleClickOnBackground);

        return () => {
            window.removeEventListener("click", handleClickOnBackground);
        };
    }, [isOpen, closeInvoiceModal]);

    function onSubmit(e) {
        if (e) {e.preventDefault()}
        cancelInvoice(invoiceData);
    }

    function onPaidExternally(e) {
        if (e) {e.preventDefault()}
        paidInvoiceExternally({invoice_id: invoiceData.id});
    }

    function onUnPair(e) {
        if (e) {e.preventDefault()}
        // unPairInvoice({invoice_id: invoiceData.id}, withdrawalsFilter);
        unPairInvoice({formData: {invoice_id: invoiceData.id}, withdrawalsFilter});
    }

    return (
        <div className="invoice-modal">
            <CloseIcon
                aria-label="close"
                onClick={closeInvoiceModal}
                className="invoice-modal__close-btn"
            />
            <div className="invoice-modal__content">
                <div className="invoice-modal__row">
                    <DataGroup
                        label="invoice id"
                        data={invoiceData.id}
                        labelColorClass="data-group__label--green"
                    />
                </div>

                <div className="invoice-modal__row">
                    <DataGroup
                        label="created"
                        data={convertDateTimeToCurrentTimezone({
                            dateTime: invoiceData.created_at,
                            format: "h:mm a - M/D/YYYY"
                            // format: "M/D/YYYY"
                        })}
                        labelColorClass="data-group__label--blue"
                    />
                    <DataGroup
                        label="username"
                        data={invoiceData.username}
                        labelColorClass="data-group__label--blue"
                    />
                    <DataGroup
                        label="amount"
                        data={invoiceData.amount}
                        labelColorClass="data-group__label--blue"
                    />
                </div>
                <div className="invoice-modal__row">
                    <DataGroup
                        label="Withdraw ID"
                        data={invoiceData.withdraw_id ? invoiceData.withdraw_id : "N/A"}
                        labelColorClass="data-group__label--yellow"
                        className="word-break-all-helper"
                    />
                    <DataGroup
                        label="reason"
                        data={invoiceData.reason ? invoiceData.reason : "N/A"}
                        labelColorClass="data-group__label--yellow"
                    />

                    <DataGroup
                        label="status"
                        data={invoiceData.paid_externally ? "Paid Externally" : (invoiceData.withdraw_id ? "Paid" : "Pending")}
                        labelColorClass="data-group__label--yellow"
                    />
                </div>
                {((role === "signaler" || role === "superAdmin") && (!invoiceData.withdraw_id || invoiceData.withdraw_id.length === 0) && (invoiceData.paid_externally === false)) ? (
                    <div>
                        <div className="invoice-modal__cancel-signal" style={{marginBottom: 30, paddingBottom: 0}}>
                            <form onSubmit={onSubmit}>
                                <Button type="solid" header={false} element="input" value="Cancel this Invoice" />
                            </form>
                        </div>

                        <div className="invoice-modal__cancel-signal" style={{marginTop: 30, paddingTop: 0}}>
                            <form onSubmit={onPaidExternally}>
                                <Button type="solid" header={false} element="input" value="This Invoice was Paid Externally" />
                            </form>
                        </div>
                    </div>
                ) : null}

                {((role === "signaler" || role === "superAdmin") && (invoiceData.withdraw_id) && (invoiceData.paid_externally === false)) ? (
                    <div className="invoice-modal__cancel-signal">
                        <form onSubmit={onUnPair}>
                            <Button type="solid" header={false} element="input" value="Un-Pair this Invoice" />
                        </form>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    closeInvoiceModal: () => dispatch(closeInvoiceModal()),
    cancelInvoice: (invoiceData) => dispatch(cancelInvoice(invoiceData)),
    paidInvoiceExternally: (invoiceData) => dispatch(paidInvoiceExternally(invoiceData)),
    unPairInvoice: (invoiceData) => dispatch(unPairInvoice(invoiceData)),
});

const mapStateToProps = ({invoiceModal: {isOpen, invoiceData}, user: {data: {role}}, withdrawalsAndDeposits}) => ({
    isOpen,
    invoiceData,
    role,
    withdrawalsFilter: withdrawalsAndDeposits.currentFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModal);
