import React, {useState, useEffect} from "react";
import moment from "moment";
import {connect} from "react-redux";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";

import {ReactComponent as CloseIcon} from "../../../static/svg/close-icon.svg";
import RadioButton from "../../../components/RadioButton";
import AutoCompleteUsers from "../../../components/AutoCompleteUsers";
import Button from "../../../components/Button";
import DateRangeInput from "../../../components/DateRangeInput";
import {getStatistics} from "../../../redux/DashHome/dash-home.actions";
import {getAllUsers} from "../../../redux/Users/users.actions";
import {updateShowGreeting} from "../../../redux/User/user.actions";
import "./FilterForm.scss";
import SelectInput from "../../../components/SelectInput";
import {clearUserNameDropDownString} from "../../../components/SignalHistoryFilters";
import {getEligibleUsers} from "../../../redux/TradeInterface/trade-interface.actions";
import {getAllUserTypes} from "../../../redux/AllUserTypes/all-user-types.actions";

const startDate = moment().startOf("day").subtract(6, "days");
const endDate = moment().endOf("day");

const INITIAL_STATE = {
    startDate: startDate,
    endDate: endDate,
    focusedInput: null,
    username: "",
    searchType: "allTrades"
};

const getUserObjectInUsers = ({username, users}) => {
    const [user] = users.filter(user => user.username === username);
    return user;
};

const FilterForm = ({
                        username,
                        role,
                        getStatistics,
                        getAllUsers,
                        getEligibleUsers,
                        getAllUserTypes,
                        users,
                        showGreeting,
                        updateShowGreeting
                    }) => {
    const [fieldInputs, setFieldInputs] = useState(INITIAL_STATE);

    useEffect(() => {
        if (role === "superAdmin" || role === "signaler") getAllUserTypes();
    }, []);

    const handleDatesChange = ({startDate, endDate}) =>
        setFieldInputs(prevState => ({
            ...prevState,
            startDate: startDate ? moment(startDate).startOf("day") : null,
            endDate: endDate ? moment(endDate).endOf("day") : null
        }));

    const handleFocusChange = focusedInput =>
        setFieldInputs(prevState => ({...prevState, focusedInput}));

    const handleAutocompleteChange = (e, {newValue}) => {
        e.persist();
        setFieldInputs(prevState => ({
            ...prevState,
            [e.target.name]: newValue
        }));
    };

    const handleUserFormInputChange = e => {
        if (e.target.value === clearUserNameDropDownString) {
            setFieldInputs({
                ...fieldInputs,
                username: "",
            });
            return;
        }
        const currentUserIndex = users.map((user) => user.username).indexOf(e.target.value);
        setFieldInputs({
            ...fieldInputs,
            username: users[currentUserIndex].username,
        });
    };

    const getUserListDropDown = () => {
        const _userList = users.map((user) => user.username);
        _userList.splice(0, 0, clearUserNameDropDownString);
        return _userList;
    };

    const handleRadioGroupChange = e => {
        setFieldInputs(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const handleAutocompleteClick = parts => {
        const combineParts = parts => parts.map(part => part.text).join("");

        setFieldInputs(prevState => ({
            ...prevState,
            username: combineParts(parts)
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();

        const {startDate, endDate, username, searchType} = fieldInputs;

        const user = getUserObjectInUsers({users, username});

        const filterData = {
            startDate: startDate ? startDate.valueOf() : "",
            endDate: endDate ? endDate.valueOf() : "",
            queryId: user ? user.id : "",
            searchType
        };

        getStatistics(filterData);
    };

    const handleCloseGreeting = () => updateShowGreeting(false);

    const getWelcomeMessage = role => {
        if (role === "user")
            return "This is your portfolio home page, where you can sort and visualize all of your account data";
        else if (role === "signaler")
            return "This is your signaler home page, where you can sort and visualize all of your filled orders";
        else if (role === "superAdmin")
            return "This is your admin home page, where you can sort and visualize user and signaler trade data";
    };

    const flexClass =
        (role !== "superAdmin" && role !== "signaler") ? "filter-form__date-range-container--row" : "";

    return (
        <div className={`filter-form`}>
            {showGreeting ? (
                <div className="filter-form__welcome-container">
                    <button
                        onClick={handleCloseGreeting}
                        className="filter-form__welcome-container-close-button"
                    >
                        <CloseIcon/>
                    </button>
                    <h3 className="dash-component__title">Hi {username}</h3>
                    <p>{getWelcomeMessage(role)}</p>
                </div>
            ) : null}

            <form onSubmit={handleSubmit}>
                {(role === "superAdmin" || role === "signaler") ? (
                    <div className="filter-form__admin-filters">
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="Search Type"
                                name="searchType"
                                value={fieldInputs.searchType}
                                onChange={handleRadioGroupChange}
                            >
                                <RadioButton
                                    label="all trades"
                                    value="allTrades"
                                    type="light"
                                    checked={fieldInputs.searchType === "allTrades"}
                                />
                                <RadioButton
                                    label="search by username"
                                    value="searchByUsername"
                                    type="light"
                                    checked={fieldInputs.searchType === "searchByUsername"}
                                />
                            </RadioGroup>
                        </FormControl>
                        {fieldInputs.searchType === "allTrades" ? null : (
                            <>
                                {/*<AutoCompleteUsers*/}
                                {/*  handleChange={handleAutocompleteChange}*/}
                                {/*  handleClick={handleAutocompleteClick}*/}
                                {/*  value={fieldInputs.username}*/}
                                {/*  suggestions={users}*/}
                                {/*/>*/}
                                <div className="home-page-stats-username-filter-selector">
                                    <SelectInput
                                        id="home-page-stats-username-filter"
                                        label="username"
                                        fullWidth={true}
                                        name="username"
                                        variant="outlined"
                                        onChange={handleUserFormInputChange}
                                        menuItemArray={getUserListDropDown()}
                                        value={fieldInputs.username}
                                        condensed={true}
                                        classNames="text-input user-drop-down"
                                        style={{width: "200px !important"}}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                ) : null}
                <div className={`filter-form__date-range-container ${flexClass}`}>
                    <DateRangeInput
                        startDate={fieldInputs.startDate}
                        startDateId="dash-home-start-date"
                        startDatePlaceholderText="Start Date"
                        endDate={fieldInputs.endDate}
                        endDateId="dash-home-end-date"
                        endDatePlaceholderText="End Date"
                        onDatesChange={handleDatesChange}
                        focusedInput={fieldInputs.focusedInput}
                        onFocusChange={handleFocusChange}
                    />
                    <Button type="solid" header={false} element="input" value="search"/>
                </div>
            </form>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    getStatistics: filterData => dispatch(getStatistics(filterData)),
    getAllUsers: () => dispatch(getAllUsers()),
    getAllUserTypes: () => dispatch(getAllUserTypes()),
    getEligibleUsers: () => dispatch(getEligibleUsers()),
    updateShowGreeting: showGreeting => dispatch(updateShowGreeting(showGreeting))
});

const mapStateToProps = ({
                             user: {
                                 data: {username, role, showGreeting}
                             },
                             users: {users},
                             tradeInterface: {
                                 userList,
                             },
                             allUserTypes: {
                                 allUsers,
                             }
                         }) => ({
    username,
    role,
    // users: userList,
    users: allUsers,
    showGreeting
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
