export const TradeInterfaceTypes = {
  TRADE_INTERFACE_REQUEST_PENDING: "TRADE_INTERFACE_REQUEST_PENDING",
  GET_ELIGIBLE_USERS_SUCCESS: "GET_ELIGIBLE_USERS_SUCCESS",
  GET_ELIGIBLE_USERS_FAILED: "GET_ELIGIBLE_USERS_FAILED",
  SEND_TRADE_SIGNAL_SUCCESS: "SEND_TRADE_SIGNAL_SUCCESS",
  SEND_TRADE_SIGNAL_FAILED: "SEND_TRADE_SIGNAL_FAILED",
  GET_USER_BALANCES_PENDING: "GET_USER_BALANCES_PENDING",
  GET_USER_BALANCES_SUCCESS: "GET_USER_BALANCES_SUCCESS",
  GET_USER_BALANCES_FAILED: "GET_USER_BALANCES_FAILED",
  CLEAR_USER_BALANCES: "CLEAR_USER_BALANCES",
  GET_OPEN_ORDERS_PENDING: "GET_OPEN_ORDERS_PENDING",
  GET_OPEN_ORDERS_SUCCESS: "GET_OPEN_ORDERS_SUCCESS",
  GET_OPEN_ORDERS_FAILED: "GET_OPEN_ORDERS_FAILED",
  CANCEL_SELECTED_ORDERS_PENDING: "CANCEL_SELECTED_ORDERS_PENDING",
  CANCEL_SELECTED_ORDERS_SUCCESS: "CANCEL_SELECTED_ORDERS_SUCCESS",
  CANCEL_SELECTED_ORDERS_FAILED: "CANCEL_SELECTED_ORDERS_FAILED",
  SELL_SELECTED_ORDERS_PENDING: "SELL_SELECTED_ORDERS_PENDING",
  SELL_SELECTED_ORDERS_SUCCESS: "SELL_SELECTED_ORDERS_SUCCESS",
  SELL_SELECTED_ORDERS_FAILED: "SELL_SELECTED_ORDERS_FAILED",
};
