import React, { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import axios from "axios";
import PropTypes from "prop-types";
import moment from "moment";

import { Urls, getRequest } from "../../../../api";
import DisplayBox from "./DisplayBox";
import RChevron from "../../../../static/svg/chevron-right.svg";
import LChevron from "../../../../static/svg/chevron-left.svg";

import "./CryptoSlider.scss";

const CryptoSlider = ({ coinArray }) => {
  const [rates, setRates] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [isSliderEntered, setIsSliderEntered] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;

    if (width >= 1280) setEndIndex(3);
    else setEndIndex(rates.length - 2);
  }, [JSON.stringify(rates)]);

  const handleEnterSlider = () => setIsSliderEntered(true);

  const handleClickNext = () => {
    if (currentIndex < endIndex) {
      if (prevDisabled === true) setPrevDisabled(prevState => !prevState);
      setCurrentIndex(prevState => (prevState += 1));
    }
    if (currentIndex === endIndex - 1) {
      setNextDisabled(true);
    }
  };

  const handleClickPrev = () => {
    if (currentIndex >= 1) {
      if (nextDisabled === true) setNextDisabled(false);
      setCurrentIndex(prevState => (prevState -= 1));
    }
    if (currentIndex === 1) setPrevDisabled(true);
  };

  const revealSliderClass = isSliderEntered ? "reveal" : "";
  const revealArrowsClass = rates.length === coinArray.length ? "reveal" : "";
  const nextDisabledClass = nextDisabled ? "disabled" : "";
  const prevDisabledClass = prevDisabled ? "disabled" : "";

  const requestCoinStatsFromApi = async symbol => {
    const data = await getRequest(Urls.coincap, `/assets?search=${symbol}`);

    let coin;
    if (!data.data.length) {
      //SET ERROR MESSAGE FOR COMPONENT
      // return;
      coin = {
        symbol: undefined,
        price: undefined,
        timestamp: moment()
      };
    } else {
      const [coinData] = await data.data;
      coin = {
        symbol: coinData.symbol,
        price: coinData.priceUsd,
        timestamp: moment()
      };
    }

    setRates(prevState => [...prevState, coin]);
  };

  // Takes an array of symbols and retreives exchange data from cryptonator api, adds returned data to rates state
  const getRates = symbols => {
    symbols.forEach(requestCoinStatsFromApi);
  };

  // Helper functions
  const resetSlider = () => {
    setCurrentIndex(0);
    setPrevDisabled(true);
    setNextDisabled(false);
  };

  useEffect(() => {
    getRates(coinArray);

    const setEndIndexOnWidth = e => {
      const windowWidth = e.target.innerWidth;
      if (windowWidth >= 1280) {
        setEndIndex(coinArray.length - 3);
        resetSlider();
      } else {
        setEndIndex(coinArray.length - 1);
        resetSlider();
      }
    };

    // const width = window.innerWidth;
    //
    // if (width >= 1280) setEndIndex(3);
    // else setEndIndex(5);

    window.addEventListener("resize", setEndIndexOnWidth);

    return () => {
      window.removeEventListener("resize", setEndIndexOnWidth);
    };
  }, [coinArray]);

  const filteredResponse = rates.filter(r => r.symbol !== undefined);

  return (
    <Waypoint onEnter={handleEnterSlider} bottomOffset="35%">
      <div className="crypto-slider">
        <button
          onClick={handleClickPrev}
          className={`crypto-slider__btn crypto-slider__btn--left  ${prevDisabledClass} ${revealArrowsClass}`}
        >
          <img
            src={LChevron}
            alt="left arrow"
            className="crypto-slider__btn-icon"
          />
        </button>

        <div className={`crypto-slider__display ${revealSliderClass}`}>
          {(filteredResponse && filteredResponse.length > 0) && (
            <div
              className="crypto-slider__display-wrapper"
              style={{ transform: `translateX(-${currentIndex * (100 / filteredResponse.length)}%)` }}
            >
              {/*{rates.length === coinArray.length ? (*/}
              {rates.length > 0 ? (
                  filteredResponse.map(coin => (
                  <DisplayBox key={JSON.stringify(coin.symbol)} coin={coin} />
                ))
              ) : (
                <div className="crypto-slider__loading">
                  <div className="crypto-slider__loading-bounce crypto-slider__loading-bounce--1" />
                  <div className="crypto-slider__loading-bounce crypto-slider__loading-bounce--2" />
                </div>
              )}
            </div>
          )}
        </div>
        <button
          className={`crypto-slider__btn crypto-slider__btn--right ${nextDisabledClass} ${revealArrowsClass}`}
          onClick={handleClickNext}
        >
          <img
            src={RChevron}
            alt="right arrow"
            className="crypto-slider__btn-icon"
          />
        </button>
      </div>
    </Waypoint>
  );
};

CryptoSlider.propTypes = {
  coinArray: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CryptoSlider;
