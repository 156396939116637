import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import './CircularLoader.scss';

const CircularLoader = ({ size, classNames }) => (
  <CircularProgress className={`circular-loader ${classNames}`} size={size} />
);

CircularLoader.propTypes = {
  size: PropTypes.number,
  classNames: PropTypes.string,
};

export default CircularLoader;
