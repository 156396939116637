import React, { useState } from "react";
import { connect } from "react-redux";

import {
  saveBinanceCredentials,
  clearBinanceCredentials
} from "../../../redux/Settings/settings.actions";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import CircleLoader from "../../../components/CircleLoader";
import DeleteIcon from "../../../static/svg/delete-icon.svg";
import "./BinanceForm.scss";

const BinanceForm = ({
  saveBinanceCredentials,
  binanceCredentialsComplete,
  errors,
  clearBinanceCredentials,

  binanceCredentialsPending
}) => {
  const initialState = { binanceApiKey: "", binanceApiSecret: "" };
  const [inputFields, setInputFields] = useState(initialState);

  const handleSubmitBinance = e => {
    e.preventDefault();
    saveBinanceCredentials(inputFields);
    setInputFields(initialState);
  };

  const handleClearCredClick = () => {
    clearBinanceCredentials();
  };

  const handleInputChange = e => {
    e.persist();
    setInputFields(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <div className="binance-form">
      {binanceCredentialsComplete ? (
        <div className="binance-form__acount-linked">
          <p className="body-text">Your secret and API key have been saved!</p>
          <div className="binance-form__btn-container">
            <button
              arial-label="delete"
              onClick={handleClearCredClick}
              className="binance-form__clear-btn"
            >
              <img src={DeleteIcon} alt="circle with a line through it" />
              Clear credentials
            </button>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmitBinance} className="binance-form__form">
          <TextInput
            id="binance-api-key-input"
            type="password"
            label="api key"
            fullWidth={true}
            name="binanceApiKey"
            variant="outlined"
            onChange={handleInputChange}
            value={inputFields.binanceApiKey}
            helperText={errors ? errors.binanceApiKey : ""}
            error={errors ? errors.binanceApiKey || false : false}
            disabled={binanceCredentialsPending}
          />
          <TextInput
            id="binance-api-secret-input"
            type="password"
            label="secret"
            fullWidth={true}
            name="binanceApiSecret"
            variant="outlined"
            onChange={handleInputChange}
            value={inputFields.binanceApiSecret}
            helperText={errors ? errors.binanceApiSecret : ""}
            error={errors ? errors.binanceApiSecret || false : false}
            disabled={binanceCredentialsPending}
          />
          <Button
            type="solid"
            header={false}
            element="button"
            actionType="submit"
            disabled={binanceCredentialsPending}
          >
            {binanceCredentialsPending ? <CircleLoader /> : "save"}
          </Button>
        </form>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  saveBinanceCredentials: formData =>
    dispatch(saveBinanceCredentials(formData)),
  clearBinanceCredentials: () => dispatch(clearBinanceCredentials())
});

const mapStateToProps = ({
  settings: { errors, binanceCredentialsPending }
}) => ({
  errors,
  binanceCredentialsPending
});

export default connect(mapStateToProps, mapDispatchToProps)(BinanceForm);
