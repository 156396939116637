import moment from "moment-timezone";

import {ReactComponent as DollarIcon} from "../../static/svg/dollar-icon.svg";
import {ReactComponent as TradeIcon} from "../../static/svg/trade-icon.svg";
import {ReactComponent as PortfolioValueIcon} from "../../static/svg/portfolio-value-icon.svg";
import {ReactComponent as BitcoinIcon} from "../../static/svg/portfolio-value-btc-icon.svg";

const date = 1562011505000;

const createProfitData = () => {
    const data = [];
    for (let i = 0; i <= 24; i++) {
        if (i % 5 === 0)
            data.push({
                x: date + i * 108000000,
                y: Math.random() * -5,
            });
        else
            data.push({
                x: date + i * 108000000,
                y: Math.random() * 8,
            });
    }
    return data;
};

const createTradeFreqData = () => {
    const data = [];
    for (let i = 0; i <= 24; i++) {
        data.push({
            x: date + i * 108000000,
            y: Math.floor(Math.random() * 4),
        });
    }

    return data;
};

const createTradeFreqBarData = () => {
    const data = [];
    for (let i = 0; i <= 24; i++) {
        const genData = Math.floor(Math.random() * 15);
        if (genData !== 0)
            data.push({
                time: moment(date + i * 108000000).tz("America/Vancouver").format("M/D"),
                Trades: genData,
            });
    }
    return data;
};

const profitDataArray = createProfitData();
const tradeFreqData = createTradeFreqData();

const statArray = [
    {
        key: "startingBalanceBTC",
        svg: BitcoinIcon,
        description: "starting balance btc",
        color: "yellow",
    },
    {
        key: "depositTotalBTC",
        svg: BitcoinIcon,
        description: "deposit total btc",
        color: "yellow",
    },
    {
        key: "startingBalanceUSDT",
        svg: DollarIcon,
        description: "starting balance usdt",
        color: "violet",
    },
    {
        key: "depositTotalUSDT",
        svg: DollarIcon,
        description: "deposit total usdt",
        color: "violet",
    },
    {
        key: "portfolioBTC",
        svg: PortfolioValueIcon,
        description: "portfolio in btc",
        color: "yellow",
    },
    {
        key: "withdrawTotalBTC",
        svg: BitcoinIcon,
        description: "withdraw total btc",
        color: "yellow",
    },
    {
        key: "portfolioValue",
        svg: PortfolioValueIcon,
        description: "portfolio in usdt",
        color: "violet",
    },
    {
        key: "withdrawTotalUSDT",
        svg: DollarIcon,
        description: "withdraw total usdt",
        color: "violet",
    },
    {
        key: "netProfitBTC",
        svg: BitcoinIcon,
        description: "profit in btc",
        color: "yellow",
    },
    {
        key: "trades",
        svg: TradeIcon,
        description: "trades",
        color: "blue",
    },
    {
        key: "netProfitUSDT",
        svg: DollarIcon,
        description: "profit in usdt",
        color: "violet",
    },
];

const profitData = [
    {
        id: "Profit (BTC)",
        data: profitDataArray,
    },
];

const tradeFreqDataSet = [
    {
        id: "Number of Trades",
        data: tradeFreqData,
    },
];

const tradeFreqBarData = createTradeFreqBarData();

const tableHeadList = [
    {title: "Total", align: "center"},
    {title: "Available", align: "center"},
    {title: "Locked", align: "center"},
    {title: "Value (BTC)", align: "center"},
    {title: "Value (USD)", align: "center"},
];

export {
    statArray,
    profitData,
    tradeFreqDataSet,
    tradeFreqBarData,
    tableHeadList,
};
