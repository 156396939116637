import React from 'react';
import { Link } from 'react-router-dom';

import './PageNotFound.scss';

const PageNotFound = () => (
  <main className="page-not-found">
    <div className="page-not-found__wrapper">
      <h2 className="sub-section-headline ">
        The page you are trying to reach is unavailable
      </h2>
      <p className="body-text">
        There are several different reasons why you are unable to reach the page
        that you want:
      </p>
      <ul className="page-not-found__reasons-list">
        <li>The link you used is outdated.</li>
        <li>The web address you entered is incorrect.</li>
        <li>The page you want no longer exists.</li>
      </ul>
      <p className="body-text">Let's get you going to where you want to be:</p>
      <ul className="page-not-found__reasons-list">
        <li>Check the web address to make sure it is correct.</li>
        <li>
          Visit the <Link to="/">Home Page</Link>.
        </li>
      </ul>
    </div>
  </main>
);

export default PageNotFound;
