import { UsersTypes } from './users.types';
import { SnackbarTypes } from '../Snackbar/snackbar.types';
import { Urls, getRequest } from '../../api';
import { handleValidationError, handleData } from '../utils.redux';

export const getAllUsers = () => dispatch => {
  dispatch({ type: UsersTypes.GET_USERS_PENDING });

  getRequest(Urls.server, '/users/all')
    .then(data =>
      handleData({ data, dispatch, type: UsersTypes.GET_USERS_SUCCESS }),
    )
    .catch(error =>
      handleValidationError({
        error,
        dispatch,
        type: UsersTypes.GET_USERS_FAILED,
        snackbarOpenType: SnackbarTypes.OPEN_SNACKBAR,
      }),
    );
};
