import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

import './Stats.scss';

const Stats = () => {
  const [areStatsEntered, setAreStatsEntered] = useState(false);

  const handleEnterStats = () => setAreStatsEntered(true);

  const revealStatsClass = areStatsEntered ? 'reveal' : '';

  return (
    <section className="stats">
      <Waypoint onEnter={handleEnterStats} bottomOffset="50%">
        <div className="stats__layout">
          <div className={`stats__item stats__item--left ${revealStatsClass}`}>
            <p className="stats__item-stat">5k+</p>
            <p className="stats__item-description">profit generating signals</p>
          </div>
          <div className={`stats__item stats__item--mid ${revealStatsClass}`}>
            <p className="stats__item-stat">0.03 btc</p>
            <p className="stats__item-description">average profit per signal</p>
          </div>
          <div className={`stats__item stats__item--right ${revealStatsClass}`}>
            <p className="stats__item-stat">10+</p>
            <p className="stats__item-description">signalers working for you</p>
          </div>
        </div>
      </Waypoint>
    </section>
  );
};

export default Stats;
