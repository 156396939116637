import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import CircularLoader from "../../../components/CircularLoader";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import { loginUser } from "../../../redux/Login/login.actions";
import { clearErrors } from "../../../redux/ClearErrors/clear-errors.actions";

import "./AuthForm.scss";

const LoginForm = ({
  loginUser,
  loginIsPending,
  userIsLoading,
  loginErrors,
  clearErrors
}) => {
  const INITIAL_STATE = {
    username: "",
    password: ""
  };

  const [fieldInputs, setFieldInputs] = useState(INITIAL_STATE);

  const handleFormSubmit = e => {
    e.preventDefault();
    loginUser(fieldInputs);
  };

  const handleInputChange = e => {
    e.persist();
    setFieldInputs(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const loadingClass = loginIsPending || userIsLoading ? "loading" : "";

  console.log(loadingClass)

  return (
    <div className={`auth-form ${loadingClass}`}>
      {loginIsPending || userIsLoading ? (
        <CircularLoader classNames={loadingClass} size={100} />
      ) : null}

      <h2 className="sub-section-headline sub-section-headline--white">
        Login
      </h2>

      <form onSubmit={handleFormSubmit} className="auth-form__form">
        <TextInput
          id="username"
          type="text"
          name="username"
          variant="outlined"
          label="username"
          fullWidth={true}
          borderColor="white"
          value={fieldInputs.username}
          onChange={handleInputChange}
          helperText={loginErrors ? loginErrors.username : ""}
          error={loginErrors ? loginErrors.username || false : false}
        />
        <TextInput
          borderColor="white"
          id="password"
          name="password"
          type="password"
          variant="outlined"
          label="password"
          fullWidth={true}
          value={fieldInputs.password}
          onChange={handleInputChange}
          helperText={loginErrors ? loginErrors.password : ""}
          error={loginErrors ? loginErrors.password || false : false}
        />
        <Button type="solid" value="login" element="input" header={false} />
      </form>

      <div className="auth-form__links-container">
        <Link
          onClick={clearErrors}
          className="auth-form__link"
          to="/accounts/recover-password"
        >
          recover password/username
        </Link>

        <span>
          <p className="auth-form__text">
            Not a member?{" "}
            <Link
              onClick={clearErrors}
              className="auth-form__link"
              to="/accounts/register"
            >
              Register
            </Link>
          </p>
        </span>
      </div>
    </div>
  );
};

LoginForm.propTypes = {};

const mapDispatchToProps = dispatch => ({
  loginUser: formData => dispatch(loginUser(formData)),
  clearErrors: () => dispatch(clearErrors())
});

const mapStateToProps = ({ login, user }) => ({
  loginIsPending: login.isPending,
  loginErrors: login.errors,
  userIsLoading: user.isLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
