import React, {useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./MuiBasicTable.scss";

const MuiBasicTable = ({
  rowList = [],
  headList = [],
  noDataMessage = "CONNECT BINANCE FOR DATA",
}) => {

  const [renderKey, setRenderKey] = useState(1);

  useEffect(() => {
    setRenderKey(renderKey + 1);
  }, [rowList.toString()])

  const noDataClass = rowList.length ? "" : "mui-table-paper--no-data";
  const headCells = headList.map(({ title, align }) => (
    <TableCell key={title} align={align}>
      {title}
    </TableCell>
  ));

  const bodyRows = rowList.map((row) => {
    const bodyCells = row.map(({ align, data }) => {
      return (<TableCell key={Math.random()} align={align}>
        {data}
      </TableCell>)
    });

    return <TableRow key={JSON.stringify(row.symbol) + Math.random()}>{bodyCells}</TableRow>;
  });

  return (
    <div key={renderKey} style={{width: "100%"}}>
      <Paper className={`mui-table-paper ${noDataClass}`}>
        <Table stickyHeader={true} className="mui-basic-table">
          <TableHead>
            <TableRow>{headCells}</TableRow>
          </TableHead>
          <TableBody>
            {rowList.length ? bodyRows : <h2>{noDataMessage}</h2>}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default MuiBasicTable;
