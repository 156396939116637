import React, {useEffect} from "react";
import {connect} from "react-redux";
import {filterWithdrawalsAndDeposits} from "../../../redux/WithdrawalsAndDeposits/withdrawals-and-deposits.actions";
import DashboardLoading from "../../../components/DashboardLoading";
import {ReactComponent as FirstChevron} from "../../../static/svg/page-chevron-first.svg";
import {ReactComponent as LastChevron} from "../../../static/svg/page-chevron-last.svg";
import WithdrawalsAndDepositsTableTableRow from "./WithdrawalsAndDepositsTableTableRow";
import "./WithdrawalsAndDepositsTableDisplay.scss";

const WithdrawalsAndDepositsTableDisplay = ({filterWithdrawalsAndDeposits, headerTitleArray, removeHeaderAtSmallArray, totalPages, transactions, isPending, page = 1, currentFilter, lowerDisplay, upperDisplay, totalWithdrawalsAndDeposits, selectedUser}) => {
    const resultsPerPage = 5;

    const renderTableRows = () => {
        return transactions.map((row, i) => (
            <WithdrawalsAndDepositsTableTableRow key={row._id} data={row} index={i} selectedUser={selectedUser}/>
        ));
    };

    const renderTableHeaders = () => {
        return headerTitleArray.map(title => {
            let header;

            if (removeHeaderAtSmallArray.includes(title)) {
                header = (<span key={title} className="withdrawals-and-deposits-table-display__header-title remove-at-sm">{title}</span>);
            } else {
                header = (<span key={title} className="withdrawals-and-deposits-table-display__header-title">{title}</span>);
            }
            return header;
        });
    };

    const createPageLinkHandler = i => () =>
        filterWithdrawalsAndDeposits({...currentFilter, page: i});

    const renderPagination = () => {
        let paginationArr = [];
        const indexValue = Math.ceil(page / resultsPerPage);
        const startIndex = Math.ceil(indexValue * resultsPerPage - 4);
        const pageNumberLimit =
            indexValue * resultsPerPage > totalPages
                ? totalPages
                : indexValue * resultsPerPage;

        for (let i = startIndex; i <= pageNumberLimit; i++) {
            if (page === i)
                paginationArr.push(
                    <span className="withdrawals-and-deposits-table-display__pagination-link current" key={i}>
            {i}
          </span>
                );
            else {
                paginationArr.push(
                    <button
                        className="withdrawals-and-deposits-table-display__pagination-link"
                        key={i}
                        onClick={createPageLinkHandler(i)}
                    >
                        {i}
                    </button>
                );
            }
        }
        return paginationArr;
    };

    const handleFirstPageClick = () => {
        filterWithdrawalsAndDeposits({ ...currentFilter, page: 1 });
    };

    const handleReverseFivePages = () => {
        filterWithdrawalsAndDeposits({
            ...currentFilter,
            page:
                Math.ceil(page / resultsPerPage) !== 1
                    ? (Math.ceil(page / resultsPerPage) - 1) * resultsPerPage
                    : resultsPerPage
        });
    };

    const handleSkipForwardFivePages = () => {
        filterWithdrawalsAndDeposits({
            ...currentFilter,
            page:
                Math.ceil(page / resultsPerPage) <
                Math.ceil(totalPages / resultsPerPage)
                    ? Math.ceil(page / resultsPerPage + 1) * 5 - 4
                    : totalPages
        });
    };

    const handleLastPageClick = () => {
        filterWithdrawalsAndDeposits({ ...currentFilter, page: totalPages });
    };

    return (
        <>
            {isPending ? <DashboardLoading /> : null}
            <div className="withdrawals-and-deposits-table-display">
                <div className="withdrawals-and-deposits-table-display__table-header">
                    {renderTableHeaders()}
                </div>

                <div className="withdrawals-and-deposits-table-display__table-body">
                    {(currentFilter.user_id === "" || currentFilter.user_id < 0)? (
                        <div className="withdrawals-and-deposits-table-display__no-results">
                            <span>Select a user to view transactions</span>
                        </div>
                    ) : (
                        <>
                            {transactions.length === 0 ? (
                                <div className="withdrawals-and-deposits-table-display__no-results">
                                    <span>No Withdrawals or Deposits for the selected user & time range.</span>
                                </div>
                            ) : null}
                        </>
                    )}
                    {renderTableRows()}
                </div>

                {page && (
                    <div>
                        {totalWithdrawalsAndDeposits !== undefined && (
                            <div className="show-at-sm">
                                <div className="withdrawals-and-deposits-table-display__pagination">
                                    <span className="withdrawals-and-deposits-table-display__pagination-total">{`Showing ${lowerDisplay} - ${upperDisplay} of ${totalWithdrawalsAndDeposits}`}</span>
                                </div>
                            </div>
                        )}

                        <div className="withdrawals-and-deposits-table-display__pagination">
                            {totalWithdrawalsAndDeposits !== undefined && (
                                <span className="withdrawals-and-deposits-table-display__pagination-total remove-at-sm">{`Showing ${lowerDisplay} - ${upperDisplay} of ${totalWithdrawalsAndDeposits}`}</span>
                            )}

                            <button disabled={page === 1} onClick={handleFirstPageClick}>
                                First
                            </button>
                            <button
                                disabled={Math.ceil(page / resultsPerPage) === 1}
                                onClick={handleReverseFivePages}
                            >
                                <FirstChevron className="pagination__double-chevron" />
                            </button>

                            {renderPagination()}

                            <button
                                disabled={
                                    Math.ceil(page / resultsPerPage) * resultsPerPage >= totalPages
                                }
                                onClick={handleSkipForwardFivePages}
                            >
                                <LastChevron className="pagination__double-chevron" />
                            </button>
                            <button disabled={page === totalPages} onClick={handleLastPageClick}>
                                Last
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    filterWithdrawalsAndDeposits: filterData => dispatch(filterWithdrawalsAndDeposits(filterData))
});

const mapStateToProps = ({
    withdrawalsAndDeposits: {totalPages, transactions, isPending, page, currentFilter, lowerDisplay, upperDisplay, totalWithdrawalsAndDeposits}
}) => ({
    totalPages,
    transactions,
    isPending,
    page,
    currentFilter,
    lowerDisplay,
    upperDisplay,
    totalWithdrawalsAndDeposits,
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalsAndDepositsTableDisplay);
