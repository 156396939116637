import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import SignalHistoryFilters from '../../components/SignalHistoryFilters';
import DashComponent from '../../components/Dashboard/DashComponent';
import SignalsTableDisplay from './SignalsTableDisplay';
import { getSignalsOnPageLoad } from '../../redux/SignalHistory/signal-history.actions';
import { ReactComponent as SignalsIcon } from '../../static/svg/signals-icon.svg';
import './DashSignalHistory.scss';

const DashSignalHistory = ({ role, getSignalsOnPageLoad, match }) => {
  useEffect(() => {
    getSignalsOnPageLoad(match.params.page);
  }, []);

  const [] = useState(null);

  const baseColumns = ['signal', 'date', 'status'];
  if (role === "signaler" || role === "superAdmin") {
    baseColumns.splice(1, 0, "user");
    baseColumns.splice(2, 0, "signaler");
  }

  return (
    <section className="dash-signal-history">
      <DashComponent role={role} svg={<SignalsIcon />} title="signal history">
        <SignalHistoryFilters role={role} />
        <SignalsTableDisplay
          headerTitleArray={baseColumns}
          removeHeaderAtSmallArray={[]}
          role={role}
        />
      </DashComponent>
    </section>
  );
};

const mapDispatchToProps = dispatch => ({
  getSignalsOnPageLoad: () => dispatch(getSignalsOnPageLoad()),
});

const mapStateToProps = ({user: {data: {role}}}) => ({
  role
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DashSignalHistory));
