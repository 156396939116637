import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { filterUserActivityData } from "../../../redux/DashActivity/dash-activity.actions";
import { getAllUsers } from "../../../redux/Users/users.actions";
import Button from "../../../components/Button";
import AutoCompleteUsers from "../../../components/AutoCompleteUsers";
import SelectInput from "../../../components/SelectInput";
import "./ActivityTableFilters.scss";

const roleItemArray = ["none", "user", "signaler"];

const statusItemArrays = {
  user: [
    "none",
    "active",
    "inactive",
    "logged in",
    "logged out",
    "banned",
    "eligible",
    "ineligible"
  ],
  signaler: ["none", "active", "inactive", "logged in", "logged out", "banned"],

  none: ["none"]
};

const ActivityTableFilters = ({
  getAllUsers,
  users,
  filterUserActivityData,
  numberOfAccounts
}) => {
  const [roleSelectInput, setRoleSelectInput] = useState("none");
  const [statusSelectInput, setStatusSelectInput] = useState("none");
  const [statusMenuItemsArray, setStatusMenuItemsArray] = useState(
    statusItemArrays.user
  );
  const [fieldInputs, setFieldInputs] = useState({ username: "" });

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleRoleChange = e => {
    setRoleSelectInput(e.target.value);
    setStatusMenuItemsArray(statusItemArrays[e.target.value]);
    setStatusSelectInput(statusItemArrays[e.target.value][0]);
  };

  const handleStatusChange = e => setStatusSelectInput(e.target.value);

  const handleAutocompleteChange = (e, { newValue }) => {
    e.persist();
    setFieldInputs(prevState => ({
      ...prevState,
      [e.target.name]: newValue
    }));
  };

  const handleAutocompleteClick = parts => {
    const combineParts = parts => parts.map(part => part.text).join("");

    setFieldInputs(prevState => ({
      ...prevState,
      username: combineParts(parts)
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    const formData = {
      username: fieldInputs.username,
      role: roleSelectInput === "none" ? "" : roleSelectInput,
      status:
        statusSelectInput === "none"
          ? ""
          : statusSelectInput.split(" ").join(""),
      page: 1
    };

    filterUserActivityData(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="activity-table-filters__form">
      <div className="activity-table-filters__count">
        <span className="activity-table-filters__count-label">Accounts</span>
        <span className="activity-table-filters__count-number">
          {numberOfAccounts}
        </span>
      </div>
      <AutoCompleteUsers
        handleChange={handleAutocompleteChange}
        handleClick={handleAutocompleteClick}
        value={fieldInputs.username}
        suggestions={users}
      />
      <SelectInput
        value={roleSelectInput}
        onChange={handleRoleChange}
        menuItemArray={roleItemArray}
        id="activity-table-role-input"
        name="roleSelectInput"
        label="Role"
      />
      <SelectInput
        value={statusSelectInput}
        onChange={handleStatusChange}
        menuItemArray={statusMenuItemsArray}
        id="activity-table-status-input"
        name="statusSelectInput"
        label="Status"
      />
      <Button type="solid" header={false} element="input" value="Search" />
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  getAllUsers: () => dispatch(getAllUsers()),
  filterUserActivityData: formData => dispatch(filterUserActivityData(formData))
});

const mapStateToProps = ({
  users: { users },
  dashActivity: { numberOfUsers }
}) => ({
  users,
  numberOfAccounts: numberOfUsers
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTableFilters);
