import { SignalModalTypes } from './signal-modal.types';

const INITIAL_STATE = {
  isOpen: false,
  signalData: null,
  cancelSignalPending: false,
};

const signalModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SignalModalTypes.SIGNAL_MODAL_OPEN:
      return { ...state, isOpen: true, signalData: action.payload };

    case SignalModalTypes.SIGNAL_MODAL_CLOSE:
      return { ...state, isOpen: false, signalData: null };

    case SignalModalTypes.SIGNAL_MODAL_CANCEL_SIGNAL_PENDING:
      return { ...state, cancelSignalPending: true };

    case SignalModalTypes.SIGNAL_MODAL_CANCEL_SIGNAL_SUCCESS:
      return { ...state, cancelSignalPending: false };

    case SignalModalTypes.SIGNAL_MODAL_CANCEL_SIGNAL_FAILED:
      return { ...state, cancelSignalPending: false };

    default:
      return state;
  }
};

export default signalModalReducer;
