import React from 'react';

import CircularLoader from '../../components/CircularLoader';

import './MessagePending.scss';

const MessagePending = ({ isPending }) => {
  const loadingClass = isPending ? 'loading' : '';

  return (
    <div className="message-pending">
      <CircularLoader classNames={loadingClass} size={100} />
    </div>
  );
};

export default MessagePending;
