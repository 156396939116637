import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import MuiBasicTable from "../../components/MuiBasicTable";
import FixedBanner from "../../components/FixedBanner";
import DashboardLoading from "../../components/DashboardLoading";
import PerformanceChart from "./PerformanceChart";
import CoinAllocationChart from "./CoinAllocationChart";
import TradeFreqChart from "./TradeFreqChart";
import StatBox from "./StatBox";
import DashComponent from "../../components/Dashboard/DashComponent";
import FilterForm from "./FilterForm";
import { ReactComponent as PerformanceIcon } from "../../static/svg/performance-icon.svg";
import { ReactComponent as CoinAllocationIcon } from "../../static/svg/coin-allocation-icon.svg";
import { ReactComponent as TradeFreqIcon } from "../../static/svg/trade-freq-icon.svg";
import { ReactComponent as ListIcon } from "../../static/svg/list-icon.svg";
import { statArray, tableHeadList } from "./seedData";
import { getStatistics } from "../../redux/DashHome/dash-home.actions";
import "./DashHome.scss";

const DashHome = ({
  role,
  isPending,
  getStatistics,
  settings,
  usersIsPending,
  assetList = [],
}) => {
  console.log(assetList);
  
  useEffect(() => {
    const startDate = moment().startOf("day").subtract(6, "days");

    const endDate = moment().endOf("day");

    if (role === "superAdmin" || role === "signaler")
      getStatistics({
        startDate: startDate.valueOf(),
        endDate: endDate.valueOf(),
        queryId: "",
        searchType: "allTrades",
      });
    else
      getStatistics({
        startDate: startDate.valueOf(),
        endDate: endDate.valueOf(),
        queryId: "",
      });
  }, [getStatistics, role]);

  const renderUserStatBoxes = statArray.map((stat) => (
    <StatBox key={stat.description} stat={stat} />
  ));

  const formattedTableRowData = assetList.map(
    ({ asset, total, available, locked, valueBtc, valueUsd }) => [
      { data: total + ` ${asset}`, align: "center" },
      { data: available + ` ${asset}`, align: "center" },
      { data: locked + ` ${asset}`, align: "center" },
      { data: valueBtc + " BTC", align: "center" },
      { data: "$" + valueUsd, align: "center" },
    ]
  );

  return (
    <section className="dash-home">
      {isPending || usersIsPending ? <DashboardLoading /> : null}
      {role !== "user" ||
      (role === "user" &&
        settings &&
        settings.binanceCredentialsComplete) ? null : (
        <FixedBanner />
      )}
      <FilterForm role={role} />
      <div className="dash-home__stat-box-container">{renderUserStatBoxes}</div>
      {role === "user" || role === "signaler" || role === "superAdmin" ? (
        <DashComponent svg={<ListIcon />} title="Asset List">
          <MuiBasicTable
            headList={tableHeadList}
            rowList={formattedTableRowData}
          />
        </DashComponent>
      ) : null}
      {/*{role !== "signaler" && (*/}
      <DashComponent svg={<PerformanceIcon />} title="performance">
        <PerformanceChart />
      </DashComponent>
      {/*)}*/}
      <div className="dash-home__row">
        <DashComponent
          svg={<CoinAllocationIcon />}
          title="coin allocation"
          twoPerRow={true}
          role={role}
        >
          <CoinAllocationChart />
        </DashComponent>

        <DashComponent
          svg={<TradeFreqIcon />}
          title="trade frequency"
          twoPerRow={true}
          role={role}
        >
          <TradeFreqChart />
        </DashComponent>
      </div>
      {/*{role === "user" && (*/}
      {/*    <DashComponent svg={<AttachMoneyIcon />} title="Profit Report">*/}
      {/*      <UserProfileReport/>*/}
      {/*    </DashComponent>*/}
      {/*)}*/}
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStatistics: (filterData) => dispatch(getStatistics(filterData)),
});

const mapStateToProps = ({
  dashHome: { isPending },
  dashHome: {
    statistics: { assetList },
  },
  user: {
    data: { role },
  },
  users,
  settings: { settings },
}) => ({
  isPending,
  settings,
  role,
  usersIsPending: users.isPending,
  assetList,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashHome);
